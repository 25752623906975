// authActions.js
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ADMIN_ROLES } from 'common_components/hoc/admin.pov';
import Models from 'imports/models.import';
import { toast } from 'react-toastify';
import encrypt from 'utils/encrypt';
import { modelError, toastifyError } from 'utils/functions.utils';
// import authService from '../../services/auth.service';

export const setClientPOVStoreLogin = createAsyncThunk("client-pov-store/login",
    async (data: {
        token: string,
        storeId: string
    }, { rejectWithValue, ...rest }) => {
        try {
            if (data.token && data.storeId) {
                localStorage.setItem("store_token", data.token);
                localStorage.setItem("logged_in_store", data.storeId)
                const res:any =  await Models.store.getStore({
                    id: data.storeId,
                });
                return {
                    store: res?.data[0],
                }
            } else {
                return rejectWithValue("Can't Login store");
            }
        } catch (error) {
            console.log("Error =>", error);
            toastifyError(modelError(error));
            return rejectWithValue("some error happened");
        }
    }
)

export const setClientPOVStoreLogout = createAsyncThunk("client-pov-store/logout",
    async (_, { rejectWithValue }) => {
        try {
            localStorage.removeItem("logged_in_store");
            localStorage.removeItem("store_token");
            toast("Store logged out successfully");
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    }
)
export const getLoggedInClientPOVStore = createAsyncThunk("client-pov-store/getuser",
    async (_, { rejectWithValue, dispatch }) => {
        try {
            let storeId = localStorage.getItem("logged_in_store");
            let store_access_token = localStorage.getItem("store_token");
            if (storeId && store_access_token) {
                const res:any =  await Models.store.getStore({
                    id: storeId,
                });
                return {
                    store: res?.data[0],
                }
            } else {
                return rejectWithValue("No login detected");
            }
        } catch (error) {
            return rejectWithValue("Unauthenticated");
        }
    }
)