import History from 'components/history/history.component'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useSetState } from 'utils/functions.utils';
import { TICKET_STATUS } from './ticket.screen';
import { Dropdown } from 'primereact/dropdown';
import { IAddValues } from 'utils/interface.utils';
import View from 'components/view/view.component';
import Avatar from 'common_components/ui/avatar/avatar.ui';
import Divider from 'common_components/ui/divider/divider.ui';

const ViewTicket: React.FC<any> = () => {
  const { id } = useParams();
  const values = {
    name: "Busra Fathima",
    city: "Bengaluru",
    mobile: "1234567890",
    client: "Fullfily",
    comment: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi debitis vero quisquam quaerat eaque omnis, quas nam doloremque expedita, modi, itaque enim cum quod fuga.",
    issue: "Vehicle not working",
    image: null,
    historyActive: false,
  }
  const viewFields: IAddValues[] = [
    {
      label: "Driver name", key: "name", type: "string",
    },
    {
      label: "City", key: "city", type: "string",
    },
    {
      label: "Mobile no.", key: "mobile", type: "string",
    },
    {
      label: "Client", key: "client", type: "string",
    },
    {
      label: "Comment", key: "comment", type: "string",
    },
    { label: "Issue", key: "issue", type: "string" },
    { label: "Reference Image", key: "image", type: "file" },
  ];
  const ticket_status = [
    { label: "PENDING", value: "pending" },
    { label: "IN_PROCESS", value: "processing" },
    { label: "CLOSED", value: "closed" },
    { label: "CLIENT", value: "client" },
  ]
  const [state, setState] = useSetState({
    loading: "",
    ticketStatus: TICKET_STATUS.PENDING,
  })
  return (
    <div className="container-fluid ticket-view-screen">
      <History
        // name={`state.data.name`}
        path={`/ticket/view/ticket ID #${id}`}
      />
      <h3  >Ticket #{id}</h3>
      <div className="ticket-view-heading container-fluid">
        <div className="row align-items-center ">
          <div className="col-6">
            <div className="d-flex align-items-center" >
              <Avatar
                size={70}
                name='Soham roy'
                className='avatar'
              />
              <div style={{ flex: 1, margin: "0 10px" }}>
                <h5>Soham Roy</h5>
                <span className='text-muted'>{localStorage.getItem('role')}</span>
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="dropdown-primary">
              {/* @ts-ignore */}
              <Dropdown
                loading={state.loading}
                filter
                value={state.ticketPickedBy}
                onChange={(e) => setState({ ticketPickedBy: e.value })}
                options={ticket_status}
                optionLabel="label"
                placeholder={state.loading ? "Fetching Data..." : "Status"}
              />
            </div>
          </div>
          <div className="col-4 tabs">
            <div className={`tab-item ${!state.historyActive ? "selected" : ""}`} onClick={() => setState({ historyActive: false })}>
              <i className='bx bx-sm bx-info-circle' ></i>&nbsp;
              Details
            </div>
            <div className={`tab-item ${state.historyActive ? "selected" : ""}`} onClick={() => setState({ historyActive: true })}>
              <i className='bx bx-sm bx-time-five'></i>&nbsp;
              History
            </div>
          </div>
        </div>

      </div>
      <br />
      <br />
      <br />
      {
        !state.historyActive ?
          <View
            actions={[]}
            data={values}
            values={viewFields}
            hasFiles
          />
          :
          <TicketHistory />
      }
    </div>
  )
}

const TicketHistory: React.FC<any> = () => {
  return (
    <div className='ticket-history-container' >
      <div className="row ">
        <div className="col-md-6 p-5">
          <div className="d-flex align-items-center " >
            <Avatar
              size={30}
              name='Abhijit Kumar'
            />
            <span style={{ fontSize: 15 }}>&nbsp; Abhijit Kumar </span>
            <span style={{ fontSize: 15, fontWeight: 'bold' }} >&nbsp; Today 12/09/2024</span>
          </div>
          {
            [
              "Mahesh created a ticket on 12/09/2024 at 2:55PM, Issue with vehicle...... ",
              "Bushra fathima picked up ticket on 12/09/2024 at 3:0PM ",
              "Ticket assigned to client on 12/09/2024 at 3:05PM ",
              "Ticket assigned to client on 12/09/2024 at 3:05PM ",
              "Ticket Resolved by client (Sammer) on 12/09/2024 at 3:25PM ",
            ].map((item, index) => (
              <div className='hist-rows' key={index} >{item}</div>
            ))

          }
        </div>
        <div style={{ borderLeft: "1px solid #80819155" }} className="col-md-6 p-5">
          <div className="history-details-container">
            <div className="d-flex align-items-center " >
              <Avatar
                size={30}
                name='Bushra Fathima'
              />
              <div style={{ fontSize: 15, marginLeft : 10 , fontWeight : 'bold', flex : 1}}>
                <div>Bushra Fathima </div>
                <div className='ticket-history-status' >
                  Bushra Fathima  changed ticket from 
                  <div className="status-indicator pending"></div> Pending to 
                  <div className="status-indicator closed"> </div> Closed
                </div>
              </div>
            </div>
            <Divider style={{margin : "20px 0"}} />
            <div className="container-fluid">
              <div className="row align-items-center mt-2">
                <div className="col-4">Ticket overview</div>
                <div className="col-8">
                  <div className="overview-box pending">
                    <del>Pending</del> 
                  </div>
                  <div className="overview-box closed">
                    <span>Closed</span> 
                  </div>
                </div>
              </div>
              <div className="row align-items-center  mt-2">
                <div className="col-4">Reason</div>
                <div className="col-8">
                  <div className="ticket-history-details-row">Vehicle not working</div>
                </div>
              </div>
              <div className="row align-items-center  mt-2">
                <div className="col-4">Assigned to client</div>
                <div className="col-8">
                  <div className="ticket-history-details-row">NA</div>
                </div>
              </div>
              <div className="row align-items-center  mt-2">
                <div className="col-4">Ticket generated on </div>
                <div className="col-8">
                  <div className="ticket-history-details-row">Wed, 12/02/2024 at 11 AM</div>
                </div>
              </div>
              <div className="row align-items-center  mt-2">
                <div className="col-4">Ticket resolved on </div>
                <div className="col-8">
                  <div className="ticket-history-details-row">Wed, 12/02/2024 at 12:30 PM</div>
                </div>
              </div>
              <div className="row align-items-center  mt-2">
                <div className="col-4">Comments</div>
                <div className="col-8">
                  <div className="ticket-history-details-row">Mechanical assistance provided</div>
                </div>
              </div>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ViewTicket