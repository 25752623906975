import React, { Fragment, createRef, useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import InputField from "common_components/ui/field/field.ui";
import Button from "common_components/ui/button/button.ui";
import History from "components/history/history.component";
import {
  useSetState,
  toastifyError,
  removeEmptyValues,
  jsonToFormData,
} from "utils/functions.utils";
import { Models, Validation } from "utils/imports.utils";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { POWER_BILLS_UPLOAD_URL } from "utils/constant.utils";
import "./add_power.screen.scss";
import FileInput from "common_components/ui/file_input/file_input.ui";
import SelectDropdown from "common_components/ui/select_dropdown/select_dropdown.component";
import PowerBill from "components/power_bill/power_bill.component";
import Add from "components/add/add.component";
import { IAddValues } from "utils/interface.utils";
import Divider from "common_components/ui/divider/divider.ui";
import Assets from "imports/assets.import";
import { Dropdown } from "primereact/dropdown";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default function AddPower() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id, year } = useParams();
  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");
  const formRef = useRef<any>(null);
  const [formRefs, setFormRefs] = React.useState<any[]>([]);
  const [mappedData ,setMappedData] = useState({})
  const [visibleAddComponents, setVisibleAddComponents] = useState(1);

  const handleAddComponent = () => {
    setVisibleAddComponents((prev) => prev + 1);
  };

  const handleRemoveComponent = (indexToRemove: number) => {
    setVisibleAddComponents((prev) => prev - 1);
  };
  const [state, setState] = useSetState({
    year: isEditable ?  year : "",
    data: [],
    loading: false,
    buttonDisabled: false,
  });
  const [years ,setYears] = useState<number[]>([]) 


  useEffect(() => {
    setFormRefs((r:any) =>months.map(() => createRef()),
  );
    
    if (isEditable) {
      GetPower();
    }
    GetManyData();
  }, []);

  const GetPower = async () => {
    try {
      const response: any = await Models.warehouse.getPower({
        wId: id,
        year 
      });
      let temp = {}
      response.data?.[0]?.powerBillHistory?.map((item)=>{
        temp[item?.monthFor] = item;
      })
      setMappedData(temp)
      setState({
        data: response.data?.[0]?.powerBillHistory,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleCreate = async (values) => {
    setState({loading : true})
    try {
      if(state.year){
        values.year = state.year;
        const data = {
          wareHouseId: id,
          year : state.year,
          powerBillHistory : values,
        };
        // const formData = jsonToFormData(data)
        await Models.warehouse.createPowerBills(data);
        navigate(`/warehouse/power/${id}`);
      }else{
        toastifyError("Please choose a year !")
      }
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }finally{
      setState({loading : false})
    }
  };

  const currentYear = new Date().getFullYear();
  
  
  const GetManyData = async () => {
    try {
      let res: any;
      const body: any = {
        // page: state.currentPage,
        // pageSize: 10,
        wId: id,
      };
      if (state?.search?.length > 0) {
        body.search = state.search;
      
      }
      res = await Models.warehouse.getManyPower(body);
      const existingYears = res?.data?.map((item:any)=>item?.year?.toString())
      let t_years: any = [];

      for (let i = currentYear; i >= currentYear - 10; i--) {
        t_years.push({ label: i.toString(), value: i.toString() });
      }

      console.log(existingYears);
      t_years = t_years.filter(item=>!existingYears.includes(item.value))
      if(isEditable) t_years.push({ label: year, value: year }) ;
      setYears(t_years)
      // setState({ data: res?.data });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  const onSubmitForm =()=>{
    let gatheredData:any = []
    for (let i = 0; i < months.length; i++) {
      var {billDocument ,billNumber , initialReadings , paidAmount} = formRefs[i]?.current?.values
      if(billDocument && billNumber && initialReadings && paidAmount) gatheredData.push(formRefs[i]?.current?.values)
      else if(billDocument || billNumber || initialReadings || paidAmount)  {
        formRefs[i]?.current?.submitForm()
        gatheredData = []
      }
    }
    console.log(gatheredData);
    if (gatheredData.length !== 0) {
      handleCreate(gatheredData)
    }
  }
  return (
    <div className="add_power_container container-fluid">
      <History
        name={state?.data?.chId}
        path={isEditable ? "power bill/edit" : "power bill/add"}
      />
      <div className="row">
        <div className="col-md-12 ">
          <label className="input-label" htmlFor="">Year <span className="text-danger">*</span></label>
          <div className="remove-styles">
            <Dropdown
              style={{ width: "200px" }}
              placeholder={"Select year"}
              options={years}
              optionLabel="label"
              onChange={(year: any) =>
                setState({
                  year: year.value,
                })
              }
              value={state.year}
            />
          </div>
        </div>
      </div>
      <br />
      {
        months.map((item, index) => (
          <PowerBill key={index} data={ mappedData[item] ?? {} } ref={formRefs[index]} month={item} />
        ))
      }
      {/* <PowerBill ref={formRef}  month={"January"}/> */}
      <Button
        loading={state.loading}
        loadingText="Uploading data..."
        value={isEditable?  "Update power bills" : "Upload Power bills"}
        onClick={onSubmitForm}
      />
    </div>
  );
}

