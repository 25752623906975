import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from 'store';
import { getUser } from 'store/actions/auth.actions';
import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import "./assets/primereact-theme.css";
import './index.scss';
import './primereact_modified.scss'
const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <PrimeReactProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </PrimeReactProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
const initialize = async () => {
  if (localStorage.getItem('accessToken')) {
    await store.dispatch(getUser())
    renderApp()
  } else {
    renderApp()
  }
}
initialize()
reportWebVitals();