import React, { useEffect } from "react";
import Functions, {
  useSetState,
  useQuery,
  toastifyError,
  createExcelFile,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import "./careers.screen.scss";
import "react-responsive-modal/styles.css";
import Table from "components/table/table.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import DateRangePicker from "common_components/ui/date_range_picker/date_range.component";
import SelectDropdown from "common_components/ui/select_dropdown/select_dropdown.component";
import Button from "common_components/ui/button/button.ui";
import Filter from "common_components/ui/filter/filter.ui";
import Search from "common_components/ui/search/search.ui";
import Pagination from "components/pagination/pagination.component";
import pako from "pako";
import { Buffer } from "buffer";
import CustomModal from "common_components/ui/modal/modal.component";
import DeletePopup from "components/delete_popup/delete_popup.component";
import AccessControlWrapper from "common_components/access_control.wrapper.hoc";

export default function Careers() {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const pathArr = pathname.split("/");
  const driverCheckin = params.id;
  console.log(params);

  const [state, setState] = useSetState({
    data: [],
    search: "",
    toggleFilter: false,
    selectedFilter: 0,
    currentPage: 1,
    totalItems: 0,
    filterStatus: "",
    filterVehicle: "",
    filterCareer: "",
    deleteModal: "",
    checkinVehicleData: [],
    checkinDriverData: [],
  });
  const status = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "in-active" },
    { label: "Break", value: "break" },
    { label: "All Status", value: 0 },
  ];
  const thead = [
    { head: "Date", key: "createdAt", type: "date-only" },
    { head: "Job role", key: "title" },
    { head: "Job location", key: "location" },
    { head: "Job qualification", key: "qualification" },
  ];

  const getAllCareers = async () => {
    try {
      const res: any = await Models.careers.getManyCareers({});
      setState({
        data: res?.data,
      });
    } catch (err: any) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getAllCareers();
  }, []);
  const handleView = (data) => {
    navigate(`/careers/view/${data?._id}`);
  };

  const handlePageChange = (page: number) => {
    setState({ currentPage: page });
  };
  const handleFilter = (status) => {
    if (status !== 0) setState({ filterCareer: "" });
    setState({
      id: "",
      selectedFilter: status,
      currentPage: 1,
      totalItems: 0,
      isArchived: 0,
      filterCareer: 2,
    });
  };
  const handleExport = async () => {
    // const res: any = await Models.checkin.exportCheckin();
    // const compressedString = res.data;
    // const compressedBuffer = Buffer.from(compressedString, "base64");
    // const base64String = pako.ungzip(compressedBuffer, { to: "base64" });
    // createExcelFile(base64String);
  };
  const handleEdit = (data) => {
    navigate(`/careers/edit/${data._id}`);
  };
  const handleDelete = async () => {
    try {
      await Models.careers.deleteJob({
        ids: [state.id],
        target: "delete",
        createdByCheck: false,
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
      });
      setState({ id: "", deleteModal: false });
      getAllCareers();
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  return (
    <div id="careers_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="head h3">Careers</div>
        </div>
        <div style={{ justifyContent: "flex-end" }} className="search_wrapper">
          <Search
            value={state.search}
            onChange={(search) => setState({ search ,currentPage: 1, totalItems: 0 })}
          />

          <div className="button_group">
            {/* <Filter
              onClick={() => setState({ toggleFilter: !state.toggleFilter })}
            /> */}
            <AccessControlWrapper accessKey="CAREER_ADD">
              <Button
                value="Add Job"
                onClick={() => {
                  navigate("/careers/add");
                }}
              />
            </AccessControlWrapper>
            {/* <AccessControlWrapper accessKey="CAREER_EXPORT">
              <Button
                value="Export"
                onClick={() => {
                  handleExport();
                }}
              />
            </AccessControlWrapper> */}
          </div>
        </div>
      </div>
      <div className="all_filter_container">
        {state.toggleFilter && (
          <div className="all_filter_dropdown_wrapper">
            <div className="all_filter_dropdown_item">
              <SelectDropdown
                notfound={"Not found"}
                placeholder={"Status"}
                data={status}
                onChange={(filterStatus: any) =>
                  setState({
                    filterStatus: filterStatus.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterStatus}
              />
            </div>
            <div className="all_filter_dropdown_item">
              <SelectDropdown
                notfound={"Not found"}
                placeholder={"Model - vhNumber"}
                data={state.checkinVehicleData}
                onChange={(filterVehicle: any) =>
                  setState({
                    filterVehicle: filterVehicle.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterVehicle}
              />
            </div>
            <div className="all_filter_dropdown_item">
              <SelectDropdown
                notfound={"Not found"}
                placeholder={"dNumber"}
                data={state.checkinDriverData}
                onChange={(filterCareer: any) =>
                  setState({
                    filterCareer: filterCareer.value,
                    currentPage: 1,
                    totalItems: 0,
                  })
                }
                value={state.filterCareer}
              />
            </div>
          </div>
        )}
      </div>
      {/* <div className="career_filters">
        <div
          onClick={() => handleFilter(0)}
          className={`button ${0 === state.selectedFilter ? "selected" : ""}`}
        >
          All Jobs
        </div>
        <div
          onClick={() => handleFilter("PROCESSING")}
          className={`button ${
            "PROCESSING" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Interviews process
        </div>
      </div> */}
      <div className="drivertable">
        <Table
          data={state.data}
          totalDocs={state.totalDocs}
          loading={state.loading}
          theads={thead}
          link=""
          loadMore={() => {}}
          actions={[
            { key: "CAREER_VIEW", icon: "view", onClick: handleView },
            {
              key: "CAREER_EDIT",
              icon: "edit",
              onClick: handleEdit,
            },
            {
              key: "CAREER_DELETE",
              icon: "delete",
              onClick: (data) => setState({ id: data._id, deleteModal: true }),
            },
          ]}
        />
      </div>
      <div className="driver_pagination">
        <Pagination
          totalItems={state.totalItems}
          itemsPerPage={10}
          currentPage={state.currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={handleDelete}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
    </div>
  );
}
