import Main from 'common_components/hoc/main.hoc'
import React, { FC, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

export const CLIENT_ROLES = ["CLIENT"]

const ClientPOV: FC<any> = () => {
  const auth = useSelector((s: any) => s.auth);
  if (CLIENT_ROLES.includes(`${localStorage.getItem('role')}`)) {
    return <Outlet />
  }
  // return <Outlet />
  return <Navigate to="/unauthorized" />
}

export default ClientPOV