import React, { FC, useEffect, useState } from 'react'
import "./d_form.style.scss";
import DFormType, { DFieldType } from './d-form.type';
import Divider from 'common_components/ui/divider/divider.ui';
import DInputText from './components/d-input-text';
import DInputNumber from './components/d-input-number';
import DDatePicker from './components/d-datepicker';
import DDropdown from './components/d-dropdown';
import Button from 'common_components/ui/button/button.ui';
import { ErrorMessage, Formik, FormikConfig, FormikProps, FormikValues, useFormik } from 'formik';
import Error from 'common_components/ui/error/error.ui';
import { getNestedObjectValue } from 'utils/functions.utils';
import { MultiSelect } from 'primereact/multiselect';
import FileInput from 'common_components/ui/file_input/file_input.ui';
import DCustomSelect from './components/d-custom-select';


export interface DFormProps extends React.HTMLAttributes<HTMLDivElement> {
  data: DFormType[],
  fillSections?: any[]
}


const DForm: React.FC<DFormProps> = ({
  className,
  data,
  fillSections,
  ...rest
}) => {
  return (
    <div className={`d-form-container ${className}`} {...rest}>

      {
        data.map((item, index) => (
          <DFormComponent fillData={fillSections?.[index] ?? {}} {...item} key={index.toString()} />
        ))
      }
    </div>
  )
}


export interface DFormComponentProps extends DFormType {
  fillData : any
}
const DFormComponent: FC<DFormComponentProps> = ({
  initialValues,
  onSubmit,
  fillData,
  validationSchema,
  ...props
}) => {
  const fileTypes = ['file-picker', 'image-picker']
  const [finalInitialValues , setFinalInitialValues] = useState<any>(initialValues);
  const generalFields = props.fields.filter((item) => !fileTypes.includes(item.type))
  const fileFields = props.fields.filter((item) => fileTypes.includes(item.type))
  // const formik = useFormik({
  //   initialValues,
  //   onSubmit,
  //   validationSchema,
  //   enableReinitialize: true
  // })
  useEffect(()=>{
    let temp = initialValues
    if(fillData){
      for(var [key,value] of Object.entries(fillData)){
        if(key in initialValues) temp[key] = value
      }
      setFinalInitialValues(temp)
    }
  },[fillData])
  return (

    <Formik
      // ref={formikRef}
      onSubmit={onSubmit}
      // validate={handleChange}
      initialValues={finalInitialValues}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({handleSubmit ,setFieldTouched , setFieldValue , touched , values , errors })=>(
        <form onSubmit={(e) => {
          e.preventDefault();
          handleSubmit()
        }}>
          <div className='d-form-section-container container-fluid'>
            <div className="row">
              <div className='d-form-section-label'>{props.sectionLabel}</div>
              <Divider />
              {
                generalFields.map((fItem, fIndex) => (
                  <div key={fIndex.toString()} className={`${fItem.grid ?? (props.defaultGrid ?? "col-6")}`}>
                    <DField
                      fieldKey={fItem.key}
                      value={values[fItem.key] || getNestedObjectValue(values, fItem.key) || null}
                      onChange={(value) => setFieldValue(fItem.key, value)}
                      onBlur={() => setFieldTouched(fItem.key)}
                      error={(touched[fItem.key] || getNestedObjectValue(touched, fItem.key)) ? true : false}
                      errorText={(errors[fItem.key] as string) || getNestedObjectValue(errors, fItem.key)}
                      linkedValue={fItem.linkedWith ? (values[fItem.linkedWith] || getNestedObjectValue(values, fItem.linkedWith)) : ""}
                      setFieldValue={setFieldValue}
                      {...fItem}
                    />
                  </div>
                ))
              }
              {
                fileFields.length !== 0 && <Divider />
              }
              {
                fileFields.map((fItem, fIndex) => (
                  <div key={fIndex.toString()} className={`${fItem.grid ?? (props.defaultGrid ?? "col-6")}`}>
                    <DField
                      fieldKey={fItem.key}
                      value={values[fItem.key] || getNestedObjectValue(values, fItem.key) || null}
                      onChange={(value) => setFieldValue(fItem.key, value)}
                      onBlur={() => setFieldTouched(fItem.key)}
                      error={(touched[fItem.key] || getNestedObjectValue(touched, fItem.key)) ? true : false}
                      errorText={(errors[fItem.key] as string) || getNestedObjectValue(errors, fItem.key)}
                      linkedValue={fItem.linkedWith ? (values[fItem.linkedWith] || getNestedObjectValue(values, fItem.linkedWith)) : ""}
                      setFieldValue={setFieldValue}
                      {...fItem}
                    />
                  </div>
                ))
              }
            </div>
            <div className="row justify-content-center my-3">
              <div className="col-md-2">
                <Button
                  style={{ width: "100%" }}
                  value='Submit'
                  // onClick={() => formik.handleSubmit()}
                />
              </div>
            </div>

          </div>
        </form>
      )}
    </Formik>
  )
}

export interface DFieldProps extends DFieldType {
  fieldKey: string,
  onChange: (value: any) => void,
  value: any,
  onBlur?: () => void,
  error?: boolean,
  errorText?: string,
  linkedValue?: string,
  setFieldValue: (key: string, val: any) => void
}

const DField: React.FC<DFieldProps> = ({
  label,
  fieldKey,
  options,
  extraProps,
  type,
  optional,
  grid,
  onChange = () => { },
  value,
  onBlur = () => { },
  error = false,
  errorText = "",
  linkedValue = "",
  setFieldValue = (key, val) => { },
  ...rest
}) => {




  const fieldElementReturner = () => {
    switch (type) {
      case 'string':
        return <DInputText
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          placeholder={`Please enter ${label.toLocaleLowerCase()}`}
          {...extraProps}
        />
      case 'number':
        return <DInputNumber
          value={value}
          onChange={(e) => onChange(e.value)}
          onBlur={onBlur}
          placeholder={`Please enter ${label.toLocaleLowerCase()}`}
          {...extraProps}
        />
      case 'date':
      case 'future-date':
      case 'previous-date':
        return <DDatePicker
          type={type}
          selected={value}
          onChange={(date) => onChange(date)}
          onBlur={onBlur}
          placeholder={`Please enter ${label.toLocaleLowerCase()}`}
          {...extraProps}
        />
      case 'select':
        return <DDropdown
          value={value}
          onChange={(e) => onChange(e.value)}
          onBlur={onBlur}
          optionLabel='label'
          options={options ?? []}
          placeholder={`Please enter ${label.toLocaleLowerCase()}`}
          {...extraProps}
        />
      
      case 'city':
      case 'warehouse':
      case 'state':
        return <DCustomSelect
          type={type}
          value={value}
          linkedValue={linkedValue}
          onChange={(e) => onChange(e.value)}
          onBlur={onBlur}
          optionLabel='label'
          placeholder={`Please choose a ${label.toLocaleLowerCase()}`}
          {...extraProps}
        />
      case 'multiselect':
        return <MultiSelect
          style={{ width: "100%" }}
          value={value}
          onChange={
            (e) => onChange(e.value)
          }
          onBlur={onBlur}
          options={options}
          optionLabel="label"
          display="chip"
          placeholder="select one or multiple"
          maxSelectedLabels={3}
          className="w-full"
          {...extraProps}
        />
      case 'image-picker':
        return <FileInput
          value={value}
          fileKey={fieldKey}
          fileType={'image'}
          onChangeFile={(file) => {
            console.log(file);
            onChange(file);
          }}
          optional={optional}
          {...extraProps}
        />
      case 'file-picker':
        return <FileInput
          fileKey={fieldKey}
          fileType={'file'}
          onChangeFile={(file) => {
            console.log(file);
            onChange(file);
          }}
          optional={optional}
          {...extraProps}
        />

      default:
        return null;
    }
  }
  return (
    <div className="d-field-container container-fluid">
      <div className="row align-items-center ">
        <div className="col-md-4">
          <div className="d-field-label mb-2">
            {label}
            {
              !optional && <span style={{ color: 'red' }}> * </span>
            }
          </div>
        </div>
        <div className="col-md-8">
          {fieldElementReturner()}
        </div>
        {
          error && errorText &&
          <div className="col-md-8 offset-md-4">
            <Error>{errorText}</Error>
          </div>
        }
      </div>


    </div>
  )

}


export default DForm