import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./view_warehouse.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import { Models } from "utils/imports.utils";
import { useSetState } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";

export default function ViewCharger() {
  const navigate = useNavigate();
  const { id, cId } = useParams();
  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
  });
  useEffect(() => {
    GetCharger();
  }, []);

  const GetCharger = async () => {
    try {
      const response: any = await Models.warehouse.getCharger({
        wId: id,
      });
      setState({
        data: response.data[0].chargingPoint.filter(
          (item) => item._id === cId
        )[0],
      });
      console.log(
        response.data[0].chargingPoint.filter((item) => item._id === cId)
      );
    } catch (err) {
      console.log(err);
    }
  };

  const inputFields: IAddValues[] = [
    { label: "Charger Type", key: "chargerType", type: "string" },
    { label: "Charger Unit", key: "chargerUnit", type: "string" },
    { label: "Charger Value", key: "chargerValue", type: "string" },
    {
      label: "Currently Occupied",
      key: "currentlyOccupied",
      type: "boolean",
    },
    { label: "Capacity", key: "capacity", type: "string" },
    { label: "Connector", key: "connector", type: "string" },
    {
      label: "Vehicle Type",
      key: "vehicleType",
      type: "string",
    },
    { label: "Availability", key: "availability", type: "string" },
  ];

  return (
    <div className="view_warehouse_container">
      <div className="view_warehouse_wrapper">
        <History name={state.data?.chId} path={"view"} />
        <div className="view_warehouse_body_container">
          <View
            actions={[
              { link: `/warehouse/charger/edit/${id}/${cId}`, icon: "edit" },
            ]}
            data={state.data}
            values={inputFields}
            head={<ViewHeader />}
            // buttons={<ViewButton />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );
  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">{state?.data?.chId}</div>
          </div>
        </div>
      </div>
    );
  }
}
