import React, { useEffect, useState } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
import Assets from "imports/assets.import";
import Button from "common_components/ui/button/button.ui";
import "./add_store.screen.scss";
import UploadButton from "common_components/ui/upload_button/upload_button.ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeEmptyValues,
  useSetState,
  toastifyError,
  toastify,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";
import RateCards from "components/rate_cards/rate_cards.component";
import Divider from "common_components/ui/divider/divider.ui";

export default function AddStore() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { clientId, id } = useParams();

  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");

  const initialValue = {
    storeName: "",
    phone: "",
    state: "",
    city: "",
    fullAddress: "",
    geoLocation: "",
    type: "",
    ffCluster: "",
    ffSupervisor: "",
    controlCenterId: "",
    storeInCharge: {
      name: "",
      number: "",
      emailId: "",
    },
  };
  const selectOption = [{ label: "Test", value: "test" }];

  const [state, setState] = useSetState({
    data: initialValue,
    loading: false,
    selectOptions: selectOption,
    buttonDisabled: false,
    storeLoading: false,
  });
  const [storeId, setStoreId] = useState(isEditable ? id : "");
  const inputFields: IAddValues[] = [
    { label: "Store name", key: "storeName", type: "string" },
    { label: "Mobile", key: "phone", type: "string" },
    { label: "State", key: "state", type: "state" },
    { label: "City", key: "city", type: "city" },
    { label: "Enter address", key: "fullAddress|geoLocation", type: "map" },
    {
      label: "Type",
      key: "type",
      type: "select",
      options: [
        { label: "Grocery", value: "GROCERY" },
        { label: "Fresh", value: "FRESH" },
        { label: "Large", value: "LARGE" },
        { label: "Non Large", value: "NON_LARGE" },
      ],
    },
    {
      label: "Store incharge name",
      key: "storeInCharge.name",
      type: "string",
      isNested: true,
    },
    {
      label: "Store incharge number",
      key: "storeInCharge.number",
      type: "string",
      isNested: true,
    },
    {
      label: "Store incharge email ID",
      key: "storeInCharge.emailId",
      type: "string",
      isNested: true,
    },
    { label: "Cluster lead", key: "ffCluster", type: "user", optional: true },
    { label: "Supervisor", key: "ffSupervisor", type: "user", optional: true },
    { label: "Control center", key: "controlCenterId", type: "center" },
  ];
  useEffect(() => {
    if (isEditable) {
      GetStore();
    }
  }, []);

  const GetStore = async () => {
    try {
      const response: any = await Models.store.getStore({
        id: id,
      });
      console.log(response.data);
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };


  const handleEdit = async (values) => {
    // values = {
    //   ...values,
    //   geoLocation: {
    //     type: "Point",
    //     coordinates: [values.fullAddress?.lng, values.fullAddress?.lat],
    //   },
    //   fullAddress: values?.fullAddress?.formatted_address,
    // };
    setState({ buttonDisabled: true, storeLoading: true });
    try {
      const final_data = {
        _id  :id,
        storeName: values.storeName,
        phone: values.phone,
        state: values.state,
        city: values.city,
        fullAddress: values.fullAddress,
        geoLocation: values.geoLocation,
        type: values.type,
        ffCluster: values.ffCluster,
        ffSupervisor: values.ffSupervisor,
        controlCenterId: values.controlCenterId,
        storeInCharge: values.storeInCharge,
      }
      const data: any = removeEmptyValues(values);
      // delete data.fullAddress;
      delete data.createdAt;
      const res: any = await Models.store.editStore(final_data);
      toastify("Store Updated !!!");
    } catch (err) {
      console.log(err);
      toastifyError(err);
    } finally {
      setState({ buttonDisabled: false, storeLoading: false });
    }
  };

  const handleCreate = async (values) => {
    setState({ buttonDisabled: true, storeLoading: true });
    try {
      console.log(values);
      const data: any = removeEmptyValues(values);
      data.client = clientId;
      const res: any = await Models.store.createStore(data);
      toastify("Store Created !!!");
      setStoreId(res?.data?._id);
    } catch (err) {
      console.log(err);
    } finally {
      setState({ buttonDisabled: false, storeLoading: false });
    }
  };

  return (
    <div className="add_store_container">
      <div className="add_store_wrapper">
        <History
          name={state?.data?.storeName}
          path={isEditable ? `/client/Edit store` : `/client/Add store`}
        />
        <div className="add_store_body_container">
          {isEditable || !storeId ? (
            <Add
              title={`${isEditable ? "Edit" : "Add"} Store`}
              actions={[{ link: "/", icon: "view" }]}
              data={state.data}
              values={inputFields}
              initialValues={initialValue}
              validationSchema={isEditable ? "" : "store"}
              onSubmit={isEditable ? handleEdit : handleCreate}
              buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
              hasFiles
            />
          ) : null}
          {storeId ? (
            <>
              <RateCards updateMode={isEditable} storeId={storeId} />
              <br />
              <div className="d-flex justify-content-center">
                <Button
                  value="Back to stores"
                  onClick={() => navigate(`/client/${clientId}/store`)}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          <div className="view_button">
            <Button
              loading={state.storeLoading}
              loadingText="Uploading your details please wait..."
              buttonDisabled={props.buttonDisabled}
              value={isEditable ? "Update" : "Create store"}
            />
          </div>
        </div>
      </div>
    );
  }

  function CustomInputs() {
    return <div className="custom_inputs"></div>;
  }
}
