import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import React, { useEffect } from "react";
import "./icon_table.component.scss";
import Assets from "imports/assets.import";

interface IconTableProps {
  icon?: string;
  color?: string;
  title?: string;
  count?: string;
  onClick?: any;
}

export default function IconTable(props: IconTableProps) {
  useEffect(() => {}, []);

  return (
    <div className="table-icon-container" onClick={props.onClick}>
      <div className="desc">
        <div className="desc1">
          <div className="img-div" style={{ backgroundColor: props.color }}>
            <img
              src={Assets[props.icon || ""]}
              width={22}
              height={22}
              className=" icon"
              alt="view"
            />
          </div>
          <div className="count">{props.count}</div>
          <div className="title">{props.title}</div>
        </div>
        <div className="desc2">
          <div className="sidebar_icon"> &gt; </div>
        </div>
      </div>
    </div>
  );
}
