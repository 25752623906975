import { createSlice } from '@reduxjs/toolkit'
import {
    setClientPOVStoreLogin,
    setClientPOVStoreLogout,
    getLoggedInClientPOVStore
} from '../actions/client.pov.store.actions'
import { STATUSES } from '../../utils/constant.utils'

const initialState = {
    loading: false,
    store: {},
    error: null,
    loggedIn: false,
    status: STATUSES.FAILED,
}
export type ClientPOVStoreType = {
    loading: boolean;
    store: any;
    error: null;
    loggedIn: boolean;
    status: string;
}
const ClientPOVStore = createSlice({
    name: 'client-pov-store',
    reducers: {},
    initialState,
    extraReducers: (builder) => {
        //login
        builder.addCase(setClientPOVStoreLogin.pending, (state, action: any) => {
            state.loading = true
        })
        builder.addCase(setClientPOVStoreLogin.fulfilled, (state, action: any) => {
            state.loading = false
            state.loggedIn = true;
            state.store = action.payload.store;
            state.status = STATUSES.SUCCESS
        })
        builder.addCase(setClientPOVStoreLogin.rejected, (state, action: any) => {
            state.loading = false
            state.error = action.payload
            state.status = STATUSES.FAILED
            state.loggedIn = false;
        })
        //logout
        builder.addCase(setClientPOVStoreLogout.pending, (state, action: any) => {
            state.loading = true
        })
        builder.addCase(setClientPOVStoreLogout.fulfilled, (state, action: any) => {
            state.loading = false
            state.loggedIn = false;
            state.store = {}
            state.status = STATUSES.SUCCESS
        })
        builder.addCase(setClientPOVStoreLogout.rejected, (state, action: any) => {
            state.loading = false
            state.error = action.payload
            state.status = STATUSES.FAILED
        })

        //get store
        builder.addCase(getLoggedInClientPOVStore.pending, (state, action: any) => {
            state.loading = true
        })
        builder.addCase(getLoggedInClientPOVStore.fulfilled, (state, action: any) => {
            state.loading = false
            state.loggedIn = true;
            state.store = action.payload.store;
            state.status = STATUSES.SUCCESS
        })
        builder.addCase(getLoggedInClientPOVStore.rejected, (state, action: any) => {
            state.loading = false
            state.error = action.payload
            state.status = STATUSES.FAILED
        })
    }
})
export default ClientPOVStore.reducer
