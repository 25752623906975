import axios, { AxiosInstance } from "axios";

const source = process.env.REACT_APP_SOURCE_DEV as string;

const instance = (url?:string): AxiosInstance => {
  const apiInstance = axios.create({
    baseURL: url ? url : process.env.REACT_APP_NODE + "/api/v2/",
  });
  apiInstance.interceptors.request.use(async (request) => {
    let accessToken = localStorage.getItem("accessToken") || "defaultToken";
    console.log(`Calling request: ${request.baseURL}${request.url}`);
    if (accessToken) {
      //@ts-ignore
      request.headers["Authorization"] = accessToken;
    }
    apiInstance.defaults.headers.common["source"] = source;

    return request;
  });
  // apiInstance.defaults.headers.common["authorization"] = accessToken;
  // apiInstance.defaults.headers.common["source"] = source;
  // apiInstance.interceptors.request.use(
  //   async (config) => {
  //     return config;
  //   }
  // );
  // for response
  apiInstance.interceptors.response.use(
    async (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      try {
        if (error.response.status === 403 && !originalRequest._retry) {
          console.log("==========Intercept Response============");
          originalRequest._retry = true;
          await checkTokenExpiration();
          return apiInstance(originalRequest);
        }
        return Promise.reject(error);
      } catch (err: any) {
        console.log("Err in interceptor :", err);
        return Promise.reject(error);
      }
    }
  );
  const checkTokenExpiration = async () => {
    try {
      let refreshToken = localStorage.getItem("refreshToken") || "defaultToken";
      const response = await axios.post(
        `${process.env.REACT_APP_NODE + "/api/v2"}/users/refreshToken`,
        {},
        {
          headers: {
            refreshtoken: `${refreshToken}`,
          },
        }
      );
      if (response.data.status) {
        const newAccessToken = response.data?.data?.access_token;
        console.log("new token", newAccessToken);
        localStorage.setItem("accessToken", newAccessToken);
        apiInstance.defaults.headers.common["authorization"] = newAccessToken;
      }
    } catch (error) {
      console.error("Error checking token expiration:", error);
    }
  };
  // setInterval(checkTokenExpiration, 60 * 60 * 1000);
  return apiInstance;
};

export default instance;
