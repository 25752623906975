import { createSlice } from '@reduxjs/toolkit'
import {
    setClientPOVStoreLogin,
    setClientPOVStoreLogout,
    getLoggedInClientPOVStore
} from '../actions/client.pov.store.actions'
import { STATUSES } from '../../utils/constant.utils'
import { getAccessControls } from 'store/actions/access.control.actions'

const initialState = {
    loading: false,
    data: {
        activeACL : []
    },
    error: null,
    loggedIn: false,
    status: STATUSES.FAILED,
}
export type ClientPOVStoreType = {
    loading: boolean;
    store: any;
    error: null;
    loggedIn: boolean;
    status: string;
}
const AccessControlSlice = createSlice({
    name: 'client-pov-store',
    reducers: {},
    initialState,
    extraReducers: (builder) => {
        //login
        builder.addCase(getAccessControls.pending, (state, action: any) => {
            state.loading = true
        })
        builder.addCase(getAccessControls.fulfilled, (state, action: any) => {
            state.loading = false
            state.loggedIn = true;
            state.data = action.payload.data;
            state.status = STATUSES.SUCCESS
        })
        builder.addCase(getAccessControls.rejected, (state, action: any) => {
            state.loading = false
            state.error = action.payload
            state.status = STATUSES.FAILED
            state.loggedIn = false;
        })
        
    }
})
export default AccessControlSlice.reducer
