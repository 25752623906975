import React, { CSSProperties, FC } from "react";

interface dividerProps {
  style?: CSSProperties;
  color?: string;
}
const Divider: FC<dividerProps> = ({ style, color = "#6c757d" }) => {
  return (
    <div className="divider_container">
      <div
        style={{
          margin: "10px 0",
          height: 0.5,
          opacity: 1,
          background: "rgb(239 240 240)",
          ...style,
        }}
      ></div>
    </div>
  );
};

export default Divider;
