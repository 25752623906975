import Colors from 'imports/color.import';
import React from 'react'

export interface AvatarProps extends React.HTMLAttributes<HTMLDivElement>{
  size : number,
  name? : string,
  backgroundColor? : string;
  imageSrc? : any,
  imageProps? : React.ImgHTMLAttributes<HTMLImageElement>
}

const Avatar:React.FC<AvatarProps> = ({
  size = 70,
  style,
  name,
  backgroundColor = Colors.primary,
  imageSrc,
  imageProps = {src:null},
  ...rest
}) => {
  const {src ,...image} = imageProps 
  return (
    <div
      style={{
        height : size,
        width : size,
        borderRadius  :size /2,
        backgroundColor,
        display  :'flex',
        justifyContent : 'center',
        alignItems :"center",
        overflow : 'hidden',
        ...style
      }}
      {...rest}
    >
      {
        imageSrc ?
        <img style={{height : "100%" , width : "100%" , objectFit : "cover"}} src={imageSrc} alt="" {...image}/>
        : null
      }
      {
        !imageSrc && name ?
        <span
        style={{
          fontSize : size * (60 /100),
          color : Colors.white
        }}
        >{name?.split(" ")[0].charAt(0)}</span>
        : null
      }
    </div>
  )
}

export default Avatar