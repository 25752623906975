export const Team = ["Founders", "Hr", "It", "Ops", "Finance"];

export type ACCESS_CONTROL_KEYS_TYPE =
  | "DASHBOARD_VIEW"
  | "CONTROL_CENTER_VIEW"
  | "CONTROL_CENTER_ADD"
  | "CONTROL_CENTER_EDIT"
  | "CONTROL_CENTER_DELETE"
  | "CONTROL_CENTER_EXPORT"
  | "USER_VIEW"
  | "USER_ADD"
  | "USER_EDIT"
  | "USER_EXPORT"
  | "USER_DELETE"
  | "USER_APPROVE"
  | "USER_ARCHIVE"
  | "USER_TERMINATE"
  | "USER_RESIGN"
  | "USER_UNARCHIVE"
  | "CLIENT_VIEW"
  | "CLIENT_EDIT"
  | "CLIENT_DELETE"
  | "CLIENT_ADD"
  | "CLIENT_APPROVE"
  | "CLIENT_ARCHIVE"
  | "CLIENT_STORE_VIEW"
  | "CLIENT_EXPORT"
  | "STORE_ADD"
  | "STORE_EDIT"
  | "STORE_VIEW"
  | "STORE_DELETE"
  | "STORE_ARCHIVE"
  | "STORE_EXPORT"
  | "DRIVER_ONBOARD"
  | "DRIVER_APPROVE"
  | "DRIVER_REJECT"
  | "DRIVER_EDIT"
  | "DRIVER_DELETE"
  | "DRIVER_ARCHIVE"
  | "DRIVER_UNARCHIVE"
  | "DRIVER_VIEW"
  | "DRIVER_TERMINATE"
  | "DRIVER_RESIGN"
  | "DRIVER_CHECKIN"
  | "DRIVER_EXPORT"
  | "CHECKIN_VIEW"
  | "CHECKIN_EDIT"
  | "WAREHOUSE_ADD"
  | "WAREHOUSE_EDIT"
  | "WAREHOUSE_VIEW"
  | "WAREHOUSE_CHARGING"
  | "WAREHOUSE_POWER_BILL"
  | "WAREHOUSE_EXPORT"
  | "WAREHOUSE_DELETE"
  | "VEHICLE_ADD"
  | "VEHICLE_EXPORT"
  | "VEHICLE_QR"
  | "VEHICLE_VIEW"
  | "VEHICLE_DELETE"
  | "VEHICLE_EDIT"
  | "CAREER_ADD"
  | "CAREER_EXPORT"
  | "CAREER_VIEW"
  | "CAREER_DELETE"
  | "CAREER_EDIT";

export const Controls = [
  "DASHBOARD_VIEW",
  "CONTROL_CENTER_VIEW",
  "CONTROL_CENTER_ADD",
  "CONTROL_CENTER_EDIT",
  "CONTROL_CENTER_DELETE",
  "CONTROL_CENTER_EXPORT",
  "USER_VIEW",
  "USER_ADD",
  "USER_EDIT",
  "USER_EXPORT",
  "USER_DELETE",
  "USER_APPROVE",
  "USER_ARCHIVE",
  "USER_TERMINATE",
  "USER_RESIGN",
  "USER_UNARCHIVE",
  "CLIENT_VIEW",
  "CLIENT_EDIT",
  "CLIENT_DELETE",
  "CLIENT_ADD",
  "CLIENT_APPROVE",
  "CLIENT_ARCHIVE",
  "CLIENT_STORE_VIEW",
  "CLIENT_EXPORT",
  "STORE_ADD",
  "STORE_EDIT",
  "STORE_VIEW",
  "STORE_DELETE",
  "STORE_ARCHIVE",
  "STORE_EXPORT",
  "DRIVER_ONBOARD",
  "DRIVER_APPROVE",
  "DRIVER_REJECT",
  "DRIVER_EDIT",
  "DRIVER_DELETE",
  "DRIVER_ARCHIVE",
  "DRIVER_UNARCHIVE",
  "DRIVER_VIEW",
  "DRIVER_TERMINATE",
  "DRIVER_RESIGN",
  "DRIVER_CHECKIN",
  "DRIVER_EXPORT",
  "CHECKIN_VIEW",
  "CHECKIN_EDIT",
  "WAREHOUSE_ADD",
  "WAREHOUSE_EDIT",
  "WAREHOUSE_VIEW",
  "WAREHOUSE_CHARGING",
  "WAREHOUSE_POWER_BILL",
  "WAREHOUSE_EXPORT",
  "WAREHOUSE_DELETE",
  "VEHICLE_ADD",
  "VEHICLE_EXPORT",
  "VEHICLE_QR",
  "VEHICLE_VIEW",
  "VEHICLE_DELETE",
  "VEHICLE_EDIT",
  "CAREER_ADD",
  "CAREER_EXPORT",
  "CAREER_VIEW",
  "CAREER_DELETE",
  "CAREER_EDIT",
];
