import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./view_vehicle.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Button from "common_components/ui/button/button.ui";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { useSetState, toastifyError, toastify } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import DeletePopup from "components/delete_popup/delete_popup.component";
import CustomModal from "common_components/ui/modal/modal.component";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import ActionButton from "common_components/ui/action_button/action_button.ui";
import Divider from "common_components/ui/divider/divider.ui";
import moment from "moment";
import Pagination from "components/pagination/pagination.component";

export default function ViewVehicle() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const params = useParams();
  const pathArr = pathname.split("/");

  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
    driverAllocated: [],
    deallocateModal: false,
    driverAllocationHistory: [],
    currentPage: 1,
    totalItems: 0,
  });
  useEffect(() => {

    GetVehicle();
    GetDriverAllocationHistory();
    getAllocatedDriver();
  }, []);
  useEffect(() => {
    GetDriverAllocationHistory();
  }, [state.currentPage]);
  const getAllocatedDriver = async () => {
    try {
      let res: any;
      const body: any = {
        vId: params.id,
      };
      res = await Models.vehicle.getAllocatedDriver(body);
      setState({ driverAllocated: res.data[0] });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  const GetVehicle = async () => {
    try {
      const response: any = await Models.vehicle.getVehicle({
        id: params.id,
      });
      setState({ data: response.data.data[0] });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const GetDriverAllocationHistory = async () => {
    try {
      const response: any = await Models.vehicle.getDriverAllocationHistory({
        id: params.id,
        type: 'vehicle',
        page: state.currentPage,
        pageSize: 5,
      });
      setState({ driverAllocationHistory: response.data, totalItems: response?.count, });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const handleDelete = (data) => {
    setState({ id: data._id, deleteModal: true });
  };
  const handleImmobilise = (data: any) => {
    setState({ vhNo: data.vhNumber, rejectModal: true });
  };

  const handleMobilise = (data: any) => {
    setState({ vhNo: data.vhNumber, approvalModal: true });
  };
  const handleStatus = async (vhno, status) => {
    try {
      const body = {
        vhNo: vhno,
        partner: "INTELLICAR",
      };
      if (status === "MOBILISE") {
        const res: any = await Models.vehicle.mobiliseVehicle(body);
        toastify(res?.message?.vhDataResp?.data);
      }
      if (status === "IMMOBILISE") {
        const res: any = await Models.vehicle.immobiliseVehicle(body);
        toastify(res?.message?.vhDataResp?.data);
      }

      setState({
        rejectModal: false,
        approvalModal: false,
      });
      navigate("/vehicle");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const DeleteVehicle = async () => {
    try {
      await Models.vehicle.deleteVehicle({
        ids: [state.id],
        target: "delete",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
      });
      setState({ id: "", deleteModal: false });
      navigate("/vehicle");
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const handleViewAllottedDriver = () => {
    navigate(`/driver/view/${state?.driverAllocated?.driverId?.userId}`);
  };
  const handleDeAllocation = async () => {
    try {
      const res: any = await Models.driver.deAllocation(
        state?.driverAllocated?._id
      );
      GetVehicle();
      toastify("Driver de-allocated successfully!");
    } catch (err) {
      // toastifyError(err);
      console.log(err);
    }
  };
  const inputFieldsworking: IAddValues[] = [
    {
      label: "Carrier Type",
      key: "vhType",
      type: "string",
    },
    { label: "Vehicle Registration Number", key: "vhNumber", type: "string" },
    { label: "Vehicle Reg Name", key: "vhRegName", type: "string" },
    { label: "Vehicle Reg Contact", key: "vhRegContact", type: "string" },
    { label: "Vehicle Make", key: "vhCompany", type: "string" },
    { label: "Vehicle Model", key: "vhModel", type: "string" },
    {
      label: "Leasing Partner",
      key: "leasingPartner",
      type: "string",
    },
    { label: "Dealer Name", key: "dealerName", type: "string" },
    { label: "Purchase Date", key: "purchaseDate", type: "date" },
    { label: "Leasing Tenure (Months)", key: "leasingTenure", type: "string" },
    { label: "Monthly Leasing Amount", key: "leasingAmount", type: "string" },
    { label: "Security Deposit", key: "securityDeposit", type: "string" },
    { label: "Residue Amount", key: "residueAmount", type: "string" },
    {
      label: "Professional Charges",
      key: "professionalCharges",
      type: "string",
    },
    { label: "Insurance Amount", key: "insuranceAmount", type: "string" },
    {
      label: "Insurance (Start Date)",
      key: "insuranceStartDate",
      type: "date",
    },
    { label: "Insurance (End Date)", key: "insuranceEndDate", type: "date" },
    { label: "AMC Amount", key: "amcAmount", type: "string" },
    { label: "AMC (Start Date)", key: "amcStartDate", type: "date" },
    { label: "AMC (End Date)", key: "amcEndDate", type: "date" },
    { label: "KM Cap Monthly", key: "kmCapMonthly", type: "string" },
    { label: "Above KM Cap in Rs", key: "aboveKmCapAmount", type: "string" },
    { label: "KM Warranty", key: "kmWarranty", type: "string" },
    { label: "Vehicle Tracking Link", key: "vhTL", type: "string" },
    {
      label: "Battery No - 1",
      key: "batteryOneSeqNumber",
      type: "string",
    },
    {
      label: "Battery No - 1 Warranty Start Date",
      key: "batteryOneWarrantyStartDate",
      type: "date",
    },
    {
      label: "Battery No - 1 Warranty End Date",
      key: "batteryOneWarrantyEndDate",
      type: "date",
    },

    {
      label: "Battery No - 2",
      key: "batteryTwoSeqNumber",
      type: "string",
    },
    {
      label: "Battery No - 2 Warranty Start Date",
      key: "batteryTwoWarrantyStartDate",
      type: "date",
    },
    {
      label: "Battery No - 2 Warranty End Date",
      key: "batteryTwoWarrantyEndDate",
      type: "date",
    },
    {
      label: "Motor Number",
      key: "motorNumber",
      type: "string",
    },
    {
      label: "Charger Number (+)",
      key: "chargerNumber",
      type: "string",
    },
    {
      label: "Controller Number",
      key: "controllerNumber",
      type: "string",
    },
    { label: "Convertor Number", key: "converterNumber", type: "string" },
    { label: "Chassis Number", key: "chassisNumber", type: "string" },

    { label: "State", key: "state", type: "state" },
    { label: "City", key: "city", type: "city" },
    {
      label: "Manufacture Year",
      key: "manufactureYear",
      type: "string",
    },
    {
      label: "Bike Image (Document must be below 2 MB size) ",
      key: "vhImage",
      type: "file",
    },
    {
      label: "RC Book Front (Document must be below 2 MB size) ",
      key: "rcImageF",
      type: "passbook",
    },
    {
      label: "RC Book Back (Document must be below 2 MB size) ",
      key: "rcImageB",
      type: "passbook",
    },
    {
      label: "Insurance Image (Document must be below 2 MB size) ",
      key: "insuranceImage",
      type: "passbook",
    },
    {
      label: "Invoice Image (Document must be below 2 MB size) ",
      key: "invoiceImage",
      type: "passbook",
    },
    {
      label: "Warehouse",
      key: "wareHouseId.name",
      type: "string",
      isNested: true,
    },
    { label: "Insurance Number", key: "insuranceNumber", type: "string" },
    {
      label: "RC Number",
      key: "rcNumber",
      type: "string",
    },
  ];
  return (
    <div className="view_vehicle_container">
      <div className="view_vehicle_wrapper">
        <History name={state.data?.vhModel} path={"view"} />
        <div className="view_container container-fluid p-5 ">
          <h3>Driver Allocation History ({state?.driverAllocationHistory?.length ?? 0})</h3>
          <Divider />
          {/* Header */}
          <table width={"100%"} cellPadding={5}>
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>D number</th>
                <th>Assigned By</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                state?.driverAllocationHistory?.map((item, index) => (
                  <tr key={index} >
                    <td>{index + 1}</td>
                    <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                    <td>{item?.assignedTo?.fullName}</td>
                    <td>{item?.assignedTo?.mobile}</td>
                    <td>{item?.assignedDriverId?.dNumber}</td>
                    <td>
                      <b>Email : </b> {item?.createdBy?.email}
                    </td>
                    <td className="text-center">
                      {
                        item?.assignedTo?._id ?
                          <Button
                            onClick={() => navigate(`/driver/view/${item?.assignedTo?._id}`)}
                            style={{ padding: "5px 10px" }} value="View driver" />
                          : "NA"
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
          <div className="driver_pagination">
            <Pagination
              totalItems={state.totalItems}
              itemsPerPage={5}
              currentPage={state.currentPage}
              onPageChange={(page)=>setState({ currentPage: page })}
            />
          </div>
        <div className="view_vehicle_body_container">
          <View
            actions={[
              {
                key: "VEHICLE_VIEW",
                link: `/vehicle/edit/${id}`,
                icon: "edit",
              },
            ]}
            data={state.data}
            values={inputFieldsworking}
            head={<ViewHeader />}
            // buttons={<ViewButton />}
            hasFiles
          />
          {/* <View
            actions={[]}
            data={state?.statData}
            values={statFields}
            // buttons={<ViewButton />}
          /> */}
          <div className="view-action-req">
            <div className="view-actio-h">Take Actions</div>
            <div className="accept-decline">
              <ActionButton
                icon={"approve"}
                onClick={handleMobilise}
                label={"Mobilise"}
                className="req-button"
              />
              <ActionButton
                icon={"decline"}
                onClick={handleImmobilise}
                label={"Immobilise"}
                className="req-button"
              />
            </div>
          </div>
        </div>
      </div>
      {/* reject Modal */}
      <CustomModal
        center
        open={state.rejectModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ rejectModal: false })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">Immobilise this?</div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ rejectModal: false });
              }}
              className="reject_cancel_button"
            >
              No
            </div>
            <div
              onClick={() => handleStatus(state.vhNumber, "IMMOBILISE")}
              className="reject_button"
            >
              Yes
            </div>
          </div>
        </div>
      </CustomModal>
      {/* reject modal end */}
      {/* approve modal */}
      <CustomModal
        center
        open={state.approvalModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ approvalModal: false })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">Mobilise this?</div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ approvalModal: false });
              }}
              className="reject_cancel_button"
            >
              No
            </div>
            <div
              onClick={() => handleStatus(state.vhNumber, "MOBILISE")}
              className="reject_button"
            >
              Yes
            </div>
          </div>
        </div>
      </CustomModal>
      {/* approve modal end */}
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      >
        <DeletePopup
          onPress={DeleteVehicle}
          onCancel={() => setState({ deleteModal: false })}
        />
      </CustomModal>
      <CustomModal
        center
        open={state.deallocateModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deallocateModal: false })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">
            Are you sure you want to deallocate vehicle?
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ deallocateModal: false });
              }}
              className="reject_cancel_button"
            >
              No
            </div>
            <div onClick={() => handleDeAllocation()} className="reject_button">
              Yes
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );

  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">{state.data?.vhModel}</div>
            {state.driverAllocated && (
              <div className="allocated_to">
                Allocated to - {state?.driverAllocated?.driverId?.dNumber}
                <img
                  src={Assets["view"]}
                  onClick={() => handleViewAllottedDriver()}
                />
                <img
                  src={Assets["delete"]}
                  onClick={() => setState({ deallocateModal: true })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
