import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { useSetState } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";

export default function ViewUser() {
  const role = localStorage.getItem("role");
  const { id } = useParams();
  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
  });
  useEffect(() => {
    GetUser();
  }, []);

  const GetUser = async () => {
    try {
      const response: any = await Models.user.getUser({
        id: id,
      });
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
    }
  };

  const inputFields: IAddValues[] = [
    { label: "Full name", key: "fullName", type: "string" },
    { label: "Gender", key: "gender", type: "string" },
    { label: "Dob", key: "dob", type: "date" },
    { label: "Blood Group", key: "bloodGroup", type: "string" },
    { label: "Mobile", key: "mobile", type: "string" },
    { label: "WhatsApp number", key: "wapNumber", type: "string" },
    { label: "Secondary mobile no", key: "secondaryMobileNo", type: "string" },
    { label: "Father name", key: "fatherName", type: "string" },
    { label: "Languages", key: "languages", type: "string" },
    { label: "Email", key: "email", type: "string" },
    { label: "Profile image", key: "userImage", type: "file" },
    {
      label: "Aadhaar document",
      key: "aadhaarDocument",
      type: "passbook",
    },
    { label: "Pan document", key: "panDocument", type: "passbook" },
    { label: "Control center ", key: "controlCenterId", type: "center" },
    {
      label: "Full Address",
      key: "address[0].fullAddress",
      type: "string",
      isNested: true,
    },
    { label: "State", key: "address[0].state", type: "state", isNested: true },
    { label: "City", key: "address[0].city", type: "city", isNested: true },
    {
      label: "Pincode",
      key: "address[0].pinCode",
      type: "string",
      isNested: true,
    },
    {
      label: "Landmark",
      key: "address[0].landMark",
      type: "string",
      isNested: true,
    },
    {
      label: "Building name",
      key: "address[0].buildingName",
      type: "string",
      isNested: true,
    },
    {
      label: "Remarks on status",
      key: "commentOnStatusChangeAction",
      type: "string",
    },
    {
      label: "Status changed by",
      key: "statusChangedBy.fullName",
      type: "string",
      isNested: true,
    },
  ];

  return (
    <div className="view_driver_container">
      <div className="view_driver_wrapper">
        <History path="employee" name={state?.data?.fullName ?? "View"} />
        <div className="view_driver_body_container">
          <View
            actions={[
              { key: "USER_EDIT", link: `/employee/edit/${id}`, icon: "edit" },
            ]}
            data={state.data}
            values={inputFields}
            head={<ViewHeader />}
            // buttons={<ViewButton />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );
  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_image_conatiner">
          <img
            className="view_head_image"
            src={state?.data?.userImage || Assets.testPic}
            alt="head_image"
          />
        </div>
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">
              {state.data?.fullName} - {state.data?.role?.[0]}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
