import Models from 'imports/models.import';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import React, { FC, useEffect, useState } from 'react'

export interface DCustomSelectProps  extends DropdownProps {
  type: "state"
  | "city"
  | "vehicle"
  | "user"
  | "center"
  | "warehouse";
  linkedWith?: string,
  onChange: (value: any) => void;
  value: any,
  placeholder?: string,
  linkedValue : any,
}

const DCustomSelect: FC<DCustomSelectProps> = ({
  type,
  linkedValue,
  style,
  ...rest
}) => {
  const [data ,setData ] = useState([])
  const [loading ,setLoading ] = useState(false)
  const fetchStates = async () => {
    
    try {
      const response = await fetch("https://api-dev.users.fullfily.com/api/v1/smc/location?key=st", {
        method: "GET",
      });
      if (response.ok) {
        //Data filtration
        const responseData = await response.json();
        const temp = responseData?.data?.map((item: string) => ({
          label: item,
          value: item,
        }));
        setData(temp)
      } else {
        console.error(
          "Failed to fetch states:",
          response.status,
          response.statusText
        );
      }
    } catch (err: any) {
      console.error(err);
    }
  };
  const fetchCities = async () => {
    setLoading(true)
    try {
      const response = await fetch("https://api-dev.users.fullfily.com/api/v1/smc/location?key=ct&value=" + linkedValue, {
        method: "GET",
      });
      if (response.ok) {
        //Data filtration
        const responseData = await response.json();
        const temp = responseData?.data?.map((item: string) => ({
          label: item,
          value: item,
        }));
        setData(temp)
      } else {
        console.error(
          "Failed to fetch states:",
          response.status,
          response.statusText
        );
      }
    } catch (err: any) {
      console.error(err);
    }finally{
      setLoading(false)
    }
  };
  const fetchWarehouse = async () => {
    const body: any = {};
    try {
      const res: any = await Models.warehouse.getManyWarehouse(body);
      const temp = res?.data?.map((item: any) => ({
        label: item?.name,
        value: item?._id,
      }));
      setData(temp);
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  const Actions = {
    state: fetchStates,
    // driver: GetManyDriver,
    // all_city: GetManyCities,
    // vehicle: GetManyVehicle,
    // user: GetManyUser,
    // center: GetManyCenter,
    warehouse: fetchWarehouse,
  };
  const fetchData = async () => {
    try {
      setLoading(true)
      await Actions[type]();
    } catch (err) {
      console.log("ERR in D-FORM CUSTOM FETCH", err);
    } finally {
      setLoading(false)
    }
  };
  useEffect(()=>{
    fetchData()
  },[])
  //effect for linked values
  useEffect(()=>{
    if(type === 'city' && linkedValue) fetchCities()
    // rest.onChange({value : ""});
  },[linkedValue])

  if(['state','city','warehouse','abc'].includes(type)){
    return <Dropdown
        loading={loading}
        style={{ width: "100%",...style}}
        options={data}
        {...rest}
      />
  }else{
    return <></>
  }
}

export default DCustomSelect