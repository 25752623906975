import { InputText, InputTextProps } from 'primereact/inputtext'
import React from 'react'

export interface DInputTextProps extends InputTextProps{

}

const DInputText:React.FC<DInputTextProps> = ({
  style,
  ...rest
}) => {
  return (
    <InputText
      style={{width : "100%" , ...style}}
      {...rest}    
    />
  )
}

export default DInputText