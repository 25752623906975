import React, { useEffect } from "react";
import "./list.component.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

interface IListComponentProps {
  selected?: any;
  data?: any;
  edit?: any;
  view?: any;
  delete?: any;
  color?: string;
  delete_icon_color?: string;
  loading?: boolean;
  theads: { head: string; value: string }[];
  link: string;
  percent?: any;
}

export default function ListComponent(props: IListComponentProps) {
  useEffect(() => {}, []);

  return (
    <div className="list_container">
      <div className="row">
        <div className="index_wrapper">
          <div className="index"></div>
        </div>
        <div className="list_wrappers">
          {props.theads.map((head,index) => (
            <div key={index} className="list_containers">
              <div className="head_wrappers">
                <div className="key_texts">{head.value}</div>
                <div className="head_texts">{head.head}</div>
              </div>
              {/* <div className="key_wrappers">
                <div className="key_texts">{head.value}</div>
              </div> */}
            </div>
          ))}
          <div className="list-progress-bar">
            <CircularProgressbar
              value={props.percent}
              text={`${Math.floor(props.percent)}%`}
              styles={buildStyles({
                textColor: "black",
              })}
            />
          </div>
        </div>
        <div className="action_wrapper"></div>
      </div>
    </div>
  );
}
