import instance from "utils/axios.utils";
import Functions from "utils/functions.utils";

const customer = {
  getMe: () => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/me";
      instance()
        .get(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
};

export default customer;
