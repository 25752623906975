import instance from "utils/axios.utils";
import Functions from "utils/functions.utils";

const user = {
  getManyUser: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "users/sa/list";
        const response = await instance().get(url, { params: query });
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },

  getUser: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "users/sa/list";
        const response = await instance().get(url, { params: id });
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  editUser: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/create-instantly";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  approveUser: async (id) => {
    try {
      const url = `users/sa/approve?userId=${id}`;
      const response = await instance().patch(url);
      return response.data;
    } catch (error) {
      throw Functions.modelError(error);
    }
  },
  resignTerminateUser: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/sa/end-journey";
      instance()
        .patch(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },

  rejectUser: async (id) => {
    try {
      const url = `users/sa/reject?userId=${id}`;
      const response = await instance().patch(url);
      return response.data;
    } catch (error) {
      throw Functions.modelError(error);
    }
  },
  deleteUser: (payload) => {
    let promise = new Promise((resolve, reject) => {
      let url = "users/sa/drop";
      instance()
        .delete(url, { data: payload })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  exportUser: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "users/sa/list?export=1";
        const response = await instance().get(url);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
};

export default user;
