import React, { FC } from 'react'
import "./loader.ui.scss"

export interface LoaderProps extends React.HTMLAttributes<HTMLDivElement>{
  size? : number,
  primary?  :boolean , 
}
const Loader:FC<LoaderProps> = ({size = 20 , primary = false,style,...rest}) => {
  return (
    <div style={{width : size , backgroundSize : `${size * 0.25}px ${size * 0.25}px` , ...style}} className={`loader ${primary ? "loader-primary" : ""}`} {...rest}></div>
  )
}

export default Loader