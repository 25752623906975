import React from "react";
import "./select_dropdown.component.scss";
import Colors from "imports/color.import";
import Select, { StylesConfig } from 'react-select';
interface ISelectDropdown {
  value: string;
  onChange: Function;
  data: any;
  placeholder?: string;
  notfound: any;
  width?: any;
}


const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles: StylesConfig<any> = {
  control: (styles) => ({ ...styles, backgroundColor: Colors.white ,
    padding : 5 ,
    ':active': {
      ...styles[':active'],
      borderColor : Colors.primary
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? 'black'
        : data.color,
      ':active': {
        ...styles[':active'],
        color: !isDisabled
          ? isSelected
            ? data.color
            : Colors.white
          : undefined,
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};
const SelectDropdown = (props: ISelectDropdown) => {
  const handleFilter = (e: any) => {
    console.log("filter", e);
    props.onChange(e);
  };

  return (
    <div className="select_dropdown_container">
      <Select
        theme={(theme) => ({
          ...theme,
          borderRadius: 34,
          width:500,
          backgroundColor: Colors.background,
        })}
        styles={colourStyles}
        
        onChange={(e: any) => handleFilter(e)}
        noOptionsMessage={() => props.notfound}
        placeholder={props.placeholder}
        options={props.data}
      />
    </div>
  );
};

export default SelectDropdown;
