import instance from "utils/axios.utils";
import Functions from "utils/functions.utils";
import { setManyCheckin, setCheckin } from "utils/redux.utils";

const Careers = {
  getJobById: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "career";
        const response = await instance().get(url, { params: id });
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  createCareers: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "career";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  updateCareers: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "career";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  getManyCareers: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "career";
        const response = await instance().get(url, { params: query });
        setManyCheckin(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  exportCareers: () => {
    return new Promise(async (resolve, reject) => {
      try {
        // const url = "checkIns?export=1";
        // const response = await instance().get(url);
        // resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  editJob: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "career";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  deleteJob: (payload) => {
    let promise = new Promise((resolve, reject) => {
      let url = "career";
      instance()
        .delete(url, { data: payload })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
};

export default Careers;
