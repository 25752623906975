const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0KJbhm4yQBvabkOvZ3Ar
RJTDyKyYeD9w9qjDyDkzMCXTv+hSJCT0c+Qe9tKjUxJPWWXbea1d657coIkt/5CG
Xc9pRhT9AsNfNYSPbVcpMaEqeuxEQKy3HxK0jqlZVK5MqFX0sm/WEc2RkeTGWY8D
hDCUJ7O6V9rR2MWpZX6/1FFFqKsniwl6tVuIpLafMoKzgxEuXTEWtTxphIrgCqH5
0zEwKaRXj1Zgo4FRDBS8Bi5GZ0F80AyknjHryngQi/FQcCVFdoL+uNyxuW+a6kDK
jsnwX5tH/ooEKPcJoFjcPGBwa9kzPb2nfA27F61xN2d9UlXT3tJ5mTFK4ymz45WP
8wIDAQAB
-----END PUBLIC KEY-----
`;

export default publicKey;