import {createSlice} from '@reduxjs/toolkit'
import { 
    // deleteAccount, 
    getUser, 
    resetInitMandate, 
    setLogin, 
    setLogout, 
    // updateUser, 
} from '../actions/auth.actions'
import { STATUSES } from '../../utils/constant.utils'

const initialState = {
    loading : false,
    user :{},
    CLIENT_INIT_MANDATE : false,
    CLIENT_INIT_MANDATE_EMAIL : "",
    error: null,
    loggedIn : false,
    status : STATUSES.FAILED,
}
export type authSliceType =  typeof initialState 
const authSlice = createSlice({
    name : 'auth',
    reducers : {},
    initialState,
    extraReducers :(builder)=> {
        //login
        builder.addCase(setLogin.pending, (state , action:any)=>{
            state.loading = true
        })
        builder.addCase(setLogin.fulfilled, (state , action:any)=>{
            state.loading = false
            if(action.payload.CLIENT_INIT_MANDATE){
                state.CLIENT_INIT_MANDATE = true;
                state.CLIENT_INIT_MANDATE_EMAIL = action.payload.CLIENT_INIT_MANDATE_EMAIL;
            }else{
                state.CLIENT_INIT_MANDATE = false;
                state.loggedIn = true;
                state.user  = action.payload.user;
            }
            state.status = STATUSES.SUCCESS
        })
        builder.addCase(setLogin.rejected, (state , action:any)=>{
            state.loading = false
            state.error = action.payload
            state.status = STATUSES.FAILED
            state.loggedIn = false;
        })
        //logout
        builder.addCase(setLogout.pending, (state , action:any)=>{
            state.loading = true
        })
        builder.addCase(setLogout.fulfilled, (state , action:any)=>{
            state.loading = false
            state.loggedIn = false;
            state.user  = {}
            state.status = STATUSES.SUCCESS
        })
        builder.addCase(setLogout.rejected, (state , action:any)=>{
            state.loading = false
            state.error = action.payload
            state.status = STATUSES.FAILED
        })
        //reset INIT_MANDATE
        builder.addCase(resetInitMandate.pending, (state , action:any)=>{
            state.loading = true
        })
        builder.addCase(resetInitMandate.fulfilled, (state , action:any)=>{
            state.CLIENT_INIT_MANDATE = false;
            state.status = STATUSES.SUCCESS
        })
        builder.addCase(resetInitMandate.rejected, (state , action:any)=>{
            state.loading = false
            state.error = action.payload
            state.status = STATUSES.FAILED
        })
        // //deleteAccount
        // builder.addCase(deleteAccount.pending, (state , action:any)=>{
        //     state.loading = true
        // })
        // builder.addCase(deleteAccount.fulfilled, (state , action:any)=>{
        //     state.loading = false
        //     state.loggedIn = false;
        //     state.user  = {}
        //     state.status = STATUSES.SUCCESS
        // })
        // builder.addCase(deleteAccount.rejected, (state , action:any)=>{
        //     state.loading = false
        //     state.error = action.payload
        //     state.status = STATUSES.FAILED
        // })

        //get user
        builder.addCase(getUser.pending, (state , action:any)=>{
            state.loading = true
        })
        builder.addCase(getUser.fulfilled, (state , action:any)=>{
            state.loading = false
            state.loggedIn = true;
            state.user  = action.payload.user;
            state.status = STATUSES.SUCCESS
        })
        builder.addCase(getUser.rejected, (state , action:any)=>{
            state.loading = false
            state.error = action.payload
            state.status = STATUSES.FAILED
        })
        // //update user
        // builder.addCase(updateUser.pending, (state , action:any)=>{
        //     state.loading = true
        // })
        // builder.addCase(updateUser.fulfilled, (state , action:any)=>{
        //     state.loading = false
        //     state.user  = {...state.user,...action.payload};
        // })
        // builder.addCase(updateUser.rejected, (state , action:any)=>{
        //     state.loading = false
        //     state.error = action.payload
        //     state.status = STATUSES.FAILED
        // })
    }
})
export default authSlice.reducer
