import { InputNumber, InputNumberProps } from 'primereact/inputnumber'
import React from 'react'

export interface DInputNumberProps extends InputNumberProps{

}

const DInputNumber:React.FC<DInputNumberProps> = ({
  style,
  ...rest
}) => {
  return (
    <InputNumber
      style={{width : "100%",...style}}
      {...rest}    
    />
  )
}

export default DInputNumber