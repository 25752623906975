import { Form, Formik } from "formik";
import * as yup from "yup";
import "./login.screen.scss";
import InputField from "common_components/ui/field/field.ui";
import Button from "common_components/ui/button/button.ui";
import { Link } from "react-router-dom";
import encrypt from "utils/encrypt";
import { toastifyError } from "utils/functions.utils";
import Models from "imports/models.import";
import { useDispatch } from "react-redux";
import { setLogin } from "store/actions/auth.actions";
import { useState } from "react";
import { emailRegExp } from "utils/validation.utils";

export default function Login() {
  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Please enter email")
      .matches(emailRegExp, "Please enter valid email"),
    password: yup.string().required("Please enter password"),
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleLogin = async (values) => {
    try {
      setLoading(true);
      await dispatch(setLogin(values));
      // if (login?.status === 1) {
      //   window.location.href = "/dashboard";
      // }
    } catch (err) {
      toastifyError("There was a problem logging in!");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="login_screen">
      <div className="login_screen_wrapper">
        <div className="login_screen_container">
          <h1
            style={{
              paddingBottom: "2%",
              textAlign: "center",
            }}
          >
            Login
          </h1>
          <Link to="/sign-up">
            <div className="login_links_container">
              <div className="login_link">
                Dont have an account? <u>Sign up.</u>
              </div>
            </div>
          </Link>
          <div className="login_input_wrapper">
            <Formik
              onSubmit={handleLogin}
              validationSchema={validationSchema}
              initialValues={{
                email: "",
                password: "",
              }}
            >
              <Form>
                <div className="login_input_container">
                  <InputField
                    name="email"
                    type="text"
                    placeholder="Email ID"
                    optional={true}
                  />
                </div>
                <div className="login_input_container">
                  <InputField
                    name="password"
                    type="password"
                    placeholder="Password"
                    optional={true}
                  />
                </div>
                <br />
                <Link to="/sign-up/reset_password">
                  <div className="login_links_container">
                    <div className="login_link">Forgot password?</div>
                  </div>
                </Link>
                <div className="submit_button_container">
                  <Button
                    loading={loading}
                    loadingText="Verifying Credentials..."
                    width="100%"
                    value="Log In"
                  />
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
