import publicKey from "../publicKey";
import JSEncrypt from "jsencrypt";

const encrypt = (value) => {
  console.log("Encrypted value", value);
  const enpt = new JSEncrypt();
  enpt.setPublicKey(publicKey);
  const encryptedValue = enpt.encrypt(value);
  return encryptedValue;
};

export default encrypt;
