import { EnhancedStore, StoreEnhancer, ThunkDispatch, Tuple, UnknownAction, configureStore } from '@reduxjs/toolkit'
import authSlice from './slices/auth.slice';
import clientPovStoreSlice from './slices/client.pov.store.slice';
import accessControlSlice from './slices/access.control.slice';


const rootReducer = {
  auth: authSlice,
  clientPOVStore : clientPovStoreSlice,
  accessControl : accessControlSlice
}
var store:EnhancedStore<any, UnknownAction, Tuple<[StoreEnhancer<{
  dispatch: ThunkDispatch<any, undefined, UnknownAction>;
}>, StoreEnhancer]>>;
// if(false){

store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
})
export default store