import React, { useEffect } from "react";
import Functions, {
  useSetState,
  useQuery,
  toastifyError,
  createExcelFile,
  convertNumberToTwoDigit,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import "./checkin.screen.scss";
import "react-responsive-modal/styles.css";
import Table from "components/table/table.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import DateRangePicker from "common_components/ui/date_range_picker/date_range.component";
import SelectDropdown from "common_components/ui/select_dropdown/select_dropdown.component";
import Button from "common_components/ui/button/button.ui";
import Filter from "common_components/ui/filter/filter.ui";
import Search from "common_components/ui/search/search.ui";
import Pagination from "components/pagination/pagination.component";
import pako from "pako";
import { Buffer } from "buffer";
import { Calendar } from "primereact/calendar";
import Loader from "common_components/ui/loader/loader.ui";
import Colors from "imports/color.import";
import { Dialog } from "primereact/dialog";
import StoreSummery from "./store_summery.popup";
import ClientSummery from "./client_summery.popup";

export default function Checkin() {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const pathArr = pathname.split("/");
  const driverCheckin = params.id;
  console.log(params);

  const [state, setState] = useSetState({
    data: [],
    search: "",
    toggleFilter: false,
    currentPage: 1,
    totalItems: 0,
    filterStatus: "",
    clients: [],
    stores: [],
    storeFetchLoading: false,
    filterVehicle: "",
    filterDriver: "",
    filterDate: undefined,
    checkinVehicleData: [],
    checkinDriverData: [],
    clientSummeryVisible: false,
    storeSummeryVisible: false,
    clientStats : [],
    selectedClient :{}
  });
  const status = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "in-active" },
    { label: "Break", value: "break" },
    { label: "All Status", value: 0 },
  ];
  const thead = [
    { head: "Date", key: "createdAt", type: "date-only" },
    { head: "Driver name", key: "createdBy.fullName", isNested: true },
    { head: "Mobile", key: "createdBy.mobile", isNested: true },
    { head: "Driver no.", key: "driverId.dNumber", isNested: true },
    { head: "Vehicle number.", key: "vehicleId.vhNumber", isNested: true },
    { head: "City", key: "city" },
    { head: "Checkin time", key: "vehicleCheckInTime", type: "time-only" },
    { head: "Checkout time", key: "checkOutTime", type: "time-only" },
    { head: "Checkin Status", key: "checkIn", type: "statuscheck" },
  ];

  const GetManyData = async () => {
    try {
      let res: any;
      const body: any = {
        page: state.currentPage,
        pageSize: 10,
      };
      if (state.search.length > 0) {
        body.search = state.search;

      }
      if (state.filterStatus.length > 0) {
        body.status = state.filterStatus;
      }
      if (state.filterVehicle.length > 0) {
        body.vhNumber = state.filterVehicle;
      }
      if (state.filterDriver.length > 0) {
        body.dId = state.filterDriver;
      }
      if (state.filterStore) {
        body.sId = state.filterStore;
      }
      if (state.filterDate) {
        let date: Date[] = state.filterDate;
        body.startDate = `${date[0].getFullYear()}-${convertNumberToTwoDigit(date[0].getMonth() + 1)}-${convertNumberToTwoDigit(date[0].getDate())}T00:00:00.000Z`
        body.endDate = `${date[1].getFullYear()}-${convertNumberToTwoDigit(date[1].getMonth() + 1)}-${convertNumberToTwoDigit(date[1].getDate())}T00:00:00.000Z`
      }
      if (driverCheckin) {
        console.log("in");

        body.dId = params.id;
      }
      res = await Models.checkin.getManyCheckin(body);
      setState({
        totalItems: res?.count,
        data: res?.data,
      });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  const getAllCheckin = async () => {
    let data: any = [];
    let data2: any = [];
    try {
      const res: any = await Models.checkin.getManyCheckin({});
      for (let checkin of res.data) {
        data.push({
          label:
            checkin?.vehicleId?.vhModel && checkin?.vehicleId?.vhNumber
              ? `${checkin?.vehicleId?.vhModel} - ${checkin?.vehicleId?.vhNumber}`
              : checkin?.vehicleId?.vhModel || checkin?.vehicleId?.vhNumber,
          value: checkin?.vehicleId?.vhNumber,
        });
        data2.push({
          label: checkin?.driverId?.dNumber,
          value: checkin?.driverId?._id,
        });
      }
      data = _.orderBy(data, ["label"]);
      data2 = _.orderBy(data2, ["label"]);

      data.unshift({
        label: "ALL VEHICLES",
        value: 0,
      });
      data2.unshift({
        label: "ALL DRIVERS",
        value: 0,
      });
      setState({
        checkinVehicleData: _.uniqBy(data, "label"),
        checkinDriverData: _.uniqBy(data2, "label"),
      });
    } catch (err: any) {
      console.log("err", err);
    }
  };
  const fetchClients = async () => {
    try {
      const res: any = await Models.organization.getManyOrganization({ clt: "ls" })
      const temp = res?.data?.map((item, index) => ({
        label: item?.fullName,
        value: item?.clientId?._id + "|" + item?.clientId?.userId
      }))
      setState({ clients: temp })
    } catch (err) {
      console.log("Fetch Clients Error : ", err)
    }
  }
  const fetchClientStats = async () => {
    try {
      const res: any = await Models.organization.clientStats({})
      
      setState({ clientStats: res?.data })
    } catch (err) {
      console.log("Fetch Clients Error : ", err)
    }
  }
  const fetchStores = async () => {
    try {
      setState({ storeFetchLoading: true })

      const res: any = await Models.store.getManyStore({ client: state.filterClient?.split("|")[0] })
      const temp = res?.data?.map((item, index) => ({
        label: item?.storeName,
        value: item?._id
      }))
      setState({ stores: temp })
    } catch (err) {
      console.log("Fetch Store Error : ", err)
    } finally {
      setState({ storeFetchLoading: false })
    }
  }
  useEffect(() => {
    if (state.filterClient) fetchStores()
  }, [state.filterClient])
  useEffect(() => {
    getAllCheckin();
    fetchClients();
    fetchClientStats()
  }, []);
  useEffect(() => {
    GetManyData();
  }, [
    state.search,
    state.currentPage,
    state.filterStatus,
    state.filterVehicle,
    state.filterDriver,
    state.filterDate,
    state.filterStore,

  ]);
  const handleView = (data) => {
    navigate(`/checkin/view/${data?._id}`);
  };

  const handlePageChange = (page: number) => {
    setState({ currentPage: page });
  };
  const handleExport = async () => {
    const res: any = await Models.checkin.exportCheckin();
    const compressedString = res.data;
    const compressedBuffer = Buffer.from(compressedString, "base64");
    const base64String = pako.ungzip(compressedBuffer, { to: "base64" });
    createExcelFile(base64String);
  };
  return (
    <div className="checkin_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="head h3">Checkin</div>
        </div>
        <div style={{ justifyContent: "flex-end" }} className="search_wrapper">
          <Search
            value={state.search}
            onChange={(search) => setState({ search, currentPage: 1, totalItems: 0 })}
            placeholder={"Search by Mobile, Full Name "}
          />

          <div className="button_group">
            <Filter
              onClick={() => setState({ toggleFilter: !state.toggleFilter })}
            />
            <Button
              value="Export"
              onClick={() => {
                handleExport();
              }}
            />
          </div>
        </div>
      </div>
      <div className="all_filter_container">
        {state.toggleFilter && (
          <div className="all_filter_dropdown_wrapper">
            <div className="container-fluid">
              <div className="row g-3">
                <div className="col-md-3 col-sm-6">
                  <SelectDropdown
                    notfound={"Not found"}
                    placeholder={"Status"}
                    data={status}
                    onChange={(filterStatus: any) =>
                      setState({
                        filterStatus: filterStatus.value,
                        currentPage: 1,
                        totalItems: 0,
                      })
                    }
                    value={state.filterStatus}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <SelectDropdown
                    notfound={"Not found"}
                    placeholder={"Model - vhNumber"}
                    data={state.checkinVehicleData}
                    onChange={(filterVehicle: any) =>
                      setState({
                        filterVehicle: filterVehicle.value,
                        currentPage: 1,
                        totalItems: 0,
                      })
                    }
                    value={state.filterVehicle}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <SelectDropdown
                    notfound={"Not found"}
                    placeholder={"Driver no."}
                    data={state.checkinDriverData}
                    onChange={(filterDriver: any) =>
                      setState({
                        filterDriver: filterDriver.value,
                        currentPage: 1,
                        totalItems: 0,
                      })
                    }
                    value={state.filterDriver}
                  />
                </div>
                <div className="col-md-3 col-sm-6">
                  <Calendar
                    placeholder="Select date range"
                    value={state.filterDate}
                    onChange={(e) => {
                      setState({
                        filterDate: e.value,
                        currentPage: 1,
                        totalItems: 0,
                      })
                    }}
                    style={{width : "100%"}}
                    selectionMode="range"
                    readOnlyInput />
                </div>
                <div className="col-md-3 col-sm-6">
                  <SelectDropdown
                    notfound={"Not found"}
                    placeholder={"Client"}
                    data={state.clients}
                    onChange={(filterClient: any) =>
                      setState({
                        filterClient: filterClient.value,
                      })
                    }
                    value={state.filterClient}
                  />
                </div>
                {/* {
                  state.filterClient ?
                    <div className="col-md-2 col-sm-6">

                      <Button
                        boxIcon="bxs-pie-chart-alt-2"
                        value="Client Summery"
                        onClick={() => setState({ clientSummeryVisible: true })}
                        style={{ cursor: "pointer" , width : "100%"}}
                      />
                    </div>
                    : null
                } */}
                <div className="col-md-3 col-sm-6">
                  {
                    state.storeFetchLoading ?
                      <Loader primary />
                      :
                      <SelectDropdown
                        notfound={"Not found"}
                        placeholder={"Store"}
                        data={state.stores}
                        onChange={(filterStore: any) =>
                          setState({
                            filterStore: filterStore.value,
                            currentPage: 1,
                            totalItems: 0,
                          })
                        }
                        value={state.filterStore}
                      />
                  }
                </div>
                {/* {
                  state.filterStore ?
                    <div className="col-md-2 col-sm-6">

                      <Button
                        boxIcon="bxs-pie-chart-alt-2"
                        value="Store Summery"
                        onClick={() => setState({ storeSummeryVisible: true })}
                        style={{ cursor: "pointer" , width : "100%" , backgroundColor : Colors.textdark}}
                      />
                    </div>
                    : null
                } */}
                <div className="col-md-2 col-sm-6">
                  <Button
                    boxIcon="bx-x-circle"
                    value="Clear all filters"
                    onClick={() => window.location.reload()} style={{ cursor: "pointer", backgroundColor: Colors.red,width :"100%" }} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
        <div className="container-fluid">
          <div className="row">
            {
              
              state?.clientStats?.map((item)=>(
                <div className="col-md-3">
                  <div className="c-stats-box" onClick={()=>setState({selectedClient : item ,clientSummeryVisible : true })}>
                    <table  width={"100%"} cellPadding={0}> 
                      <tr >
                        <td width={150} rowSpan={3}>
                          <div style={{fontWeight : 'bold'}} className="name">
                            {item?.clientName}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="assigned">
                            <div>Assigned</div>
                            <div style={{fontSize :20}} >{item?.assignedCount}</div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="active">
                            <div>Active</div>
                            <div style={{fontSize :20}}>{item?.actualCount}</div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      <div className="drivertable">
        <Table
          data={state.data}
          totalDocs={state.totalDocs}
          loading={state.loading}
          theads={thead}
          link=""
          loadMore={() => { }}
          actions={[
            {
              key: "CHECKIN_VIEW",
              icon: "view",
              onClick: handleView,
            },
          ]}
        />
      </div>
      <div className="driver_pagination">
        <Pagination
          totalItems={state.totalItems}
          itemsPerPage={10}
          currentPage={state.currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      <Dialog header="Client Summery" visible={state.clientSummeryVisible} style={{ width: '110vw' }} onHide={() => setState({ clientSummeryVisible: false })}>
        <ClientSummery cid={state?.selectedClient?.clientId} cuid={state?.selectedClient?.userId} />
      </Dialog>
      
    </div>
  );
}
