import instance from "utils/axios.utils";
import Functions from "utils/functions.utils";

const signedUrl = {
  getSignedUrl: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "signed";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
};
export default signedUrl;
