import React, { FC } from 'react'

export type RateSelectValueType = {value : string , title : string}
export interface RateSelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  data : RateSelectValueType[],
  value : string  | number,
}

const RateSelect:FC<RateSelectProps> = ({
  value = {title : "No Data" , value : ''},
  data = [],
  ...rest
}) => {
  return (
    <select name="" id="blocks" style={{flex  :0.5}} {...rest} >
      {
        data.map((item , index)=>(
          <option key={index} selected={item.value == value } value={item.value}>{item.title}</option>
        ))
      }
    </select>
  )
}

export default RateSelect