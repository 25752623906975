import { Dropdown, DropdownProps } from 'primereact/dropdown'
import React from 'react'

export interface DDropdownProps extends DropdownProps{

}

const DDropdown:React.FC<DDropdownProps> = ({
  style,
  ...rest
}) => {
  return (
    <Dropdown
      style={{ width: "100%",...style}}
      {...rest}
    />
  )
}

export default DDropdown