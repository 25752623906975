import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import React, { useEffect } from "react";
import "./circular_bar.component.scss";
import Assets from "imports/assets.import";

interface IProgressComponentProps {
  percentage?: any;
  color?: string;
  icon?: string;
  loading?: boolean;
  title?: string;
  count?: string;
  onClick?: any;
}

export default function CircularBar(props: IProgressComponentProps) {
  useEffect(() => {}, []);

  return (
    <div className="progress-container" onClick={props.onClick}>
      <div className="progress-bar">
        <CircularProgressbar
          value={props.percentage}
          text={`${Math.floor(props.percentage)}%`}
          styles={buildStyles({
            textColor: "black",
            pathColor: props.color,
            // trailColor: "gold",
          })}
        />
      </div>
      <div className="desc">
        <div className="title">{props.title}</div>
        <div className="count">{props.count}</div>
      </div>
      <div className="sidebar_icon"> &gt; </div>
    </div>
  );
}
