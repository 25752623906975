import React, { FC } from 'react'
import "./checkbox.ui.scss"
export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked : boolean;
  inline? : boolean;
  label : string;
  id? : string,
}

const Checkbox: FC<CheckboxProps> = ({
  label,
  inline,
  id = Date.now().toString(),
  ...rest
}) => {
  return (
    <div className={`checkbox-group ${inline ? "inline" : ""}`}>
      <input id={id} type="checkbox" {...rest}  /> 
      <label htmlFor={id}>{label}</label>
    </div>

  )
}

export default Checkbox