import Functions from "utils/functions.utils";
import instance from "../utils/axios.utils";
import { setManyCenter, setCenter } from "utils/redux.utils";

const Center = {
  createCenter: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "center";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  editCenter: (body) => {
    let promise = new Promise((resolve, reject) => {
      let url = "center";
      instance()
        .post(url, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  getCenter: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "center";
        const response = await instance().get(url, { params: id });
        setCenter(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getManyCenter: (query) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "center";
        const response = await instance().get(url, { params: query });
        setManyCenter(response?.data);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  getCenterMembers: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "center/members";
        const response = await instance().get(url, { params: id });
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
  deleteCenter: (payload) => {
    let promise = new Promise((resolve, reject) => {
      let url = "center/sa/drop";
      instance()
        .delete(url, { data: payload })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(Functions.modelError(error));
        });
    });
    return promise;
  },
  exportCenter: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const url = "center?export=1";
        const response = await instance().get(url);
        resolve(response.data);
      } catch (error) {
        reject(Functions.modelError(error));
      }
    });
  },
};

export default Center;
