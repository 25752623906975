import { Form, Formik } from "formik";
import * as yup from "yup";
import "./signup.screen.scss";
import InputField from "common_components/ui/field/field.ui";
import Button from "common_components/ui/button/button.ui";
import { Link, useNavigate } from "react-router-dom";
import { Models } from "utils/imports.utils";
import { toastifyError } from "utils/functions.utils";
import encrypt from "../../utils/encrypt";
import { toast } from "react-toastify";
import { emailRegExp, passwordRegex } from "utils/validation.utils";

export default function Signup() {
  const navigate = useNavigate();
  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Please enter email")
      .matches(emailRegExp, "Please enter valid email"),
    password: yup
      .string()
      .required("Please enter password")
      .matches(passwordRegex, "Please choose a different password."),
  });
  const handleSignup = async (values) => {
    try {
      const body = {
        ...values,
        role: "ADMIN",
      };
      const jsonString = JSON.stringify(body);
      const data = await encrypt(jsonString);
      let register: any = [];
      try {
        register = await Models.auth.register({ data: data });
        toast("User registered! Try logging in.");
        navigate("/");
      } catch (error) {
        toastifyError("There was some error while signing up!");
      }
    } catch (err) {
      toastifyError(err);
    }
  };
  return (
    <div className="signup_screen">
      <div className="signup_screen_wrapper">
        <div className="signup_screen_container">
          <h1
            style={{
              paddingBottom: "2%",
              textAlign: "center",
            }}
          >
            Sign Up
          </h1>
          <Link to="/">
            <div className="signup_links_container">
              <div className="signup_link">
                Already have an account?<u>Log in.</u>
              </div>
            </div>
          </Link>
          <div className="signup_input_wrapper">
            <Formik
              onSubmit={handleSignup}
              validationSchema={validationSchema}
              initialValues={{
                email: "",
                password: "",
              }}
            >
              <Form>
                <div className="signup_input_container">
                  <InputField
                    name="email"
                    type="text"
                    placeholder="Email ID"
                    optional={true}
                  />
                </div>
                <div className="signup_input_container">
                  <InputField
                    name="password"
                    type="password"
                    placeholder="Password"
                    optional={true}
                  />
                </div>

                <div className="submit_button_container">
                  <Button width="100%" value="SignUp" />
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
