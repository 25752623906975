import Assets from "imports/assets.import";
import Models from "imports/models.import";
import React, { FC, useEffect, useState } from "react";
import { toastifyError } from "utils/functions.utils";
import CustomModal from "../modal/modal.component";
import Divider from "../divider/divider.ui";

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fileKey: string;
  label?: string;
  accessType?: 'private' | 'public';
  onChangeFile: (file: any, responseData: any) => void;
  file_bucket_url?: string;
  optional?: boolean;
  fileType?: "file" | "image"
  value?: string;
}

const FileInput: FC<FileInputProps> = ({
  fileKey = "",
  label = "",
  accessType,
  fileType = "image",
  className,
  onChangeFile = (file: any, responseData: any) => { },
  file_bucket_url = "",
  optional = false,
  style,
  value,
  ...rest
}) => {

  const [tempFile, setTempFile] = useState<{
    url: string | null;
    name: string | null;
  }>({
    url: null,
    name: null,
  });
  const [openView, setOpenView] = useState(false)
  const [viewUrl, setViewUrl] = useState("")
  const handleFileUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      let response: any = [];

      if (accessType === "private") {
        response = await fetch(
          `${process.env.REACT_APP_NODE}/api/v2${file_bucket_url}`,
          {
            method: "POST",
            body: formData,
          }
        );
      } else {
        response = await fetch(
          `${process.env.REACT_APP_NODE}/api/v2${file_bucket_url}`,
          {
            method: "POST",
            body: formData,
          }
        );
      }

      if (response.ok) {
        const responseData = await response.json();
        console.log("Upload successful:", responseData);
        setTempFile({
          url: URL.createObjectURL(file),
          name: file.name,
        });
        if (accessType === "private") {
          onChangeFile(responseData.data.key, fileKey);
        } else {
          onChangeFile(responseData.data.location, fileKey);
        }
      } else {
        toastifyError("File upload failed: Image size too big");
      }
    } catch (error) {
      toastifyError("Error uploading file: Image size too big");
    }
  };
  const convertToPublicUrl = async (url) => {
    try {
      const body: any = {
        bucketName: process.env.REACT_APP_SIGNED_BUCKET_KEY,
        file: url,
        expiresIn: 3000,
      };
      const response: any = await Models.signedUrl.getSignedUrl(body);

      // Check if the response has a valid link
      if (response?.data?.link) {
        setViewUrl(response?.data?.link)
      } else {
        console.error("Invalid public URL in the response:", response);
        // Handle the error or log it as needed
      }
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  useEffect(() => {
    if (value) {
      if (accessType === 'private') {
        convertToPublicUrl(value)
      } else {
        setViewUrl((prev) => (value))
      }
    }
  }, [value])

  return (
    <>
      <div style={{ width: "100%" }} className="img-title">
        {
          label ?
            <div className="file-label">
              {label}
              {!optional && (
                <span className="required" style={{ color: "red" }}>
                  *
                </span>
              )}
            </div> : null
        }

        <div  style={style} className={"file-input-container " + className ?? ""}>
          <label htmlFor={fileKey} className="d-flex align-items-center">
            {
              fileType === 'image' ?
                <div className={`file-show ${tempFile.url ? "show" : ""}`}>
                  <img src={tempFile.url ?? Assets.file_placeholder} alt="" />
                </div> : <i className='bx bx-sm bx-cloud-upload mx-2'></i>
            }
            {
              fileType === 'image' ? <>
                <div className="choose-btn">Choose file</div>
                <div className="file-placeholder">
                  {tempFile.name ?? "Only PDF, JPG, PNG file supported"}
                </div>
              </> : <div className="file-placeholder">
                {tempFile.name ?? (value ? "File uploaded click to change" : "Upload file")}
              </div>
            }
          </label>
          {
            fileType === 'image' && (tempFile?.url || viewUrl) &&
            <div className="file-viewer" onClick={() => setOpenView(true)}>
              <img src={tempFile?.url ?? viewUrl ?? ""} alt="file view" />
            </div>
          }
        </div>
      </div>
      <div className="img-box">
        <input
          type="file"
          style={{ display: "none" }}
          id={fileKey}
          accept={fileType === 'image' ? "image/png, image/jpg, image/jpeg" : '*'}
          onChange={(event) => {
            if (
              event.currentTarget.files &&
              event.currentTarget.files.length > 0
            ) {
              setTempFile({
                url: URL.createObjectURL(event?.currentTarget?.files[0]),
                name: event?.currentTarget?.files[0]?.name,
              });
              handleFileUpload(event?.currentTarget?.files[0]);
            }
          }}
          {...rest}
        />
      </div>

      <CustomModal open={openView} onClose={() => setOpenView(false)}>
        {viewUrl ? (
          <div className="view_file_container">
            {
              <img
                style={{ height: 600, width: 800, objectFit: "contain" }}
                src={viewUrl}
                alt="upload image"
                className="view_file"
              />
            }
          </div>
        ) : (
          <div className="view_file_error_container">
            <div className="view_file_error">No file chosen</div>
          </div>
        )}
      </CustomModal>
    </>
  );
};

export default FileInput;
