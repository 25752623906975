import React, { FC, Fragment, useEffect, useState } from 'react'
import "./rate_cards.component.scss"
import Checkbox from 'common_components/ui/checkbox/checkbox.ui'
import Divider from 'common_components/ui/divider/divider.ui'
import RateSelect from './rate_select_input'
import Button from 'common_components/ui/button/button.ui'
import { toastifyError } from "utils/functions.utils";
import rateCardModel from 'models/rate_card.model'
const RATE_CARD_ENUMS = {
  rateCardType: {
    W2: "2W", W3: "3W", W4: "4W"
  },
  points: {
    W2I: "2WI",
    W2E: "2WE",
    W3I: "3WI",
    W3E: "3WE",
    W4I: "4WI",
    W4E: "4WE",
  },
  periodUnits: {
    DAILY: "DAILY",
    MONTHLY: "MONTHLY",
    WEEKLY: "WEEKLY",
  },
  targetTypes: {
    EXTERNAL: "EXTERNAL",
    INTERNAL: "EXTERNAL",
  }
}


export interface RateCardsProps {
  storeId: string,
  updateMode? : boolean;
  viewMode? : boolean;
}
const RateCards: FC<RateCardsProps> = ({ storeId = "vchvhbvhbvh" , updateMode = false,viewMode = false}) => {
  const [rateData, setState] = useState({
    twoWheeler: true,
    threeWheeler: false,
    fourWheeler: false,
    pointsData  :{

    }
  })
  const setData = (values: Partial<typeof rateData>) => {
    setState(prev => ({ ...prev, ...values }))
  }
  const filterData = (point:string , data:any)=>{
    let uid = Date.now()
    let tempData = {}
    let b_data = {}
    let s_data = {}
    let k_data = {}
    if(point == "2WI" || point == "2WE"){
      data?.rateCardClientCommercials2W?.blocksData?.map((item:any,index:number)=>{
        if(Object.keys(item).length !== 0){
          b_data = {
            ...b_data,
            [uid + index]: item
          }
        }
      })
      data?.rateCardClientCommercials2W?.kmRangeSet?.map((item:any,index:number)=>{
        if(Object.keys(item).length !== 0){
          let t_item = {}
          // demo = {
          //   '5to10' : 33,
          // }
          Object.entries(item).map(([key,value]:any,index)=>{
            let [startKey, endKey] = key?.split("to");
            t_item = {startKey, endKey , value}
          })
          k_data = {
            ...k_data,
            [uid + index]: t_item
          }
        }
      })
      tempData = {
        ...data?.rateCardClientCommercials2W,
        blocks : data?.rateCardClientCommercials2W?.blocksData?.length,
        blocksData: b_data,
        kmRangeSet: k_data
      }
    }else{
      data?.rateCardClientCommercialsGeneral?.blocksData?.map((item:any,index:number)=>{
        if(Object.keys(item).length !== 0){
          b_data = {
            ...b_data,
            [uid + index]: item
          }
        }
      })
      data?.rateCardClientCommercialsGeneral?.shiftsData?.map((item:any,index:number)=>{
        if(Object.keys(item).length !== 0){
          s_data = {
            ...b_data,
            [uid + index]: item
          }
        }
      })
      data?.rateCardClientCommercialsGeneral?.kmRangeSet?.map((item:any,index:number)=>{
        if(Object.keys(item).length !== 0){
          let t_item = {}
          // demo = {
          //   '5to10' : 33,
          // }
          Object.entries(item).map(([key,value]:any,index)=>{
            let [startKey, endKey] = key?.split("to");
            t_item = {startKey, endKey , value}
          })
          k_data = {
            ...k_data,
            [uid + index]: t_item
          }
        }
      })
      tempData = {
        ...data?.rateCardClientCommercialsGeneral,
        blocks : data?.rateCardClientCommercialsGeneral?.blocksData?.length,
        shift : data?.rateCardClientCommercialsGeneral?.shiftsData?.length,
        blocksData: b_data,
        shiftsData: s_data,
        kmRangeSet: k_data
      }
    }
    return tempData
  }
  const fetchCardData = async(sid:string)=>{
    try{
      let dataX = {}
      const res:any = await rateCardModel.fetchDetails(sid);
      (res?.data as any[]).map((item,index)=>{
        dataX[item?.point] = filterData(item?.point,item)
      })
      setData({pointsData : dataX})
      setData({
        twoWheeler : ('2WI' in dataX || '2WE' in dataX ),
        threeWheeler : ('3WI' in dataX || '3WE' in dataX ),
        fourWheeler : ('4WI' in dataX || '4WE' in dataX ),
      })
      console.log("DATA",dataX);
    }catch(error){
      console.log("ERR",error);
    }
    
  }
  useEffect(()=>{
    if(updateMode ||viewMode){
      fetchCardData(storeId);
    }
  },[])
  return (
    <div className='rate-cards-wrapper' >
      <h2>Rate Cards</h2>
      <div className="checkbox-inline-wrapper">
        <Checkbox
          label="2 Wheeler"
          checked={rateData.twoWheeler}
          onChange={(e) => { setData({ twoWheeler: e.target.checked }) }}
          inline
          id='2w'
        />
        <Checkbox
          label="3 Wheeler"
          checked={rateData.threeWheeler}
          onChange={(e) => { setData({ threeWheeler: e.target.checked }) }}
          inline
          id='3w'
        />
        <Checkbox
          label="4 Wheeler"
          checked={rateData.fourWheeler}
          onChange={(e) => { setData({ fourWheeler: e.target.checked }) }}
          inline
          id='4w'
        />
      </div>
      <br />
      {rateData.twoWheeler ?
        <div>
          <h3 className='rate-card-caption'  >2 Wheelers</h3>
          <div className="rate-card-boxes">
            <div className="box-item">
              <h5 className='text-center' >Client Commercial</h5>
              <Divider style={{ margin: "10px 0" }} />
              <RateForms
                viewMode={viewMode}
                targetType={RATE_CARD_ENUMS.targetTypes.INTERNAL}
                storeId={storeId}
                point={RATE_CARD_ENUMS.points.W2I} 
                rateCardType={RATE_CARD_ENUMS.rateCardType.W2}
                initialData={rateData.pointsData["2WI"]}
                />
            </div>
            <div className="rate-divider"></div>
            <div className="box-item">
              <h5 className='text-center' >Driver Commercial</h5>
              <Divider style={{ margin: "10px 0" }} />
              <RateForms
                viewMode={viewMode}
                targetType={RATE_CARD_ENUMS.targetTypes.EXTERNAL}
                storeId={storeId}
                point={RATE_CARD_ENUMS.points.W2E}
                rateCardType={RATE_CARD_ENUMS.rateCardType.W2}
                initialData={rateData.pointsData["2WE"]}
              />
            </div>
          </div>
        </div> : null
      }
      <br />
      {rateData.threeWheeler ?
        <div>
          <h3 className='rate-card-caption'  >3 Wheelers</h3>
          <div className="rate-card-boxes">
            <div className="box-item">
              <h5 className='text-center' >Client Commercial</h5>
              <Divider style={{ margin: "10px 0" }} />
              <RateForms
                viewMode={viewMode}
                targetType={RATE_CARD_ENUMS.targetTypes.INTERNAL}
                storeId={storeId}
                point={RATE_CARD_ENUMS.points.W3I} 
                rateCardType={RATE_CARD_ENUMS.rateCardType.W3}
                initialData={rateData.pointsData["3WI"]}
                />
            </div>
            <div className="rate-divider"></div>
            <div className="box-item">
              <h5 className='text-center' >Driver Commercial</h5>
              <Divider style={{ margin: "10px 0" }} />
              <RateForms
                viewMode={viewMode}
                targetType={RATE_CARD_ENUMS.targetTypes.EXTERNAL}
                storeId={storeId}
                point={RATE_CARD_ENUMS.points.W3E} 
                rateCardType={RATE_CARD_ENUMS.rateCardType.W3}
                initialData={rateData.pointsData["3WE"]}
                />
            </div>
          </div>
        </div> : null
      }
      <br />
      {rateData.fourWheeler ?
        <div>
          <h3 className='rate-card-caption'  >4 Wheelers</h3>
          <div className="rate-card-boxes">
            <div className="box-item">
              <h5 className='text-center' >Client Commercial</h5>
              <Divider style={{ margin: "10px 0" }} />
              <RateForms
                viewMode={viewMode}
                targetType={RATE_CARD_ENUMS.targetTypes.INTERNAL}
                storeId={storeId}
                point={RATE_CARD_ENUMS.points.W4I} 
                rateCardType={RATE_CARD_ENUMS.rateCardType.W4}
                initialData={rateData.pointsData["4WI"]}
                />
            </div>
            <div className="rate-divider"></div>
            <div className="box-item">
              <h5 className='text-center' >Driver Commercial</h5>
              <Divider style={{ margin: "10px 0" }} />
              <RateForms
                viewMode={viewMode}
                targetType={RATE_CARD_ENUMS.targetTypes.EXTERNAL}
                storeId={storeId}
                point={RATE_CARD_ENUMS.points.W4E} 
                rateCardType={RATE_CARD_ENUMS.rateCardType.W4}
                initialData={rateData.pointsData["4WE"]}
                />
            </div>
          </div>
        </div> : null
      }
      <br />
      <br />
      <br />
    </div>
  )
}

export interface RateFormsProps {
  rateCardType : string;
  point: string,
  storeId: string;
  targetType: string;
  initialData? : any;
  viewMode? : boolean;
}

const RateForms: FC<RateFormsProps> = ({
  rateCardType ,
  storeId,
  point,
  targetType = RATE_CARD_ENUMS.targetTypes.INTERNAL,
  initialData,
  viewMode
}) => {
  const isGeneral = point !== RATE_CARD_ENUMS.points.W2E && point !== RATE_CARD_ENUMS.points.W2I
  const [isUploaded ,setIsUploaded]= useState(false)
  const [formData, setState] = useState({
    blocks: 0,
    shift: 0,
    costPerOrder: "",
    minimumGuaranteeOrder: "",
    aboveMinimumGuaranteeOrder: "",
    baseKms: "",
    baseKmsPeriod: "",
    kmCap: "",
    minimumLogInHours: "",
    AdditionalLoginHoursCost: "",
    additionalPerKms: "",
    fixedCostRate: "",
    fixedCostRatePeriod: "",
    kmRangeSet: {},
    blocksData: {},
    shiftsData: {},
  })
  const [loading, setLoading] = useState(false)
  const setFormData = (values: Partial<typeof formData>) => {
    setState(prev => ({ ...prev, ...values }))
  }
  const increaseKmRange = () => {
    const uid = Date.now()
    const newEntry = {
      ...formData.kmRangeSet,
      [uid]: {
        startKey: "",
        endKey: "",
        value: ""
      }
    }
    setFormData({ kmRangeSet: newEntry })
  }
  const deleteKmRange = (key: string) => {

    const newEntry = {
      ...formData.kmRangeSet,
    }
    console.log("key", key);
    if (key !== undefined) {
      delete newEntry[key]
    }
    console.log("data", newEntry);
    setFormData({ kmRangeSet: newEntry })
  }
  const updateKmRange = (type: "start" | 'end' | 'value', key: string, value: string) => {
    let entries = formData.kmRangeSet
    if (type == "start") {
      entries[key] = {
        ...entries[key],
        startKey: value
      }
    } else if (type == 'end') {
      entries[key] = {
        ...entries[key],
        endKey: value
      }
    } else {
      entries[key] = {
        ...entries[key],
        value
      }
    }
    setFormData({ kmRangeSet: entries })
  }
  const createBlockShift = (type: "block" | "shift", value: string) => {
    let temp = {}
    let uid = Date.now()

    if (type == "block") {
      for (var i = 1; i <= parseInt(value); i++) {
        temp = {
          ...temp,
          [uid + i]: {
            "slotOrBlockHours": "",
            "perBlockPay": ""
          },
        }
      }
      setFormData({
        blocks: parseInt(value),
        blocksData: temp
      })
    } else {
      for (var i = 1; i <= parseInt(value); i++) {
        temp = {
          ...temp,
          [uid + i]: {
            "fixedCost": "",
            "fixedCostPeriod": "",
            "perDayPrice": ""
          }
        }
      }
      setFormData({
        shift: parseInt(value),
        shiftsData: temp
      })
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({
      [e.target.name]: e.target.value
    })
  }
  const handleChangeBlocksOrShifts = (type: "blocks" | "shift", key: string, event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    if (type == 'blocks') {
      let entries = formData.blocksData;
      entries[key] = {
        ...entries[key],
        [event.target.name]: event.target.value
      }
      setFormData({ blocksData: entries })
    } else {
      let entriesX = formData.shiftsData;
      entriesX[key] = {
        ...entriesX[key],
        [event.target.name]: event.target.value
      }
      setFormData({ blocksData: entriesX })
    }
  }
  const createFormData = () => {
    const f_data = formData
    const filterBlockData = Object.values(f_data.blocksData)
    const filterShiftData = Object.values(f_data.shiftsData)
    const filterKmRangeData = Object.values(f_data.kmRangeSet).map((item: any) => ({
      [item.startKey + "to" + item.endKey]: item.value
    }))
    f_data.blocksData = filterBlockData;
    if (isGeneral) f_data.shiftsData = filterShiftData;
    f_data.kmRangeSet = filterKmRangeData;
    const keyName = isGeneral ? "rateCardClientCommercialsGeneral" : "rateCardClientCommercials2W";
    const data = {
      storeId,
      targetType,
      point,
      [keyName]: {
        ...f_data,
      }
    }
    return data
  }
  const onSubmit = async () => {
    try {
      setLoading(true)
      const data = createFormData()
      console.log("data", data);
      const response = await rateCardModel.uploadDetails(rateCardType,data)
      setIsUploaded(true)
    } catch (error) {
      console.log("Failed to upload", error);
      toastifyError("Failed to upload details.");
    } finally {
      setLoading(false)
    }
  }
  useEffect(()=>{
    if(initialData){
      console.log("Initial Data",rateCardType,{...formData,...initialData});
      setState({...formData,...initialData})
      setIsUploaded(true);
    }
  },[initialData])
  return (
    <>
      <div style={{ flex: 1 }} className={`container-fluid ${viewMode ?  "view-mode" : ""}`} >
        {
          loading ?
            <div className="rate-card-overlay"></div>
            : null
        }
        <div className="d-flex justify-content-end align-items-center">
          <label className='px-2' style={{ flex: 1, textAlign: 'right' }} htmlFor="blocks">Blocks</label>
          <RateSelect
            disabled={viewMode}
            data={[
              { title: "Choose blocks", value: "0" },
              { title: "1", value: "1" },
              { title: "2", value: "2" },
              { title: "3", value: "3" },
            ]}
            value={formData.blocks}
            onChange={(e) => createBlockShift('block', e.target.value)}
          />
        </div>
        {
          isGeneral
            ?
            <div className="d-flex justify-content-end align-items-center">
              <label className='px-2' style={{ flex: 1, textAlign: 'right' }} htmlFor="shift">Shift</label>
              <RateSelect
                disabled={viewMode}
                data={[
                  { title: "Choose shift", value: "0" },
                  { title: "1", value: "1" },
                  { title: "2", value: "2" },
                  { title: "3", value: "3" },
                ]}
                value={formData.shift}
                onChange={(e) => createBlockShift('shift', e.target.value)}
              />
            </div>
            : null
        }
        <div className="row align-items-center">
          <div className="col-6">
            <label htmlFor="">CPO (Cost per orders) Rs.</label>
          </div>
          <div className="col-6 d-flex">
            <input disabled={viewMode} type="number" onChange={handleChange} name='costPerOrder' value={formData.costPerOrder} />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-6">
            <label htmlFor="">MGO (Min. Guarantee Orders) NO.</label>
          </div>
          <div className="col-6 d-flex">
            <input disabled={viewMode} type="number" onChange={handleChange} name='minimumGuaranteeOrder' value={formData.minimumGuaranteeOrder} />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-6">
            <label htmlFor="">Above MGO Rs.</label>
          </div>
          <div className="col-6 d-flex">
            <input disabled={viewMode} type="number" onChange={handleChange} name='aboveMinimumGuaranteeOrder' value={formData.aboveMinimumGuaranteeOrder} />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-6">
            <label htmlFor="">Base Kms Rs.</label>
          </div>
          <div className="col-6 d-flex">
            <input disabled={viewMode} type="number" onChange={handleChange} name='baseKms' value={formData.baseKms} />
            <RateSelect
              disabled={viewMode}
              name="baseKmsPeriod"
              data={[
                { title: "Choose unit", value: "" },
                { title: RATE_CARD_ENUMS.periodUnits.DAILY, value: RATE_CARD_ENUMS.periodUnits.DAILY },
                { title: RATE_CARD_ENUMS.periodUnits.WEEKLY, value: RATE_CARD_ENUMS.periodUnits.WEEKLY },
                { title: RATE_CARD_ENUMS.periodUnits.MONTHLY, value: RATE_CARD_ENUMS.periodUnits.MONTHLY },
              ]}
              value={formData.baseKmsPeriod}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-6">
            <label htmlFor="">Additional Kms</label>
          </div>
          <div className="col-6 d-flex align-items-center">
            <input disabled={viewMode} type="number" onChange={handleChange} name='additionalPerKms' value={formData.additionalPerKms} />
            <label htmlFor="">&nbsp; KM Cap (Rs.) &nbsp;</label>
            <input disabled={viewMode} type="number" onChange={handleChange} name='kmCap' value={formData.kmCap} style={{ flex: 0.2 }} />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12">
            <label htmlFor=""><strong>Km range &nbsp;</strong></label>
            <button onClick={increaseKmRange} className='icon-btn btn-primary' >
              <i className='bx bx-plus'></i>
            </button>
          </div>
          {
            Object.entries(formData.kmRangeSet).map(([key, value]:any, index) => (
              // @ts-ignore
              <Fragment key={index}>
                <div className="col-6 ">
                  <div className="d-flex align-items-center">
                    <input disabled={viewMode} value={formData.kmRangeSet[key].startKey} onChange={(e) => updateKmRange('start', key, e.target.value)} type="number" style={{ width: 40 }} />
                    <label className='px-3' htmlFor="">To</label>
                    <input disabled={viewMode} value={formData.kmRangeSet[key].endKey} onChange={(e) => updateKmRange('end', key, e.target.value)} type="number" style={{ width: 40 }} />
                  </div>
                </div>
                <div className="col-6 d-flex align-items-center">
                  <input disabled={viewMode} value={formData.kmRangeSet[key].value} onChange={(e) => updateKmRange('value', key, e.target.value)} type="number" />
                  &nbsp;
                  <button onClick={() => deleteKmRange(key)} className='icon-btn btn-danger'><i className='bx bx-trash-alt' ></i></button>
                </div>
              </Fragment>
            ))
          }
        </div>
        <div className="row align-items-center">
          <div className="col-6">
            <label htmlFor="">Login Hours. (Min)</label>
          </div>
          <div className="col-6 d-flex">
            <input disabled={viewMode} type="number" onChange={handleChange} name='minimumLogInHours' value={formData.minimumLogInHours} />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-6">
            <label htmlFor="">Additional login hours Rs./ Hr</label>
          </div>
          <div className="col-6 d-flex">
            <input disabled={viewMode} type="number" onChange={handleChange} name='AdditionalLoginHoursCost' value={formData.AdditionalLoginHoursCost} />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-6">
            <label htmlFor="">Fixed cost Rs.</label>
          </div>
          <div className="col-6 d-flex">
            <input disabled={viewMode} type="number" onChange={handleChange} name='fixedCostRate' value={formData.fixedCostRate} />
            <RateSelect
              disabled={viewMode}
              name="fixedCostRatePeriod"
              data={[
                { title: "Choose unit", value: "" },
                { title: RATE_CARD_ENUMS.periodUnits.DAILY, value: RATE_CARD_ENUMS.periodUnits.DAILY },
                { title: RATE_CARD_ENUMS.periodUnits.WEEKLY, value: RATE_CARD_ENUMS.periodUnits.WEEKLY },
                { title: RATE_CARD_ENUMS.periodUnits.MONTHLY, value: RATE_CARD_ENUMS.periodUnits.MONTHLY },
              ]}
              value={formData.fixedCostRatePeriod}
              onChange={handleChange}
            />
          </div>
        </div>
        {
          Object.entries(formData?.blocksData)?.map(([key, value]:any, index) => (
            <div key={index} className="row align-items-center">
              <div className="col-12">
                <Divider style={{ margin: "10px 0" }} />
                <label htmlFor=""><strong>Block / Shift ({index + 1}) </strong></label>
              </div>
              <div className="col-6">
                <label htmlFor="">Shift / Block Hours</label>
              </div>
              <div className="col-6 d-flex">
                <input disabled={viewMode} type="number" onChange={(e) => handleChangeBlocksOrShifts("blocks", key, e)} name='slotOrBlockHours' value={value?.slotOrBlockHours} />
              </div>
              <div className="col-6">
                <label htmlFor="">Per block pay Rs.</label>
              </div>
              <div className="col-6 d-flex">
                <input disabled={viewMode} type="number" onChange={(e) => handleChangeBlocksOrShifts("blocks", key, e)} name='perBlockPay' value={value?.perBlockPay} />
              </div>
            </div>
          ))
        }
        {
          Object.entries(formData?.shiftsData)?.map(([key, value]:any, index) => (
            <div key={index} className="row align-items-center">
              <div className="col-12">
                <Divider style={{ margin: "10px 0" }} />
                <label htmlFor=""><strong>Shift ({index + 1}) </strong></label>
              </div>
              <div className="col-6">
                <label htmlFor="">Fixed cost</label>
              </div>
              <div className="col-6 d-flex">
                <input disabled={viewMode} type="number" onChange={(e) => handleChangeBlocksOrShifts("shift", key, e)} name='fixedCost' value={value?.fixedCost} />
                <RateSelect
                  disabled={viewMode}
                  name="fixedCostPeriod"
                  data={[
                    { title: "Choose unit", value: "" },
                    { title: RATE_CARD_ENUMS.periodUnits.DAILY, value: RATE_CARD_ENUMS.periodUnits.DAILY },
                    { title: RATE_CARD_ENUMS.periodUnits.WEEKLY, value: RATE_CARD_ENUMS.periodUnits.WEEKLY },
                    { title: RATE_CARD_ENUMS.periodUnits.MONTHLY, value: RATE_CARD_ENUMS.periodUnits.MONTHLY },
                  ]}
                  value={value?.fixedCostPeriod}
                  onChange={(e) => handleChangeBlocksOrShifts("shift", key, e)}
                />
              </div>
              <div className="col-6">
                <label htmlFor="">Per trip / day Rs.</label>
              </div>
              <div className="col-6 d-flex">
                <input disabled={viewMode} type="number" onChange={(e) => handleChangeBlocksOrShifts("shift", key, e)} name='perDayPrice' value={value?.perDayPrice} />
              </div>
            </div>
          ))
        }

      </div>
      { !viewMode ? 
        <Button loading={loading} loadingText='Uploading data please wait...' onClick={onSubmit} value={isUploaded ?  "Update":"Submit"} />
        :null
      }
    </>
  );
}
export default RateCards