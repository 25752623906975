import { Calendar , CalendarPropsSingle} from 'primereact/calendar'
import React, { FC } from 'react'
import ReactDatePicker,{ReactDatePickerProps} from 'react-datepicker';
export interface DDatePickerProps extends Omit<ReactDatePickerProps,'onChange'> {
  onChange : (value:string )=>void,
  type  : "future-date" | 'date' | 'previous-date'
}
const DDatePicker:FC<DDatePickerProps> = ({
  selected,
  onChange = ()=>{},
  type,
  ...rest
}) => {
  return (
    // <input 
    //   type="date" className={'d-input ' + className} 
    //   {...rest}
    // />
    <ReactDatePicker
      selected={selected ? new Date(selected) : null}
      placeholderText="Select a date"
      dateFormat="MMMM d, yyyy"
      className="date-picker w-100"
      // name={name}
      onChange={(date) => {
        const isoString = date ? date.toISOString() : "";
        onChange(isoString)
      }}
      maxDate={
        type=== 'date' ? new Date("2050/01/01") :(type === "future-date" ? new Date("2050/01/01") : new Date())
      }
      minDate={
        type=== 'date' ? new Date("1950/01/01") :(type === "future-date" ? new Date() : new Date("1950/01/01"))
      }
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      {...rest}
    />
  )
}

export default DDatePicker