import Assets from "imports/assets.import";
import { useLocation } from "react-router-dom";
import "./auth.screen.scss";
import ForgotPassword from "./forgot_password.screen";
import ResetPassword from "./reset_password.screen";
import Signup from "./signup.screen";
import Login from "./login.screen";
import ResetPasswordConfirmation from "./reset_password_confirm.screen";

export default function Auth() {
  const location = useLocation();
  return (
    <div className="main-wrapper">
      <div className="auth_screen">
        <div className="auth_screen_wrapper">
          <div className="auth_screen_left_container">
            {/* <div className="auth_screen_header">Welcome Back</div> */}
            <div className="app_name_container">
              <img
                src={Assets.fullfily_white_icon}
                style={{ objectFit: "contain" }}
                height={180}
                width={300}
              />
            </div>
            {/* <div className="">Fullfily</div> */}
          </div>
          <div className="auth_screen_right_container">
            {location.pathname === "/sign-up" && <Signup />}
            {location.pathname === "/" && <Login />}
            {location.pathname === "/sign-up/reset_password" && <ResetPassword />}
            {location.pathname === "/sign-up/forgot_password" && <ForgotPassword />}
            {location.pathname === "/sign-up/reset_password_confirm" && (
              <ResetPasswordConfirmation />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
