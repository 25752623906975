import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./view_organization.screen.scss";
import History from "components/history/history.component";
import View from "components/view/view.component";
import Button from "common_components/ui/button/button.ui";
import Assets from "imports/assets.import";
import { Models } from "utils/imports.utils";
import { useSetState, toastifyError } from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import CustomModal from "common_components/ui/modal/modal.component";
import DeletePopup from "components/delete_popup/delete_popup.component";
import Divider from "common_components/ui/divider/divider.ui";
import moment from "moment";

export default function ViewOrganization() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useSetState({
    data: {},
    deleteModal: false,
    allocatedDrivers: []
  });
  useEffect(() => {
    GetOrganization();
  }, []);

  const GetAllocatedDrivers = async (cid: string) => {
    try {
      const response: any = await Models.organization.getAllocatedDriver(cid);
      setState({ allocatedDrivers: response.data });
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const GetOrganization = async () => {
    try {
      const response: any = await Models.organization.getOrganization({
        id: id,
      });
      setState({ data: response.data[0] });
      GetAllocatedDrivers(response.data?.[0]?.userId?._id)
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };


  const inputFields: IAddValues[] = [
    { label: "Email", key: "userId.email", type: "string", isNested: true },
    { label: "Mobile", key: "userId.mobile", type: "string", isNested: true },
    {
      label: "Contract period start",
      key: "contractStartDate",
      type: "date",
    },
    {
      label: "Contract period end",
      key: "contractEndDate",
      type: "date",
    },
    {
      label: "Contact Period",
      key: "contractPeriod",
      type: "string",
    },
    {
      label: "Category",
      key: "category",
      type: "string",
    },
    {
      label: "Type",
      key: "type",
      type: "string",
    },
    {
      label: "State",
      key: "userId.address",
      secondaryKey: "state",
      type: "address",
    },
    {
      label: "City",
      key: "userId.address",
      secondaryKey: "city",
      type: "address",
    },
    {
      label: "Building Name",
      key: "userId.address",
      secondaryKey: "buildingName",
      type: "address",
    },
    {
      label: "Landmark",
      key: "userId.address",
      secondaryKey: "landMark",
      type: "address",
    },
    {
      label: "Full Address",
      key: "userId.address",
      secondaryKey: "fullAddress",
      type: "address",
    },
    {
      label: "Pincode",
      key: "userId.address",
      secondaryKey: "pinCode",
      type: "address",
    },
    // {
    //   label: "Is Aadhaar Verified",
    //   key: "userId.isAadhaarVerified",
    //   type: "boolean",
    //   isNested: true,
    // },
    {
      label: "Is Approved By Admin",
      key: "userId.isApprovedByAdmin",
      type: "boolean",
      isNested: true,
    },
    {
      label: "Is Default Password Updated",
      key: "userId.isDefaultPasswordUpdated",
      type: "boolean",
      isNested: true,
    },
    {
      label: "Is Pan Verified",
      key: "userId.isPanVerified",
      type: "boolean",
      isNested: true,
    },
    { label: "GST doc", key: "gstDoc", type: "passbook" },
    { label: "Agreement doc", key: "agreementDoc", type: "passbook" },
    { label: "Cancelled cheque", key: "canceledCheque", type: "passbook" },
    { label: "PAN Document", key: "userId.panDocument", type: "passbook" },
  ];
  return (
    <div className="view_organization_container">
      <div className="view_organization_wrapper">
        <History name={state?.data?.userId?.fullName} path="view" />
        <div className="view_organization_body_container">
          <div className="view_container container-fluid p-5 ">
            <h3>Allocated Drivers ({state?.allocatedDrivers?.length ?? 0})</h3>
            <table width={"100%"} cellPadding={5} >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  state?.allocatedDrivers?.map((item, index) => (
                    <tr key={index} >
                      <td>{index + 1}</td>
                      <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                      <td>{item?.driverId?.fullName}</td>
                      <td>{item?.driverId?.mobile}</td>
                      <td className="text-center">
                        {
                          item?.driverId?._id ?
                            <Button
                              onClick={() => navigate(`/driver/view/${item?.driverId?._id}`)}
                              style={{ padding: "5px 10px" }} value="View driver" />
                            : "NA"
                        }
                      </td>
                    </tr>
                  ))
                }
                <tr></tr>
              </tbody>
            </table>
          </div>
          <br />
          <View
            actions={[
              {
                key: "CLIENT_STORE",
                link: `/client/${id}/store`,
                icon: "store",
              },
              { key: "CLIENT_EDIT", link: `/client/edit/${id}`, icon: "edit" },
            ]}
            data={state.data}
            values={inputFields}
            head={<ViewHeader />}
            // buttons={<ViewButton />}
            hasFiles
          />
        </div>

      </div>
    </div>
  );

  function ViewHeader() {
    return (
      <div className="view_head_left_container">
        <div className="view_head_image_conatiner">
          <img
            className="view_head_image"
            src={state?.data?.userId?.userImage || Assets.testPic}
            alt="head_image"
          />
        </div>
        <div className="view_head_title_container">
          <div className="view_head_title_wrapper">
            <div className="view_head_title">
              {state?.data?.userId?.fullName}
            </div>
            <div className="view_head_sub_title h5">{`Client - ${state?.data?.userId?.clientId || ""
              }`}</div>
          </div>
        </div>
      </div>
    );
  }
}
