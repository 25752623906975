import { useNavigate } from "react-router-dom";
import {
  useSetState,
  toastifyError,
  toastify,
  createExcelFile,
} from "utils/functions.utils";
import "./driver.screen.scss";
import Search from "common_components/ui/search/search.ui";
import Button from "common_components/ui/button/button.ui";
import Table from "components/table/table.component";
import Pagination from "components/pagination/pagination.component";
import Models from "imports/models.import";
import { useEffect } from "react";
import _ from "lodash";
import CustomModal from "common_components/ui/modal/modal.component";
import TextareaComponent from "common_components/ui/text_area/textarea.component";
import pako from "pako";
import { Buffer } from "buffer";
import Filter from "common_components/ui/filter/filter.ui";
import DateRangePicker from "common_components/ui/date_range_picker/date_range.component";
import Colors from "imports/color.import";
import SelectDropdown from "common_components/ui/select_dropdown/select_dropdown.component";
import DeletePopup from "components/delete_popup/delete_popup.component";
import AccessControlWrapper from "common_components/access_control.wrapper.hoc";

export default function Driver() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const [state, setState] = useSetState({
    search: "",
    data: [],
    toggleFilter: false,
    currentPage: 1,
    totalItems: 0,
    activeTab: "",
    selectedFilter: 0,
    approvalModal: false,
    rejectModal: false,
    deleteModal: false,
    archiveModal: false,
    terminateModal: false,
    resignModal: false,
    unarchiveModal: false,
    comment: "",
    resignComment: "",
    terminationComment: "",
    centerData: [],
    filterDriver: 2,
    centerId: "",
    isArchived: 0,
  });
  const thead = [
    { head: "Driver name", key: "fullName" },
    { head: "Date of join", key: "createdAt", type: "date-only" },
    {
      head: "Dnumber",
      key: "driverId.dNumber",
      type: "refresh",
      isNested: true,
    },
    { head: "Mobile", key: "mobile", type: "string" },
    {
      head: `Date of ${state.selectedFilter}`,
      key: "createdAt",
      type: "date",
    },
  ];

  const statusHeading = [
    { head: "Name", key: "fullName" },
    { head: "Date of join", key: "createdAt", type: "date-only" },
    {
      head: "Driver ID",
      key: "driverId.dNumber",
      type: "refresh",
      isNested: true,
    },
    // { head: "Driver name", key: "fullName" },

    { head: "Mobile", key: "mobile", type: "string" },
    { head: "City", key: "address[0].city", type: "string", isNested: true },
    // {
    //   head: "Allocation Status",
    //   key: "driverId.vehicleAllocationStatus",
    //   type: "allocation",
    //   isNested: true,
    // },
    {
      head: "Client Allocation Status",
      key: "driverId.clientAllocationStatus",
      type: "allocation",
      isNested: true,
    },
  ];
  const status = [
    { label: "Allotted", value: 1 },
    { label: "Non Allotted", value: 0 },
    { label: "ALL", value: 2 },
  ];
  const GetManyData = async () => {
    console.log(state.filterDriver);

    try {
      let res: any;
      const body: any = {
        page: state.currentPage,
        pageSize: 10,
      };
      if (state.search.length > 0) {
        body.search = state.search;
      
      }
      if (
        state.selectedFilter.length > 0 &&
        state.selectedFilter !== "ARCHIVED"
      ) {
        body.status = state.selectedFilter;
      }
      if (state.filterDriver !== 2) {
        body.vhAllocation = state.filterDriver;
      }
      if (state.isArchived === 1) {
        body.isArchived = state.isArchived;
      }
      res = await Models.driver.getManyDriver(body);
      setState({
        totalItems: res?.count,
        data: res?.data,
      });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  const getAllCenters = async () => {
    let data: any = [];
    try {
      const res: any = await Models.center.getManyCenter({});
      for (let center of res.data) {
        data.push({
          label:
            center?.controlCenterName && center?.centerId
              ? `${center.controlCenterName} - ${center.centerId}`
              : center.controlCenterName || center.centerId,
          value: center._id,
        });
      }
      data = _.orderBy(data, ["label"]);
      data.unshift({
        label: "ALL CENTERS",
        value: 0,
      });
      setState({ centerData: _.uniqBy(data, "label") });
    } catch (err: any) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    getAllCenters();
  }, []);
  useEffect(() => {
    GetManyData();
  }, [
    state.search,
    state.currentPage,
    state.activeTab,
    state.selectedFilter,
    state.dateRange,
    state.filterDriver,
    state.isArchived,
  ]);

  const handlePageChange = (page: number) => {
    setState({ currentPage: page });
  };
  const handleView = (data) => {
    navigate(`/driver/view/${data?._id}`);
  };
  const handleArchive = (data) => {
    setState({ id: data._id, archiveModal: true });
  };
  const handleTerminate = (data) => {
    setState({ id: data._id, terminateModal: true });
  };
  const handleResign = (data) => {
    setState({ id: data._id, resignModal: true });
  };
  const handleUnarchive = (data) => {
    setState({ id: data._id, unarchiveModal: true });
  };
  const handleFilter = (status) => {
    if (status !== 0) setState({ filterDriver: "" });
    setState({
      selectedFilter: status,
      currentPage: 1,
      totalItems: 0,
      isArchived: 0,
      filterDriver: 2,
    });
  };
  const handleReject = (data: any) => {
    setState({ driver_id: data._id, rejectModal: true });
  };

  const handleApproval = (data: any) => {
    setState({ driver_id: data._id, approvalModal: true });
  };

  const ArchiveDriver = async () => {
    try {
      await Models.driver.deleteDriver({
        ids: [state.id],
        target: "archive",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: false,
        createdByCheck: false,
      });
      setState({ id: "", archiveModal: false });
      GetManyData();
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const UnarchiveDriver = async () => {
    try {
      await Models.driver.deleteDriver({
        ids: [state.id],
        target: "archive",
        checkCreatedBy: false,
        hardDelParam: false,
        reverterParam: true,
        createdByCheck: false,
      });
      setState({ id: "", unarchiveModal: false });
      GetManyData();
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };
  const handleApproveStatus = async (dId) => {
    try {
      const body: any = {
        dId,
      };
      if (state.centerId.length > 0) {
        body.controlCenterId = state.centerId;
      }
      await Models.driver.approveDriver(body);
      GetManyData();
      toastify("Driver status updated");
      setState({
        approvalModal: false,
        centerId: "",
      });
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const handleRejectStatus = async (dId) => {
    if (!_.isEmpty(state.comment)) {
      try {
        const body = {
          dId,
          comment: state.comment,
        };
        await Models.driver.rejectDriver(body);
        GetManyData();
        toastify("Driver status updated");
        setState({
          rejectModal: false,
          comment: "",
        });
      } catch (err) {
        toastifyError(err);
        console.log(err);
      }
    } else {
      toastifyError("Please enter a reason");
    }
  };
  const handleResignStatus = async () => {
    if (!_.isEmpty(state.resignComment)) {
      try {
        const body = {
          dId: state.id,
          comment: state.resignComment,
        };
        // console.log("RESIGN COMMENT",state.resignComment);

        await Models.driver.resignDriver(body);
        GetManyData();
        toastify("Driver status updated");
        setState({
          resignModal: false,
          resignComment: "",
        });
      } catch (err) {
        toastifyError(err);
        console.log(err);
      }
    } else {
      toastifyError("Please enter a reason");
    }
  };
  const handleTerminationStatus = async () => {
    if (!_.isEmpty(state.terminationComment)) {
      try {
        const body = {
          dId: state.id,
          comment: state.terminationComment,
        };
        // console.log("RESIGN COMMENT",state.terminationComment);

        await Models.driver.terminateDriver(body);
        GetManyData();
        toastify("Driver status updated");
        setState({
          terminateModal: false,
          terminationComment: "",
        });
      } catch (err) {
        toastifyError(err);
        console.log(err);
      }
    } else {
      toastifyError("Please enter a reason");
    }
  };
  const handleCheckin = (data) => {
    navigate(`/checkin/${data?.driverId?._id}`);
  };
  const handleExport = async () => {
    const res: any = await Models.driver.exportDriver();
    const compressedString = res.data;
    const compressedBuffer = Buffer.from(compressedString, "base64");
    const base64String = pako.ungzip(compressedBuffer, { to: "base64" });
    createExcelFile(base64String);
  };
  return (
    <div className="driver_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="head h3">Driver</div>
        </div>
        <div className="search_wrapper">
          <Search
            value={state.search}
            placeholder={"Search by Name, Mobile, Whatsapp No"}
            onChange={(search) =>
              setState({ search, currentPage: 1, totalItems: 0 })
            }
          />

          <div className="button_group">
            {(state.selectedFilter === 0 ||
              state.selectedFilter === "APPROVED" ||
              state.selectedFilter === "REQUESTED") && (
              <Filter
                onClick={() => setState({ toggleFilter: !state.toggleFilter })}
              />
            )}
            <AccessControlWrapper accessKey="DRIVER_ONBOARD">
              <Button
                value="New Driver"
                boxIcon="bx-plus"
                onClick={() => {
                  navigate("/driver/add");
                }}
              />
            </AccessControlWrapper>
            <AccessControlWrapper accessKey="DRIVER_EXPORT">
              {" "}
              <Button
                value="Export"
                onClick={() => {
                  handleExport();
                }}
              />
            </AccessControlWrapper>
          </div>
        </div>
      </div>
      {state.toggleFilter && (
        <div className="all_filter_container">
          <div className="all_filter_dropdown_wrapper">
            <div className="all_filter_dropdown_item">
              {(state.selectedFilter === 0 ||
                state.selectedFilter === "APPROVED" ||
                state.selectedFilter === "REQUESTED") && (
                <SelectDropdown
                  notfound={"Not found"}
                  placeholder="Status"
                  data={status}
                  onChange={(filterDriver: any) =>
                    setState({
                      filterDriver: filterDriver.value,
                      currentPage: 1,
                      totalItems: 0,
                    })
                  }
                  value={state.filterDriver}
                />
              )}
            </div>
            <div className="all_filter_dropdown_item">
              <Button
                boxIcon="bx-x-circle"
                value="Clear"
                onClick={() => window.location.reload()} style={{ cursor: "pointer" , backgroundColor : Colors.red}} />
            </div>
          </div>
        </div>
      )}
      <div className="driver_filters">
        <div
          onClick={() => handleFilter(0)}
          className={`button ${0 === state.selectedFilter ? "selected" : ""}`}
        >
          All Drivers
        </div>
        <div
          onClick={() => handleFilter("APPROVED")}
          className={`button ${
            "APPROVED" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Approved
        </div>
        <div
          onClick={() => handleFilter("REQUESTED")}
          className={`button ${
            "REQUESTED" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Requested
        </div>
        <div
          onClick={() => handleFilter("REJECTED")}
          className={`button ${
            "REJECTED" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Rejected
        </div>
        <div
          onClick={() => handleFilter("RESIGNED")}
          className={`button ${
            "RESIGNED" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Resigned
        </div>
        <div
          onClick={() => handleFilter("TERMINATED")}
          className={`button ${
            "TERMINATED" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Terminated
        </div>
        <div
          onClick={() =>
            setState({
              isArchived: 1,
              selectedFilter: "ARCHIVED",
              currentPage: 1,
              totalItems: 0,
            })
          }
          className={`button ${
            "ARCHIVED" === state.selectedFilter ? "selected" : ""
          }`}
        >
          Archived
        </div>
      </div>
      <div className="drivertable">
        <Table
          data={state.data}
          totalDocs={state.totalDocs}
          loading={state.loading}
          theads={
            state.selectedFilter === 0 ||
            state.selectedFilter === "APPROVED" ||
            state.selectedFilter === "REQUESTED"
              ? statusHeading
              : thead
          }
          link=""
          loadMore={() => {}}
          actions={
            state.selectedFilter === 0
              ? [
                  {
                    key: "DRIVER_VIEW",
                    icon: "view",
                    onClick: handleView,
                  },
                ]
              : state.selectedFilter === "APPROVED"
              ? [
                  {
                    key: "DRIVER_VIEW",
                    icon: "view",
                    onClick: handleView,
                  },
                  {
                    key: "DRIVER_CHECKIN",
                    icon: "vehicle",
                    onClick: handleCheckin,
                  },
                  {
                    key: "DRIVER_ARCHIVE",
                    icon: "archive",
                    onClick: handleArchive,
                  },
                  {
                    key: "DRIVER_TERMINATE",
                    icon: "delete",
                    text: "Terminate",
                    textBackground: Colors.red,
                    hideIcon: true,
                    onClick: handleTerminate,
                  },
                  {
                    key: "DRIVER_RESIGN",
                    icon: "delete",
                    text: "Resign",
                    textBackground: Colors.red,
                    hideIcon: true,
                    onClick: handleResign,
                  },
                ]
              : state.selectedFilter === "REQUESTED"
              ? [
                  {
                    key: "DRIVER_VIEW",
                    icon: "view",
                    onClick: handleView,
                  },
                  {
                    key: "DRIVER_APPROVE",
                    icon: "delete",
                    text: "Approve",
                    textBackground: Colors.primary,
                    hideIcon: true,
                    onClick: handleApproval,
                  },
                  {
                    key: "DRIVER_REJECT",
                    icon: "delete",
                    text: "Reject",
                    textBackground: "#ff754c",
                    hideIcon: true,
                    onClick: handleReject,
                  },
                ]
              : state.selectedFilter === "REJECTED"
              ? [
                  {
                    key: "DRIVER_APPROVE",
                    icon: "delete",
                    text: "Approve",
                    textBackground: Colors.primary,
                    hideIcon: true,
                    onClick: handleApproval,
                  },
                  {
                    key: "DRIVER_VIEW",
                    icon: "view",
                    onClick: handleView,
                  },
                ]
              : state.selectedFilter === "RESIGNED"
              ? [
                  {
                    key: "DRIVER_APPROVE",
                    icon: "delete",
                    text: "Approve",
                    textBackground: Colors.primary,
                    hideIcon: true,
                    onClick: handleApproval,
                  },
                  {
                    key: "DRIVER_VIEW",
                    icon: "view",
                    onClick: handleView,
                  },
                ]
              : state.selectedFilter === "TERMINATED"
              ? [
                  {
                    key: "DRIVER_APPROVE",
                    icon: "delete",
                    text: "Approve",
                    textBackground: Colors.primary,
                    hideIcon: true,
                    onClick: handleApproval,
                  },
                  {
                    key: "DRIVER_VIEW",
                    icon: "view",
                    onClick: handleView,
                  },
                ]
              : state.selectedFilter === 0
              ? [
                  {
                    key: "DRIVER_VIEW",
                    icon: "view",
                    onClick: handleView,
                  },
                ]
              : state.selectedFilter === "ARCHIVED"
              ? [
                  {
                    key: "DRIVER_VIEW",
                    icon: "view",
                    onClick: handleView,
                  },
                  {
                    key: "DRIVER_UNARCHIVE",
                    icon: "upload",
                    onClick: handleUnarchive,
                  },
                ]
              : []
          }
          imageKey="userImage"
          img={true}
        />
      </div>
      <div className="driver_pagination">
        <Pagination
          totalItems={state.totalItems}
          itemsPerPage={10}
          currentPage={state.currentPage}
          onPageChange={handlePageChange}
        />
      </div>
      {/* approve modal */}
      <CustomModal
        center
        open={state.approvalModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ approvalModal: false })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">
            Are you sure you want to approve?
          </div>
          <div>
            <SelectDropdown
              notfound={"Not found"}
              placeholder="Control Centers"
              data={state.centerData}
              onChange={(centerId: any) =>
                setState({
                  centerId: centerId.value,
                  currentPage: 1,
                  totalItems: 0,
                })
              }
              value={state.centerId}
            />
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ approvalModal: false });
              }}
              className="reject_cancel_button"
            >
              Cancel
            </div>
            <div
              onClick={() => handleApproveStatus(state.driver_id)}
              className="reject_button"
            >
              Approve
            </div>
          </div>
        </div>
      </CustomModal>
      {/* approve modal end */}
      {/* reject Modal */}
      <CustomModal
        center
        open={state.rejectModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ rejectModal: false, comment: "" })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">Reason for rejection</div>
          <div className="rejection_document_wrapper">
            <TextareaComponent
              placeholder="Enter rejection reasons"
              value={state.comment}
              onChange={(comment: any) => setState({ comment })}
            />
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ rejectModal: false, comment: "" });
              }}
              className="reject_cancel_button"
            >
              Cancel
            </div>
            <div
              onClick={() => handleRejectStatus(state.driver_id)}
              className="reject_button"
            >
              Save
            </div>
          </div>
        </div>
      </CustomModal>
      {/* reject modal end */}
      {/* For Resign modal start*/}
      <CustomModal
        center
        open={state.resignModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ resignModal: false, resignComment: "" })}
      >
        <div className="reject_modal_container">
          <div className="reject_heading">Reason for resign</div>
          <div className="rejection_document_wrapper">
            <TextareaComponent
              placeholder="Enter resignation reason."
              value={state.resignComment}
              onChange={(resignComment: any) => setState({ resignComment })}
            />
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ resignModal: false, resignComment: "" });
              }}
              className="reject_cancel_button"
            >
              Cancel
            </div>
            <div onClick={handleResignStatus} className="reject_button">
              Save
            </div>
          </div>
        </div>
      </CustomModal>
      {/* For Resign modal start*/}
      {/* For Terminate modal start*/}
      <CustomModal
        center
        open={state.terminateModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() =>
          setState({ terminateModal: false, terminationComment: "" })
        }
      >
        <div className="reject_modal_container">
          <div className="reject_heading">Reason for termination</div>
          <div className="rejection_document_wrapper">
            <TextareaComponent
              placeholder="Enter termination reason."
              value={state.terminationComment}
              onChange={(terminationComment: any) =>
                setState({ terminationComment })
              }
            />
          </div>
          <div className="reject_modal_button">
            <div
              onClick={() => {
                setState({ terminateModal: false, terminationComment: "" });
              }}
              className="reject_cancel_button"
            >
              Cancel
            </div>
            <div onClick={handleTerminationStatus} className="reject_button">
              Save
            </div>
          </div>
        </div>
      </CustomModal>
      {/* For Terminate modal start*/}

      {/* delete modal starts */}
      <CustomModal
        center
        open={state.deleteModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ deleteModal: false })}
      ></CustomModal>
      {/* delete modal ends */}
      <CustomModal
        center
        open={state.archiveModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ archiveModal: false })}
      >
        <DeletePopup
          onPress={ArchiveDriver}
          onCancel={() => setState({ archiveModal: false })}
        />
      </CustomModal>
      <CustomModal
        center
        open={state.unarchiveModal}
        classNames={{ modalContainer: "delete_modal_container" }}
        onClose={() => setState({ unarchiveModal: false })}
      >
        <DeletePopup
          onPress={UnarchiveDriver}
          onCancel={() => setState({ unarchiveModal: false })}
        />
      </CustomModal>
    </div>
  );
}
