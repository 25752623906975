import { Form, Formik } from "formik";
import * as yup from "yup";
import "./signup.screen.scss";
import InputField from "common_components/ui/field/field.ui";
import Button from "common_components/ui/button/button.ui";
import { useLocation, useNavigate } from "react-router-dom";
import { toastify, toastifyError } from "utils/functions.utils";
import encrypt from "utils/encrypt";
import Models from "imports/models.import";
import { authSliceType } from "store/slices/auth.slice";
import { useDispatch, useSelector } from "react-redux";
import { passwordRegex } from "utils/validation.utils";
import { useEffect } from "react";
import { resetInitMandate } from "store/actions/auth.actions";

export default function ResetPasswordConfirmation() {
  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Please enter password")
      .matches(passwordRegex, "Please choose a different password."),
    confirm_password: yup.string().required("Please enter confirm password"),
  });

  // navigation
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state;
  console.log(email);
  // const { id } = useParams();
  const dispatch = useDispatch();
  const auth: authSliceType = useSelector((s: any) => s.auth);
  const resetPassword = async (values: any) => {
    if (values.password !== values.confirm_password) {
      toastifyError("Confirm password must match to the password");
    } else {
      try {
        const body = {
          email: location.state,
          password: values.password,
        };
        const jsonString = JSON.stringify(body);
        const data = await encrypt(jsonString);
        let res: any;
        if (auth.CLIENT_INIT_MANDATE) {
          res = await Models.auth.clientChangePassword({ data });
        } else {
          res = await Models.auth.forgotPassword3({ data });
        }
        if (res.status === 1) toastify("Password changes successfully!!!");
        navigate("/");
      } catch (err: any) {
        toastifyError(err);
      }
    }
  };
  useEffect(()=>{
    dispatch(resetInitMandate())
  },[])
  return (
    <div className="signup_screen">
      <div className="signup_screen_wrapper">
        <div className="signup_screen_container">
          <div
            className="left_arrow"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="bx bx-md bx-chevron-left text-white"></i>
          </div>
          <div className="signup_head_container">
            <div
              className="signup_head_wrapper"
              style={{ justifyContent: "center" }}
            >
              <div className="signup_head">Reset Password</div>
              {auth.CLIENT_INIT_MANDATE ? (
                <span>
                  Please change your default password , after that you can login
                  to dashboard.
                </span>
              ) : null}
            </div>
          </div>
          <div className="signup_input_wrapper">
            {JSON.stringify(location.state)}
            <Formik
              onSubmit={resetPassword}
              validationSchema={validationSchema}
              initialValues={{
                password: "",
                confirm_password: "",
              }}
            >
              <Form>
                <div className="signup_input_container">
                  <InputField
                    name="email"
                    type="text"
                    disabled
                    value={location.state}
                    optional={true}
                  />
                </div>
                <div className="signup_input_container">
                  <InputField
                    name="password"
                    type="password"
                    placeholder="Password"
                    autocomplete={false}
                    optional={true}
                  />
                </div>
                <div className="signup_input_container">
                  <InputField
                    name="confirm_password"
                    type="password"
                    placeholder="Confirm Password"
                    autocomplete={false}
                    optional={true}
                  />
                </div>
                <div className="submit_button_container">
                  <Button width="100%" value="Reset Password" />
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
