import Main from 'common_components/hoc/main.hoc'
import React, { FC, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

export const ADMIN_ROLES = ["SA", "ADMIN"]
const AdminPOV: FC<any> = () => {
  const auth = useSelector((s: any) => s.auth);
  if (ADMIN_ROLES.includes(`${localStorage.getItem('role')}`)) {
    return <Outlet />
  }
  // return <Outlet />
  return <Navigate to="/unauthorized" />
}

export default AdminPOV