import { useNavigate, useParams } from "react-router-dom";
import {
  useSetState,
  toastifyError,
  createExcelFile,
} from "utils/functions.utils";
import "./warehouse.screen.scss";
import Search from "common_components/ui/search/search.ui";
import Button from "common_components/ui/button/button.ui";
import Pagination from "components/pagination/pagination.component";
import { Models } from "utils/imports.utils";
import { useEffect, useState } from "react";
import Assets from "imports/assets.import";
import _ from "lodash";
import Table from "components/table/table.component";
import pako from "pako";
import { Buffer } from "buffer";
import CustomModal from "common_components/ui/modal/modal.component";
import DeletePopup from "components/delete_popup/delete_popup.component";
import FilterBox from "common_components/ui/filter_box/filter_box.ui";
import "./power.screen.scss"
import History from "components/history/history.component";

export default function Power() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const { id } = useParams();

  const [state, setState] = useSetState({
    data: [],
    search: "",
    openModal: false,
    viewModal: false,
    deleteModal: false,
    loading: false,
    toggleFilter: false,
  });
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const thead = [
    {
      head: "Month",
      key: "monthFor",
      type: "string",
    },
    { head: "Bill number", key: "billNumber", type: "string" },
    {
      head: "Initial Reading",
      key: "initialReadings",
      type: "string",
    },
    {
      head: "Paid Amount",
      key: "paidAmount",
      type: "string",
    },
    {
      head: "Bill Document",
      key: "billDocument",
      type: "download",
    },
  ];
  const GetManyData = async () => {
    try {
      let res: any;
      const body: any = {
        // page: state.currentPage,
        // pageSize: 10,
        wId: id,
      };
      if (state.search.length > 0) {
        body.search = state.search;
      
      }
      res = await Models.warehouse.getManyPower(body);
      // setState({ activeTab: res.data?.[0]?.year, loading: false });
      let data: any = [];
      for (let tab of res?.data) {
        const year = tab?.year;
        const count = res?.data.filter((item) => item?.year === year).length;
        const exists = data.some((item) => item.label === year);
        if (!exists) {
          data.push({
            label: year,
            value: year,
            count: count,
          });
        }
      }

      data = _.orderBy(data, ["label"]);
      setState({ headers: data });
      console.log(res?.data);

      // const filteredData = res?.data?.filter(
      //   (item) => item?.year === res?.data
      // );
      setState({ data: res?.data });
    } catch (error) {
      setState({ loading: false });
      console.log(error);
      toastifyError(error);
    }
  };
  useEffect(() => {
    GetManyData();
  }, [state.search, state.currentPage, activeTabIndex]);

  return (
    <div className="power_screen">
      <div className="header_container">
        <div className="header_wrapper">
          <div className="header_details_wrapper">
            <div className="header_details">
              <History  path="warehouse" name="power bills" />
            </div>
          </div>
        </div>
        <div className="search_wrapper">
          {/* <Search
            value={state.search}
            onChange={(search) => setState({ search ,currentPage: 1, totalItems: 0 })}
            placeholder={""}
          /> */}
          <div style={{flex : 1}}></div>
          <div className="button_group">
            <Button
              value="Add Power bills"
              onClick={() => {
                navigate(`/warehouse/power/add/${id}`);
              }}
            />
          </div>
        </div>
      </div>
      <div className="power_filters_scroll ">
        {state?.data?.map((item, index) => {
          return (
            <div className={`power-cards ${index === activeTabIndex ? "selected" : ''}`}
              onClick={() => setActiveTabIndex(index)}
            >
              <div className="content">
                <p className="big-content">Total Bills  {item?.powerBillHistory?.length}</p>
                <p className="small-content">Year  {item?.year}</p>
              </div>
              <div className="icon">
                <i className='bx bxs-zap' ></i>
              </div>
              <div onClick={()=>navigate(`/warehouse/power/edit/${id}/${item?.year}`)} className="action-btn">
                <img src={Assets['edit']} alt="edit" />
              </div>
            </div>
          );
        })}
      </div>
      <div className="storetable">
        <Table
          data={state.data?.[activeTabIndex]?.powerBillHistory}
          loading={state.loading}
          theads={thead}
          link="store"
          // actions={
          //   role === "SA"
          //     ? [
          //       {
          //         icon: "delete",
          //         onClick: handleDelete,
          //       },
          //       {
          //         icon: "edit",
          //         onClick: handleEdit,
          //       },
          //     ]
          //     : []
          // }
        />
      </div>
      
    </div>
  );
}
