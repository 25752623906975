import Button from 'common_components/ui/button/button.ui'
import Search from 'common_components/ui/search/search.ui'
import Colors from 'imports/color.import'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setClientPOVStoreLogout } from 'store/actions/client.pov.store.actions'
import { ClientPOVStoreType } from 'store/slices/client.pov.store.slice'
import { toastify } from 'utils/functions.utils'

const ClientPovLoggedInStore = () => {
  const store: any = useSelector((s: any) => s.clientPOVStore?.store)
  const user: any = useSelector((s: any) => s.auth?.user)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(setClientPOVStoreLogout());
  }
  const handleViewStore = () => {
    navigate(`/client-pov/${user._id}/store/view/${store._id}`);
  }
  return (
    <div>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <div className="view_head_title h5">
              {store?.storeName}
            </div>
            <div className="view_head_sub_title ">{`Store - ${store.storeId || ""
              }`}</div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              onClick={handleViewStore}
              value="View store details"
            />
            <Button
              style={{ background: Colors.red, marginLeft: 10 }}
              onClick={handleLogout}
              value="Logout from store"
            />
          </div>
        </div>
        <div className="header_container">
          <div className="search_wrapper">
            <Search
              value={""}
              onChange={(search) => {}}
            />
            <div className="button_group">
              <Button
              value="Add Order"
              onClick={() => {
                toastify("This feature will coming soon.")
              }}
            />
            </div>
          </div>
        </div>
        <div style={{
          height : 400,
          width : "100%",
          display : 'flex',
          justifyContent : "center",
          alignItems : 'center',
        }}>
          <h3>Order management system will arrive soon ....</h3>

        </div>
      </div>
    </div>
  )
}

export default ClientPovLoggedInStore