import InputField from "common_components/ui/field/field.ui";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Validation from "utils/validation.utils";
import React, { useRef, useEffect, useState } from "react";
import "./add.component.scss";
import {
  getNestedObjectValue,
  toastifyError,
  useSetState,
} from "utils/functions.utils";
import { IAddValues } from "utils/interface.utils";
import CustomSelect from "common_components/ui/select/select.ui";
import Select from "components/select/select.component";
import ValidationError from "common_components/ui/error/error.ui";
import _ from "lodash";
import Divider from "common_components/ui/divider/divider.ui";
import FileInput from "common_components/ui/file_input/file_input.ui";
import MultiUserSelect from "components/multiselect/multiselect.component";
import MapPicker from "common_components/ui/map_picker/map_picker.ui";
import Models from "imports/models.import";
import Editor from "components/editor/editor.component";
import Assets from "imports/assets.import";

interface addProps {
  data: any;
  values: IAddValues[];
  actions: { link: string; icon: string }[];
  hasFiles?: boolean;
  buttons?: any;
  title: string;
  logo?: any;
  initialValues: any;
  validationSchema: string;
  onSubmit: any;
  getForm?: any;
  optional?: boolean;
}
export default function Add(props: addProps) {
  const {
    data,
    values,
    hasFiles,
    buttons,
    logo,
    initialValues,
    validationSchema,
    onSubmit,
    getForm,
  } = props;
  const formikRef: any = useRef(null);
  const getEditableValues = () => {
    let newData = {};
    if (data) {
      Object.keys(data).forEach((item) => {
        newData[item] = data[item] || initialValues[item];
      });
      return newData;
    }
    return data;
  };

  const [state, setState] = useSetState({
    form: data,
    role: "",
    state: "",
    cities: [],
    area: 0,
    cityLoading: false,
  });
  const [publicUrl, setPublicUrl] = useState([]);
  //supervisor
  const [supervisors, setSupervisors] = useState([
    { name: "", number: "", shiftTimeStart: "", shiftTimeEnd: "" },
  ]);
  useEffect(() => {
    if (data?.supervisors) {
      setSupervisors(data?.supervisors);
    }
  }, [data?.supervisors])
  const handleAddSupervisor = () => {
    setSupervisors([
      ...supervisors,
      { name: "", number: "", shiftTimeStart: "", shiftTimeEnd: "" },
    ]);
  };
  const handleRemoveSupervisor = (index) => {
    const updatedSupervisors = [...supervisors];
    updatedSupervisors.splice(index, 1);
    setSupervisors(updatedSupervisors);
  };
  //security
  const [securities, setSecurities] = useState([
    { name: "", number: "", shiftTimeStart: "", shiftTimeEnd: "" },
  ]);
  useEffect(() => {
    if (data?.securityPersonDetails) {
      setSecurities(data?.securityPersonDetails);
    }
  }, [data?.securityPersonDetails])
  const handleAddSecurity = () => {
    setSecurities([
      ...securities,
      { name: "", number: "", shiftTimeStart: "", shiftTimeEnd: "" },
    ]);
  };
  const handleRemoveSecurity = (index) => {
    const updatedSecurities = [...securities];
    updatedSecurities.splice(index, 1);
    setSecurities(updatedSecurities);
  };

  const [tempFile, setTempFile] = useState<{
    url: string | null;
    name: string | null;
  }>({
    url: null,
    name: null,
  });
  useEffect(() => {
    getCity();
  }, [state.state]);

  useEffect(() => {
    values.forEach(({ key, type }) => {
      if (type === "state" && (data[key] || getNestedObjectValue(data, key))) {
        {
          setState({ state: data[key] || getNestedObjectValue(data, key) });
        }
      }
      if (type === "space") {
        {
          setState({
            area:
              getNestedObjectValue(data, "spaceAvailability.length") *
              getNestedObjectValue(data, "spaceAvailability.width"),
          });
        }
      }
    });
  }, [data]);
  useEffect(() => {
    setState({ form: getEditableValues() });
  }, [data]);

  useEffect(() => {
    values.forEach(({ key, type }) => {
      if (
        type === "file" &&
        (data[key] || getNestedObjectValue(data, key)) &&
        !isPublicURL(data[key]) &&
        !state[`${key}PublicURL`]
      ) {
        convertToPublicUrl(key, getNestedObjectValue(data, key));
      }
    });
  }, [data]);
  const convertToPublicUrl = async (key, url) => {
    try {
      const body: any = {
        bucketName: process.env.REACT_APP_SIGNED_BUCKET_KEY,
        file: url,
        expiresIn: 3000,
      };
      const response: any = await Models.signedUrl.getSignedUrl(body);

      // Check if the response has a valid link
      if (response?.data?.link) {
        setPublicUrl((prevState) => ({
          ...prevState,
          [`${key}PublicURL`]: response?.data?.link,
        }));
      } else {
        console.error("Invalid public URL in the response:", response);
        // Handle the error or log it as needed
      }
    } catch (err) {
      console.log(err);
      toastifyError(err);
    }
  };

  const isPublicURL = (url) => {
    return url?.startsWith("https://");
  };
  const conditionCheck = (condition) => {
    if (!condition) return true;
    if (condition(state.form)) {
      return true;
    }
  };

  const handleChange = (form) => {
    values.forEach(({ key, type }) => {
      if (type === "state" && (form[key] || getNestedObjectValue(form, key))) {
        {
          setState({ state: form[key] || getNestedObjectValue(form, key) });
        }
      }
      if (type === "space") {
        {
          setState({
            area:
              getNestedObjectValue(form, "spaceAvailability.length") *
              getNestedObjectValue(form, "spaceAvailability.width"),
          });
        }
      }
    });
    setState({ form });
    if (getForm) {
      getForm(form);
    }
  };
  const getCity = async () => {
    setState({ cityLoading: true });
    try {
      if (state?.state?.length > 0) {
        const apiUrl = `https://api-dev.users.fullfily.com/api/v1/smc/location?key=ct&value=${encodeURIComponent(
          state.state
        )}`;

        const response = await fetch(apiUrl, {
          method: "GET", // Use the GET method for retrieving data
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const citiesData = await response.json();

          const cities = _.chain(citiesData.data)
            .map((e: any) => ({
              label: e.toUpperCase(),
              value: e,
            }))
            .compact()
            .uniqBy("value")
            .orderBy("label")
            .value();

          setState({ cities: cities });
        } else {
          // Handle the error if the response is not okay
          console.error(
            "Failed to fetch cities:",
            response.status,
            response.statusText
          );
        }
      }
    } catch (err: any) {
      console.error(err);
      toastifyError("Can't fetch cities !");
    } finally {
      setState({ cityLoading: false });
    }
  };

  const value = getEditableValues() || initialValues;

  return (
    <div className="add_container">
      <div className="add_wrapper">
        <Formik
          ref={formikRef}
          onSubmit={onSubmit}
          validate={handleChange}
          initialValues={value}
          validationSchema={Validation[validationSchema]}
          enableReinitialize
        >
          {/* @ts-ignore */}
          <Form autoComplete="off">
            {logo && logo}
            <div className="add_field_body_container">
              <div className="add_field_body_wrapper">
                {values.map(
                  ({
                    label,
                    key,
                    type,
                    options,
                    condition,
                    additionalInfo,
                    secondaryKey,
                    items,
                    optional,
                    hide,
                  }) =>
                    !hide
                      ? conditionCheck(condition) && (
                        <>
                          {type === "string" && (
                            <div className="add_field_container">
                              <InputField
                                optional={optional}
                                name={key}
                                label={label}
                                type="text"
                              />
                            </div>
                          )}
                          {type === "supervisor" && (
                            <div className="supervisor">
                              {supervisors.map((supervisor, index) => (
                                <>
                                  <div key={index}>
                                    <div className="add_field_container">
                                      <InputField
                                        optional={optional}
                                        name={`supervisors[${index}].name`}
                                        label={`Supervisor Name ${index + 1}`}
                                        type="text"
                                      />
                                    </div>
                                    <div className="add_field_container">
                                      <InputField
                                        optional={optional}
                                        name={`supervisors[${index}].number`}
                                        label={`Supervisor Number ${index + 1}`}
                                        type="text"
                                      />
                                    </div>
                                    <div className="add_field_container">
                                      <InputField
                                        optional={optional}
                                        name={`supervisors[${index}].shiftTimeStart`}
                                        label={`Supervisor Shift Time Start ${index + 1
                                          }`}
                                        type="text"
                                      />
                                    </div>
                                    <div className="add_field_container">
                                      <InputField
                                        optional={optional}
                                        name={`supervisors[${index}].shiftTimeEnd`}
                                        label={`Supervisor Shift Time End ${index + 1
                                          }`}
                                        type="text"
                                      />
                                    </div>
                                    <div className="add_remove">
                                      <button
                                        type="button"
                                        onClick={handleAddSupervisor}
                                      >
                                        <span className="plus">+</span>
                                        <span>
                                          Add another supervisor in charge
                                        </span>
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleRemoveSupervisor(index)
                                        }
                                      >
                                        <img src={Assets["delete"]} />
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    index !== supervisors.length - 1 &&
                                    <Divider style={{ background: 'grey', margin: 15 }} />
                                  }
                                </>
                              ))}
                            </div>
                          )}
                          {type === "security" && (
                            <div className="supervisor">
                              {securities.map((security, index) => (
                                <>
                                  <div key={index}>
                                    <div className="add_field_container">
                                      <InputField
                                        optional={optional}
                                        name={`securityPersonDetails[${index}].name`}
                                        label={`Security Name ${index + 1}`}
                                        type="text"
                                      />
                                    </div>
                                    <div className="add_field_container">
                                      <InputField
                                        optional={optional}
                                        name={`securityPersonDetails[${index}].number`}
                                        label={`Security Number ${index + 1}`}
                                        type="text"
                                      />
                                    </div>
                                    <div className="add_field_container">
                                      <InputField
                                        optional={optional}
                                        name={`securityPersonDetails[${index}].shiftTimeStart`}
                                        label={`Security Shift Time Start ${index + 1
                                          }`}
                                        type="text"
                                      />
                                    </div>
                                    <div className="add_field_container">
                                      <InputField
                                        optional={optional}
                                        name={`securityPersonDetails[${index}].shiftTimeEnd`}
                                        label={`Security Shift Time End ${index + 1
                                          }`}
                                        type="text"
                                      />
                                    </div>
                                    <div className="add_remove">
                                      <button
                                        type="button"
                                        onClick={handleAddSecurity}
                                      >
                                        <span className="plus">+</span>
                                        <span>
                                          Add another security in charge
                                        </span>
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleRemoveSecurity(index)
                                        }
                                      >
                                        <img src={Assets["delete"]} />
                                      </button>
                                    </div>
                                  </div>
                                  {
                                    index !== securities.length - 1 &&
                                    <Divider style={{ background: 'grey', margin: 15 }} />
                                  }
                                </>
                              ))}
                            </div>
                          )}
                          {type === "space" && (
                            <div className="add_field_container capacity">
                              <InputField
                                optional={optional}
                                name={"spaceAvailability.length"}
                                label="Space length"
                                placeholder="length"
                                type="number"
                              />
                              <InputField
                                optional={optional}
                                name={"spaceAvailability.width"}
                                label={"Space width"}
                                placeholder="width"
                                type="number"
                              />
                              <div className="area">
                                Area = {state.area || 0}
                              </div>
                            </div>
                          )}

                          {type === "password" && (
                            <div className="add_field_container">
                              <InputField
                                optional={optional}
                                name={key}
                                label={label}
                                type="password"
                              />
                            </div>
                          )}
                          {type === "number" && (
                            <div className="add_field_container">
                              <InputField
                                optional={optional}
                                name={key}
                                label={label}
                                type="number"
                              />
                              {additionalInfo && (
                                <div className="additional_field">
                                  {additionalInfo}
                                </div>
                              )}
                            </div>
                          )}
                          {type === "date" && (
                            <div className="add_field_container">
                              <InputField
                                optional={optional}
                                name={key}
                                label={label}
                                type="date"
                                value={data[key]}
                              />
                            </div>
                          )}
                          {type === "year" && (
                            <div className="add_field_container">
                              <InputField
                                optional={optional}
                                name={key}
                                label={label}
                                type="year"
                                value={data[key]}
                              />
                            </div>
                          )}
                          {type === "birthdate" && (
                            <div className="add_field_container">
                              <InputField
                                optional={optional}
                                name={key}
                                label={label}
                                type="birthdate"
                                value={data[key]}
                              />
                            </div>
                          )}
                          {type === "future_date" && (
                            <div className="add_field_container">
                              <InputField
                                optional={optional}
                                name={key}
                                label={label}
                                type="future_date"
                                value={data[key]}
                              />
                            </div>
                          )}
                          {type === "dateRange" && (
                            <div className="add_field_container">
                              <InputField
                                optional={optional}
                                name={key}
                                label={label}
                                type="dateRange"
                                value={data[key]}
                              />
                            </div>
                          )}
                          {type === "select" && (
                            <div className="add_field_container">
                              <CustomSelect
                                name={key}
                                label={label}
                                placeholder={label}
                                options={options}
                                optional={optional}
                              />
                              {/* <ErrorMessage
                              name={key}
                              component={ValidationError}
                            /> */}
                            </div>
                          )}
                          {type === "city" && (
                            <div className="add_field_container">
                              <CustomSelect
                                loading={state.cityLoading}
                                name={key}
                                label={"City"}
                                placeholder={"Select a City"}
                                options={state.cities}
                                optional={optional}
                              />
                            </div>
                          )}
                          {type === "textarea" && (
                            <div className="add_field_container">
                              <InputField
                                optional={optional}
                                className="textarea_wrapper"
                                name={key}
                                label={label}
                                type="textarea"
                              />
                            </div>
                          )}
                          {type === "driver" && (
                            <div className="add_field_container">
                              <Field name={key}>
                                {({ field, form }) => (
                                  <Select
                                    name={key}
                                    onChange={(val) =>
                                      form.setFieldValue(key, val)
                                    }
                                    placeholder="Select driver"
                                    label={label}
                                    type="driver"
                                    value={data?.[key]?.name || ""}
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name={key}
                                component={ValidationError}
                              />
                            </div>
                          )}
                          {type === "vehicle" && (
                            <div className="add_field_container">
                              <Field name={key}>
                                {({ field, form }) => (
                                  <Select
                                    name={key}
                                    onChange={(val) => {
                                      form.setFieldValue(key, val);
                                      console.log("VEHICLE", key, val);
                                    }}
                                    placeholder="Select vehicle"
                                    label={label}
                                    type="vehicle"
                                    value={field.value}
                                    optional={optional}
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name={key}
                                component={ValidationError}
                              />
                            </div>
                          )}
                          {type === "user" && (
                            <div className="add_field_container">
                              <Field name={key}>
                                {({ field, form }) => (
                                  <Select
                                    name={key}
                                    onChange={(val) =>
                                      form.setFieldValue(key, val)
                                    }
                                    placeholder="Select user"
                                    label={label}
                                    type="user"
                                    value={field.value}
                                    optional={optional}
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name={key}
                                component={ValidationError}
                              />
                            </div>
                          )}
                          {type === "center" && (
                            <div className="add_field_container">
                              <Field name={key}>
                                {({ field, form }) => (
                                  <Select
                                    name={key}
                                    onChange={(val) => {
                                      form.setFieldValue(key, val);
                                    }}
                                    label={label}
                                    type="center"
                                    placeholder="Select control center "
                                    value={field.value}
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name={key}
                                component={ValidationError}
                              />
                            </div>
                          )}
                          {type === "warehouse" && (
                            <div className="add_field_container">
                              <Field name={key}>
                                {({ field, form }) => (
                                  <Select
                                    name={key}
                                    onChange={(val) => {
                                      form.setFieldValue(key, val);
                                    }}
                                    label={label}
                                    type="warehouse"
                                    placeholder="Select warehouse"
                                    value={field.value}
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name={key}
                                component={ValidationError}
                              />
                            </div>
                          )}
                          {type === "multiselect" && (
                            <div className="add_field_container">
                              <Field name={key}>
                                {({ field, form }) => (
                                  <MultiUserSelect
                                    items={items as any}
                                    name={key}
                                    onChange={(val) => {
                                      form.setFieldValue(key, val);
                                    }}
                                    placeholder="Select users"
                                    label={label}
                                    value={field.value}
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name={key}
                                component={ValidationError}
                              />
                            </div>
                          )}
                          {type === "state" && (
                            <div className="add_field_container">
                              <Field name={key}>
                                {({ field, form }) => (
                                  <Select
                                    name={key}
                                    onChange={(val) => {
                                      form.setFieldValue(key, val);
                                    }}
                                    label={label}
                                    type="state"
                                    placeholder="Select state"
                                    value={
                                      field.value ||
                                      data[key] ||
                                      getNestedObjectValue(data, key)
                                    }
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name={key}
                                component={ValidationError}
                              />
                            </div>
                          )}
                          {type === "array" && (
                            <div className="add_field_container">
                              <InputField
                                optional={optional}
                                name={key}
                                label={label}
                                type="array"
                                placeholder={`${label} values separated by commas`}
                              />
                            </div>
                          )}
                          {type === "map" && (
                            <div className="add_field_container">
                              <Field name={key}>
                                {({ field, form }) => (

                                  <MapPicker
                                    label={label}
                                    value={data[key.split("|")?.[1]] || getNestedObjectValue(data, key.split("|")?.[1]) || { coordinates: [0, 0] }}
                                    fullAddress={data?.[key.split("|")?.[0]] || getNestedObjectValue(data, key.split("|")?.[0]) || ""}
                                    onChange={(val) => {
                                      form.setFieldValue(key.split("|")?.[0], val.fullAddress);
                                      form.setFieldValue(key.split("|")?.[1], val.geoLocation);
                                    }}
                                    placeholder="Pick store location"
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name={key}
                                component={ValidationError}
                              />
                            </div>
                          )}
                        </>
                      )
                      : null
                )}
              </div>
            </div>
            {hasFiles && (
              <>
                <Divider />
                <div className="add_field_body_container">
                  <div className="add_field_body_wrapper">
                    {values.map(
                      ({
                        label,
                        key,
                        type,
                        secondaryKey,
                        file_bucket_url,
                        hide,
                        optional,
                      }) =>
                        !hide ? (
                          <>
                            {(type === "file" || type === 'file-all') && (
                              <div className="add_field_container">
                                <Field name={key}>
                                  {({ field, form, meta }) => (
                                    <FileInput
                                      value={field.value}
                                      label={label}
                                      fileKey={key}
                                      fileType={type == 'file-all' ? "file" : 'image'}
                                      name={field.name}
                                      accessType={secondaryKey as any}
                                      file_bucket_url={file_bucket_url}
                                      onChangeFile={(file) => {
                                        console.log(file);

                                        form.setFieldValue(field.name, file);
                                      }}
                                      optional={optional}
                                    />
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={key}
                                  component={ValidationError}
                                />
                              </div>
                            )}
                          </>
                        ) : null
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="add_field_body_container">
              {values.map(
                ({
                  label,
                  key,
                  type,
                  secondaryKey,
                  file_bucket_url,
                  hide,
                  optional,
                }) =>
                  type === "editor" ? (
                    <div className="editor_container">
                      <Field name={key}>
                        {({ field, form, meta }) => (
                          <Editor
                            name={key}
                            onChange={(html) => {
                              form.setFieldValue(key, html);
                            }}
                            label={label}
                            style={{ height: "320px" }}
                            type="center"
                            placeholder="Type your job description "
                            value={field.value}
                          />
                        )}
                      </Field>
                      <ErrorMessage name={key} component={ValidationError} />
                    </div>
                  ) : null
              )}
            </div>
            {buttons && buttons}
          </Form>
        </Formik>
      </div>
    </div>
  );
}
