import { Field, ErrorMessage } from "formik";
import React from "react";
import Select from "react-select";
import ValidationError from "../error/error.ui";
import "./select.scss";
import Colors from "imports/color.import";
import { Dropdown } from "primereact/dropdown";
interface optionObject {
  value: number | string | boolean;
  label: string;
}

interface customSelectProps {
  label?: string;
  className?: string;
  name: string;
  placeholder: string;
  options?: optionObject[];
  isMulti?: boolean;
  handleChange?: any;
  disable?: any;
  loading?: boolean;
  optional?: boolean;
}

export const CustomSelect = (props: customSelectProps) => {
  const {
    label,
    className,
    name,
    placeholder,
    options,
    isMulti,
    handleChange,
    optional,
    loading = false,
  } = props;

  return (
    <div className="select_container">
      <div className="select_label caption2">
        {label || ""} {!optional && <span className="required">*</span>}
      </div>
      <Field name={name}>
        {({ field, form }) => {
          return (
            // @ts-ignore
            <Dropdown
              loading={loading}
              filter
              value={field.value}
              onChange={(e) => form.setFieldValue(field.name, e.value)}
              options={options ?? []}
              optionLabel="label"
              placeholder={placeholder}
              style={{ width: "100%" }}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={ValidationError} />
    </div>
  );
};

CustomSelect.defaultProps = {
  label: "",
  className: "",
  isMulty: false,
  handleChange: () => {},
  options: [],
};

export default CustomSelect;
