import Main from 'common_components/hoc/main.hoc'
import React, { FC, Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { getAccessControls } from 'store/actions/access.control.actions'

const ProtectedRoutes: FC<any> = () => {
  const auth = useSelector((s:any)=>s.auth);
  if (!auth.loggedIn) {
    return <Navigate to="/" />
  }
 
  return (
    <Main>
      <Outlet />
    </Main>
  )
}

export default ProtectedRoutes