import Button from 'common_components/ui/button/button.ui'
import Divider from 'common_components/ui/divider/divider.ui';
import CircularBar from 'components/circular_progress_bar/circular_bar.component';
import Pagination from 'components/pagination/pagination.component';
import Models from 'imports/models.import';
import React, { FC, useEffect } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useNavigate } from 'react-router-dom';
import { useSetState } from 'utils/functions.utils';
const StoreSummery:FC<{sid :string}> = ({sid}) => {
  const [state ,setState] = useSetState({
    data : {},
    currentPage: 1,
    totalItems: 0,
  })
  const navigate = useNavigate();
  useEffect(() => {
    getData();
  }, [state.currentPage]);
  const getData = async() =>{
    try {
      const response: any = await Models.checkin.getSummery({
        id:sid,
        type: 'store',
        // page: state.currentPage,
        // pageSize: 5,
      });
      setState({ data: response.data, totalItems: response?.count, });
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div>
      <div className="container-fluid">
        <table width={"100%"}>
          <tbody>
            <tr>
              <th>Available Drivers </th>
              <td>{state?.data?.availableDriverCount ?? 0} </td>
              <td colSpan={2} rowSpan={2} width={130}>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <strong>Workforce Available</strong>
                    </div>
                    <div className="col-4"  >
                      <div className="d-flex" style={{ height: 100, width: 100, margin: "auto", alignSelf: 'center' }}>
                        <CircularProgressbar
                          value={state?.data?.workforceAvailable ?? 0}
                          text={`${Math.floor(state?.data?.workforceAvailable ?? 0)}%`}
                          styles={buildStyles({
                            textColor: "black",
                            pathColor: "#7F52FF",
                            // trailColor: "gold",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>Present Drivers </th>
              <td>{state?.data?.presentDriverCount ?? 0} </td>
            </tr>
          </tbody>
        </table>
        <Divider />
        <div className="row">
          <div className="col-md-6">
            <h6 className='text-center' >Available drivers</h6>
            <table className='table table-bordered' style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Driver name</th>
                  <th>Driver contact</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  state?.data?.availableDriverDetails?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.driverId?.fullName}</td>
                      <td>{item?.driverId?.mobile}</td>
                      <td className="text-center">
                        {
                          item?.driverId?._id ?
                            <Button
                              onClick={() => navigate(`/driver/view/${item?.driverId?._id}`)}
                              style={{ padding: "5px 10px" }} value="View driver" />
                            : "NA"
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            {/* <div className="">
              <Pagination
                totalItems={state.totalItems}
                itemsPerPage={5}
                currentPage={state.currentPage}
                onPageChange={(page) => setState({ currentPage: page })}
              />
            </div> */}
          </div>
          <div className="col-md-6">
            <h6 className='text-center' >Present drivers</h6>
            <table className='table table-bordered' style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Driver name</th>
                  <th>Driver contact</th>
                  <th className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  state?.data?.presentDriverDetails?.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.createdBy?.fullName}</td>
                      <td>{item?.createdBy?.mobile}</td>
                      <td className="text-center">
                        {
                          item?.createdBy?._id ?
                            <Button
                              onClick={() => navigate(`/driver/view/${item?.createdBy?._id}`)}
                              style={{ padding: "5px 10px" }} value="View driver" />
                            : "NA"
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  )
}

export default StoreSummery