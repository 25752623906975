import React, { useState, useEffect } from "react";
import "./accessControl.screen.scss";
import CustomSelect from "common_components/ui/select/select.ui";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { CheckboxChangeEvent } from "primereact/checkbox";
import { Checkbox } from "primereact/checkbox";
import Colors from "imports/color.import";
import { Team, Controls } from "./data";
import { Models } from "utils/imports.utils";
import { toastifyError } from "utils/functions.utils";
import { useSelector } from "react-redux";

interface TeamData {
  Team: string[];
}

interface Controls {
  title: string;
}

function AccessControl() {
  const role = localStorage.getItem("role");
  const auth = useSelector((s: any) => s.auth);
  const [state, setState] = useState({
    data: [],
    loading: false,
    user: "",
    visibleControls : Controls,
    searchText : "",
  });
  const [checked, setChecked] = useState<string[]>([]);
  const [teamTitleActive, setTeamTitleActive] = useState<number | null>(null);

  const handleCheck = (e: CheckboxChangeEvent, title: string) => {
    if (!state.user || role !== "SA") {
      return;
    }
    if (e.target.checked) {
      setChecked([...checked, title]);
      createAccessControl([...checked, title]);
    } else {
      setChecked(checked.filter((item) => item !== title));
      createAccessControl(checked.filter((item) => item !== title));
    }
  };

  const handleChangeUser = (user: string) => {
    setState({
      ...state,
      user: user,
    });
    setTeamTitleActive(null);
    setChecked([]);
  };

  const getUser = async () => {
    const body: any = {
      status: "APPROVED",
    };
    try {
      const res: any = await Models.user.getManyUser(body);
      const temp: any = res?.data?.map((item: any) => ({
        label: item?.fullName ?? "-- No name --",
        value: item?._id,
        email: item?.email,
      }));
      setState((prevState) => ({
        ...prevState,
        data: temp,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const createAccessControl = async (data: any) => {
    try {
      const payload = {
        userId: state.user,
        activeACL: data,
      };
      const res: any = await Models.accessControl.createAccessControl(payload);
    } catch (error) {
      toastifyError(error);
    }
  };

  const getAclOfUser = async () => {
    try {
      const query = {
        userId: role === "SA" ? state.user : auth.user._id,
      };
      const res: any = await Models.accessControl.getAccessControlInSAMode(
        query
      );
      if (res.data?.activeACL.length > 0) {
        setChecked([...res.data.activeACL]);
      }
    } catch (error) {
      toastifyError(error);
    }
  };

  const formattedText = (title: string) => {
    const formattedText = title.toLowerCase().replace(/_/g, " ");
    const capitalizedText =
      formattedText.charAt(0).toUpperCase() + formattedText.slice(1);
    return capitalizedText;
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (state.user || role !== "SA") {
      getAclOfUser();
    }
  }, [state.user]);

  const userValueTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>
          {option?.email ? "Email : " + option?.email : "Select a user"}
        </div>
      </div>
    );
  };
  const userItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>Name : {option.label}</div>
        <div>Email : {option.email}</div>
      </div>
    );
  };
  const toggleAllCheck =(e)=>{
    console.log(e.checked);
    if(!e.checked){
      setChecked([])
      createAccessControl([])
    }else{
      setChecked(Controls)
      createAccessControl(Controls)

    }
  }
  const handleSearch = (searchText : string)=>{

    const temp = Controls.filter(item=>item?.toLocaleLowerCase().indexOf(searchText?.toLocaleLowerCase()) !== -1)
    setState((prev)=>({...prev,visibleControls : temp , searchText }))
  }
  return (
    <div className="org_screen">
      <div className="header_container" style={{ margin: "0px" }}>
        <div className="h3 access-control-heading">Access Control</div>
      </div>
      <div className="access_control_main_container container-fluid">
        <div className="row">
        {role === 'SA' && <div className="col-md-4">
           <div className="inner_column">
              <div className="head h4">User</div>
              {/* @ts-ignore */}
              <Dropdown
                loading={state.loading}
                filter
                filterBy="label,value,email"
                style={{ backgroundColor: '#E4E4E4', width: '100%', border: "none" }}
                value={state.user}
                onChange={(e) => handleChangeUser(e.value)}
                options={state.data ?? []}
                optionLabel="label"
                valueTemplate={userValueTemplate}
                itemTemplate={userItemTemplate}
                placeholder={state.loading ? "Fetching Data..." : "Select a user"}

              />
            </div>
          </div>}
          {role === 'SA' && <div className="col-md-4">
            <div className={`inner_column ${state.user ? "" : "avoid-clicks"}`}>
              <div className="h4">Team</div>
              {Team.map((title, index) => (
                <div
                  className='team-list-box'
                  style={{ backgroundColor: index === teamTitleActive ? Colors.primary : "#E4E4E4" }}
                  onClick={() => setTeamTitleActive(index)}
                  key={index} // Add a unique key prop
                >
                  <p className={`h6 team-heading ${index === teamTitleActive ? 'text-white' : ''}`}>{title}</p>
                </div>
              ))}
            </div>
          </div>}
          <div className="col-md-4">
            <div className={`inner_column ${state.user ? "" : "avoid-clicks"}`}>
              <div className="head h4">Access Control list</div>
              <div className="d-flex my-2 align-items-center">
                <Checkbox
                  className='custom-checkbox'
                  checked={checked.length === Controls.length}
                  onChange={(e) => toggleAllCheck(e)}
                  // inputId={`checkbox-${index}`}
                  style={{margin : '0px 13px'}}
                />
                <InputText 
                  style={{flex  : 1 }}
                  placeholder='Search access..'
                  value={state.searchText}
                  onChange={(e)=>handleSearch(e.target.value)}
                />
              </div>
              <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                {state?.visibleControls?.map((title, index) => (
                  <label htmlFor={`checkbox-${index}`} className='control-list-box' style={{
                    backgroundColor: checked.includes(title) ? Colors.primary : "#E4E4E4",
                    width: role === 'SA' ? "100%" : "400px"
                  }}>
                    <Checkbox
                      className="custom-checkbox"
                      checked={checked.includes(title)}
                      onChange={(e) => handleCheck(e, title)}
                      inputId={`checkbox-${index}`}
                    />
                    <p
                      className={`control-title ${
                        checked.includes(title) ? "text-white" : ""
                      }`}
                    >
                      {formattedText(title)}
                    </p>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessControl;
