import React, { useEffect } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
import Assets from "imports/assets.import";
import Button from "common_components/ui/button/button.ui";
import "./add_warehouse.screen.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeEmptyValues,
  useSetState,
  toastifyError,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";
import _ from "lodash";
import {
  AADHAAR_BUCKET_URL,
  AGREEMENT_DOC_UPLOAD_URL,
  INVOICE_UPLOAD_URL,
  POWER_BILLS_UPLOAD_URL,
} from "utils/constant.utils";

export default function AddCharger() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id, cId } = useParams();
  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");

  const initialValue = {
    chargerType: "",
    chargerUnit: "",
    chargerValue: "",
    currentlyOccupied: false,
    capacity: "",
    connector: "",
    vehicleType: "",
    availability: "",
  };

  const [state, setState] = useSetState({
    data: initialValue,
    loading: false,
    buttonDisabled: false,
  });

  const inputFields: IAddValues[] = [
    { label: "Charger Type", key: "chargerType", type: "string" },
    { label: "Charger Unit", key: "chargerUnit", type: "string" },
    { label: "Charger Value", key: "chargerValue", type: "string" },
    {
      label: "Currently Occupied",
      key: "currentlyOccupied",
      options: [
        { label: "YES", value: true },
        { label: "No", value: false },
      ],
      type: "select",
    },
    { label: "Capacity", key: "capacity", type: "string" },
    { label: "Connector", key: "connector", type: "string" },
    {
      label: "Vehicle Type",
      key: "vehicleType",
      options: [
        { label: "2W", value: "2W" },
        { label: "3W", value: "3W" },
        { label: "4W", value: "4W" },
      ],
      type: "select",
    },
    { label: "Availability", key: "availability", type: "string" },
  ];

  useEffect(() => {
    if (isEditable) {
      GetCharger();
    }
  }, []);

  const GetCharger = async () => {
    try {
      const response: any = await Models.warehouse.getCharger({
        wId: id,
      });
      setState({
        data: response.data[0].chargingPoint.filter(
          (item) => item._id === cId
        )[0],
      });
      console.log(
        response.data[0].chargingPoint.filter((item) => item._id === cId)
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = async (values) => {
    console.log(values);

    try {
      const data: any = removeEmptyValues(values);
      await Models.warehouse.editCharger({
        _id: cId,
        wId: id,
        ...data,
      });
      navigate(`/warehouse/charger/${id}`);
    } catch (err) {
      toastifyError(err);
      setState({ buttonDisabled: false });
      console.log(err);
    }
  };
  const handleCreate = async (values) => {
    try {
      const body: any = {
        wId: id,
        ...values,
      };
      await Models.warehouse.createCharging(body);
      navigate(`/warehouse/charger/${id}`);
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };

  return (
    <div className="add_warehouse_container">
      <div className="add_warehouse_wrapper">
        <History name={state?.data?.chId} path={isEditable ? "charger/edit" : "charger/add"} />
        <div className="add_warehouse_body_container">
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputFields}
            initialValues={initialValue}
            validationSchema={"warehouse"}
            onSubmit={isEditable ? handleEdit : handleCreate}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          {isEditable ? (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Update"
              />
            </div>
          ) : (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Save"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
