import React from 'react'

const NotFound = () => {
  return (
    <div
    style={{
      display : 'flex',
      justifyContent : 'center',
      alignItems : 'center',
      height : "80%",
      width : "100%",
      color  : "#757575"
    }}
    >
      <h4 className='h4'>404 Not Found</h4>
    </div>
  )
}

export default NotFound