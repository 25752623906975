import { useEffect } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
import Button from "common_components/ui/button/button.ui";
import "./add_driver.screen.scss";
import * as yup from 'yup'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeEmptyValues,
  useSetState,
  toastifyError,
  jsonToFormData,
  toastify,
  createPayloadUpdate,
  simplifyJSON,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";
import CustomModal from "common_components/ui/modal/modal.component";
import TextareaComponent from "common_components/ui/text_area/textarea.component";
import encrypt from "utils/encrypt";
import AddVerification from "components/add/addVerification";
import {
  AADHAAR_BUCKET_URL,
  DL_BUCKET_URL,
  FACE_BUCKET_URL,
  PAN_BUCKET_URL,
  PASSBOOK_BUCKET_URL,
  GAS_BILL_BUCKET_URL,
} from "utils/constant.utils";
import DForm from "d-forms-library/d_form";
import DFormType from "d-forms-library/d_form/d-form.type";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";

export default function AddDriver() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");

  const initialValue = {
    mobile: "",
    wapNumber: "",
    secondaryMobileNo: "",
    gender: "",
    bloodGroup: "",
    fullName: "",
    state: "",
    city: "",
    birthdate: "",
    driverRegistrationType: "",
    fullAddress: "",
    fatherName: "",
    pinCode: "",
    languages: "",
    face: "",
    dob: "",
  };

  const [state, setState] = useSetState({
    data: initialValue,
    loading: false,
    buttonDisabled: false,
    deallocateModal: false,
    userId: "",
    driverId: "",
    vehicleAllocated: {
      vehicleId: "",
    },
    vehicles: [],
    clients: [],
    stores1: [],
    stores2: [],
    altVehicle: "",
    altClient1: undefined,
    altStore1: undefined,
    altClient2: undefined,
    altStore2: undefined,
    allocatedClient: {}
  });

  const inputFields: IAddValues[] = [
    {
      label: "Name",
      key: isEditable ? "userId.fullName" : "fullName",
      type: "string",
    },
    {
      label: "Gender",
      key: isEditable ? "userId.gender" : "gender",
      type: "select",
      options: [
        {
          label: "Male",
          value: "Male",
        },
        {
          label: "Female",
          value: "Female",
        },
      ],
    },
    {
      label: "D.O.B",
      key: isEditable ? "userId.dob" : "dob",
      type: "birthdate",
    },
    {
      label: "Blood Group",
      key: isEditable ? "userId.bloodGroup" : "bloodGroup",
      type: "select",
      options: [
        {
          label: "A+",
          value: "A+",
        },
        {
          label: "A-",
          value: "A-",
        },
        {
          label: "B+",
          value: "B+",
        },
        {
          label: "B-",
          value: "B-",
        },
        {
          label: "O+",
          value: "O+",
        },
        {
          label: "O-",
          value: "O-",
        },
        {
          label: "AB+",
          value: "AB+",
        },
        {
          label: "AB-",
          value: "AB-",
        },
      ],
    },
    {
      label: "Mobile",
      key: isEditable ? "userId.mobile" : "mobile",
      type: "string",
      hide: isEditable ? true : false,
    },
    {
      label: "Whatsapp no.",
      key: isEditable ? "userId.wapNumber" : "wapNumber",
      type: "string",
      optional: true,
    },
    {
      label: "Secondary no.",
      key: isEditable ? "userId.secondaryMobileNo" : "secondaryMobileNo",
      type: "string",
      optional: true,
    },
    {
      label: "Father's Name",
      key: isEditable ? "userId.fatherName" : "fatherName",
      type: "string",
    },
    {
      label: "State",
      key: isEditable ? "userId.address[0].state" : "state",
      type: "state",
    },
    {
      label: "City",
      key: isEditable ? "userId.address[0].city" : "city",
      type: "city",
    },
    {
      label: "Full address",
      key: isEditable ? "userId.address[0].fullAddress" : "fullAddress",
      type: "string",
    },
    {
      label: "Pincode",
      key: isEditable ? "userId.address[0].pinCode" : "pinCode",
      type: "string",
    },
    {
      label: "Languages",
      key: isEditable ? "userId.languages" : "languages",
      type: "array",
      optional: true,
    },
    {
      label: "Registration Type",
      key: isEditable
        ? "userId.driverRegistrationType"
        : "driverRegistrationType",
      type: "select",
      options: [
        {
          label: "DEDICATED",
          value: "DEDICATED",
        },
        {
          label: "FLOATING",
          value: "FLOATING",
        },
      ],
    },
    {
      label: "Profile photo (Document must be below 2 MB size) ",
      key: isEditable ? "userId.userImage" : "face",
      type: "file",
      file_bucket_url: FACE_BUCKET_URL,
    },
  ];

  const inputFiles: IAddValues[] = [
    // { label: "Aadhaar card no", key: "dAadhaarCardNumber", type: "string" },
    // { label: "Lisence no", key: "dDlNumber", type: "string" },
    // { label: "Pancard no", key: "dPanNumber", type: "string" },
    {
      label: "Aadhaar card front (Document must be below 2 MB size) ",
      key: "aadhaarCardFront",
      type: "file",
      file_bucket_url: AADHAAR_BUCKET_URL,
      secondaryKey: "private",
    },
    {
      label: "Aadhar card back (Document must be below 2 MB size) ",
      key: "aadhaarCardBack",
      type: "file",
      file_bucket_url: AADHAAR_BUCKET_URL,
      secondaryKey: "private",
    },
    {
      label: "Pancard front (Document must be below 2 MB size) ",
      key: "panCardFront",
      type: "file",
      file_bucket_url: PAN_BUCKET_URL,
      secondaryKey: "private",
      optional: true,
    },
    {
      label: "Recent gas bill (Document must be below 2 MB size) ",
      key: "gasBill",
      type: "file",
      file_bucket_url: GAS_BILL_BUCKET_URL,
      secondaryKey: "private",
      optional: true,
    },
    {
      label: "Driving liscence front (Document must be below 2 MB size) ",
      key: "dlFront",
      type: "file",
      file_bucket_url: DL_BUCKET_URL,
      secondaryKey: "private",
    },
    {
      label: "Driving liscence back (Document must be below 2 MB size) ",
      key: "dlBack",
      type: "file",
      file_bucket_url: DL_BUCKET_URL,
      secondaryKey: "private",
    },
  ];
  const privateInfo: IAddValues[] = [
    {
      label: "Aadhaar card no",
      key: "dAadhaarCardNumber",
      type: "string",
    },
    {
      label: "Lisence no",
      key: "dDlNumber",
      type: "string",
    },
    {
      label: "Pancard no",
      key: "dPanNumber",
      type: "string",
      optional: true,
    },
  ];
  const inputBankDetails: IAddValues[] = [
    {
      label: "Account holder's name",
      key: isEditable ? "bankDetails.acHolderName" : "acHolderName",
      type: "string",
    },
    {
      label: "Account no.",
      key: isEditable ? "bankDetails.acNumber" : "acNumber",
      type: "string",
    },
    {
      label: "IFSC Code",
      key: isEditable ? "bankDetails.ifscCode" : "ifscCode",
      type: "string",
    },
    {
      label: "Passbook front (Document must be below 2 MB size) ",
      key: isEditable ? "bankDetails.passbookFront" : "passbookFront",
      type: "file",
      file_bucket_url: PASSBOOK_BUCKET_URL,
      secondaryKey: "private",
    },
    {
      label: "Deposited amount",
      key: "driverDeposit",
      type: "string",
    },
    {
      label: "Driver deposit remarks",
      key: "driverDepositRemarks",
      type: "string",
    },
  ];
  const allocateVehicle: IAddValues[] = [
    {
      label: "Allot a vehicle",
      key: "vehicleId",
      type: "vehicle",
      optional: true,
    },
  ];
  const inputEmergency: IAddValues[] = [
    {
      label: "Relation",
      key: isEditable ? "emergencyDetails.relation" : "relation",
      type: "select",
      options: [
        {
          label: "Father",
          value: "Father",
        },
        {
          label: "Mother",
          value: "Mother",
        },
        {
          label: "Spouse",
          value: "Spouse",
        },
        {
          label: "Friend",
          value: "Friend",
        },
      ],
    },
    {
      label: "Contact Name",
      key: isEditable ? "emergencyDetails.name" : "name",
      type: "string",
    },
    {
      label: "Mobile",
      key: isEditable ? "emergencyDetails.mobile" : "mobile",
      type: "string",
    },
    {
      label: "State",
      key: isEditable ? "emergencyDetails.state" : "state",
      type: "state",
    },
    {
      label: "City",
      key: isEditable ? "emergencyDetails.city" : "city",
      type: "city",
    },
    {
      label: "Address",
      key: isEditable ? "emergencyDetails.address" : "address",
      type: "string",
    },
    {
      label: "Pincode",
      key: isEditable ? "emergencyDetails.pinCode" : "pinCode",
      type: "string",
    },
  ];

  useEffect(() => {
    if (isEditable) {
      GetDriver();
    }
  }, []);

  const GetDriver = async () => {
    try {
      const response: any = await Models.driver.getDriver({
        id: params.id,
      });
      setState({
        data: response.data[0],
        did: response.data[0]._id,
        vehicleId: response.data[0].vehicleId,
        driverUserId: response?.data?.[0]?.userId?._id
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditBasic = async (values) => {
    try {
      let data: any = removeEmptyValues(
        createPayloadUpdate(values.userId, [
          "geoLocation",
          "isDefault",
          "registrationMethod",
          "_id",
          "userImage",
          "createdAt",
          "languages",
          "approvedBy",
        ])
      );
      data = simplifyJSON(data);
      data._id = values.userId._id;
      data.face = values.userId.userImage;
      data.languages = values.userId.languages;
      //filtered DATA
      let final_data = {
        driverRegistrationType: data.driverRegistrationType,
        fullName: data.fullName,
        gender: data.gender,
        fullAddress: data.fullAddress,
        state: data.state,
        city: data.city,
        pinCode: data.pinCode,
        fatherName: data.fatherName,
        dob: data.dob,
        wapNumber: data.wapNumber,
        secondaryMobileNo: data.secondaryMobileNo,
        bloodGroup: data.bloodGroup,
        mobile: data.mobile,
        _id: data._id,
        face: data.face,
        languages: data.languages,
      };

      const formData = jsonToFormData(final_data);
      await Models.driver.editDriverBasic(formData);
      toastify("Basic info updated");
    } catch (err) {
      toastifyError(err);
      setState({ buttonDisabled: false });
      console.log(err);
    }
  };

  const handleEditUpload = async (values) => {
    try {
      const data: any = createPayloadUpdate(removeEmptyValues(values), [
        "userId",
        "vehicleAllocationStatus",
        "bankDetails",
        "emergencyDetails",
        "dNumber",
        "_id",
        "createdAt",
      ]);
      // data._id = values.userId._id;
      let final_data = {
        aadhaarCardFront: data.aadhaarCardFront,
        aadhaarCardBack: data.aadhaarCardBack,
        panCardFront: data.panCardFront,
        panCardBack: data.panCardBack,
        dlFront: data.dlFront,
        dlBack: data.dlBack,
        gasBill: data.gasBill,
        _id: values.userId._id,
      };
      const formData = jsonToFormData(final_data);
      await Models.driver.editDriverUpload(formData);
      toastify("Documents uploaded");
    } catch (err) {
      toastifyError(err);
      setState({ buttonDisabled: false });
      console.log(err);
    }
  };
  const handleEditBankDetails = async (values) => {
    try {
      const data: any = removeEmptyValues(values.bankDetails);
      data._id = values.userId._id;
      data.driverId = values._id;
      data.driverDeposit = values.driverDeposit;
      data.driverDepositRemarks = values.driverDepositRemarks;

      let final_data = {
        acHolderName: data.acHolderName,
        acNumber: data.acNumber,
        ifscCode: data.ifscCode,
        passbookFront: data.passbookFront,
        _id: data._id,
        driverId: data.driverId,
        driverDeposit: data.driverDeposit,
        driverDepositRemarks: data.driverDepositRemarks,
      };
      const formData = jsonToFormData(final_data);
      await Models.driver.editDriverBankDetails(formData);
      toastify("Bank details updated");
    } catch (err) {
      toastifyError(err);
      setState({ buttonDisabled: false });
      console.log(err);
    }
  };

  const handleEditEmergency = async (values) => {
    try {
      const data: any = removeEmptyValues(values.emergencyDetails);
      let final_data = {
        address: data.address,
        city: data.city,
        driverId: values._id,
        mobile: data.mobile,
        name: data.name,
        pinCode: data.pinCode,
        relation: data.relation,
        _id: values.userId._id,
      };
      await Models.driver.editDriverEmergency(final_data);
      toastify("Emergency contact updated");
      navigate("/driver");
    } catch (err) {
      toastifyError(err);
      setState({ buttonDisabled: false });
      console.log(err);
    }
  };
  const handleCreateBasic = async (values) => {
    try {
      console.log(values);
      
      const data: any = removeEmptyValues(values);
      const formData = jsonToFormData(data);
      const res: any = await Models.driver.createDriverBasic(formData);
      setState({ userId: res.data._id });
      toastify("Basic info uploaded");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const handleCreateUpload = async (values) => {
    try {
      values._id = state.userId;
      const data: any = removeEmptyValues(values);
      const formData = jsonToFormData(data);
      const res: any = await Models.driver.createDriverUpload(formData);
      setState({ driverId: res.data._id });
      toastify("Documents uploaded");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const handleDriverAllocation = (values)=>{
    try{
      if (values.vehicleId) {
        const vehicleAllocationData: any = {
          vehicleId: values.vehicleId,
          driverId: isEditable ? state.did : state.driverId,
        };
        Models.driver.allotVehicleToDriver(vehicleAllocationData);
        toastify("Vehicle Allocated successfully!");
      }else{

      }
    }catch(err){

    }
  }
  const handleClientAllocation = async () => {
    // console.log(values);
    console.log("DID", isEditable ? state.did : state.driverId);
    try {
      
      // if ((state.altClient1 && state.altStore1) || state.altClient2 && state.altStore2) {
      let mappingConfig: any = []
      if (state.altClient1 && state.altStore1) {
        mappingConfig.push({
          clientId: state.altClient1?.split("|")[0],
          storeId: state.altStore1
        })
      }
      if (state.altClient2 && state.altStore2) {
        mappingConfig.push({
          clientId: state.altClient2?.split("|")[0],
          storeId: state.altStore2
        })
      }
      const clientAllocationData: any = {
        driverId: isEditable ? state.driverUserId : state.userId,
        mappingConfig
      };
      await Models.driver.allotClientToDriver(clientAllocationData);
      // setState({ userId: res.data._id });
      toastify("Assigned to client successfully!");
      // }else{
      //   // toastifyError("Fields are missing")
      // }
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const getAllocatedVehicle = async () => {
    if (state.did) {
      try {
        let res: any;
        const body: any = {
          dId: state.did,
        };
        res = await Models.vehicle.getAllocatedDriver(body);
        setState({
          vehicleAllocated: { vehicleId: res.data[0]?.vehicleId?._id },
        });
      } catch (error) {
        setState({ loading: false });
        console.log(error);
        toastifyError(error);
      }
    }
  };
  const getAllocatedClient = async () => {
    if (state.did) {
      try {
        let res: any;
        const body: any = {
          dId: state.driverUserId,
        };
        res = await Models.driver.getAllocatedClient(body);
        // setState({
        //   allocatedClient: res?.data?.assigned?.mappingConfig,
        // });
        let temp = res?.data?.assigned?.mappingConfig
        setState({
          altClient1: (temp?.[0]?.clientId?._id && temp?.[0]?.clientId?.clientId) ? temp?.[0]?.clientId?._id + "|" + temp?.[0]?.clientId?.clientId : undefined,
          altStore1: temp?.[0]?.storeId?._id ?? undefined,
          altClient2: (temp?.[1]?.clientId?._id && temp?.[1]?.clientId?.clientId) ? temp?.[1]?.clientId?._id + "|" + temp?.[1]?.clientId?.clientId : undefined,
          altStore2: temp?.[1]?.storeId?._id ?? undefined,
        })
      } catch (error) {
        setState({ loading: false });
        console.log(error);
        toastifyError(error);
      }
    }
  };
  const handleCreateBankDetails = async (values) => {
    try {
      values._id = state.userId;
      values.driverId = state.driverId;
      const data: any = removeEmptyValues(values);
      const formData = jsonToFormData(data);
      const res: any = await Models.driver.createDriverBankDetails(formData);
      toastify("Bank details uploaded!");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const handleCreatePersonalInfo = async (values) => {
    console.log(values);

    try {
      // values._id = state.driverId;
      const data: any = removeEmptyValues(values);
      const jsonString = JSON.stringify(data);
      const payload = await encrypt(jsonString);
      const body = {
        _id: state.driverId,
        data: payload,
      };
      const res: any = await Models.driver.createPersonalInfo(body);
      toastify("Driver personal info uploaded!");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const handleCreateEmergency = async (values) => {
    try {
      values.driverId = state.driverId;
      const data: any = removeEmptyValues(values);
      const res: any = await Models.driver.createDriverEmergency(data);
      navigate("/driver");
      toastify("Driver onboarded successfully!");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };
  const dSchema = yup.object({
    name: yup.string().required(),
    age: yup.string().required().nullable(),
    dob: yup.string().required(),
    gender: yup.string().required(),
    address: yup.object({
      state: yup.string().required(),
      city: yup.string().required(),
    }),
    multi: yup.array().min(1).required(),
    file: yup.string().required(),
    warehouse: yup.string().required(),
  });
  const GetManyVehicle = async () => {
    const body: any = {};
    try {
      const res: any = await Models.vehicle.getManyVehicle(body);
      const temp = res?.data?.data?.map((item: any) => ({
        label: item?.vhNumber ?? "-- No name --",
        value: item?._id,
        modal: item?.vhModel,
      }));
      setState({ vehicles: temp });
    } catch (err) {
      console.log(err);
    }
  };
  const fetchClients = async () => {
    try {
      const res: any = await Models.organization.getManyOrganization({ clt: "ls" })
      const temp = res?.data?.map((item, index) => ({
        label: item?.fullName,
        value: item?.clientId?.userId + "|" + item?.clientId?._id
      }))
      setState({ clients: temp })
    } catch (err) {
      console.log("Fetch Clients Error : ", err)
    }
  }
  const fetchStores = async (type: 1 | 2) => {
    try {
      setState({ storeFetchLoading: true })
      const res: any = await Models.store.getManyStore({ client: state["altClient" + type]?.split("|")[1] })
      const temp = res?.data?.map((item, index) => ({
        label: item?.storeName,
        value: item?._id
      }))
      setState({ ["stores" + type]: temp })
    } catch (err) {
      console.log("Fetch Store Error : ", err)
    } finally {
      setState({ storeFetchLoading: false })
    }
  }
  const vehicleItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>Modal : {option.modal}</div>
        <div>Vh Number : {option.label}</div>
      </div>
    );
  };
  useEffect(() => {
    GetManyVehicle()
    fetchClients()
  }, [])
  useEffect(() => {
    if (state.altClient1) fetchStores(1)
  }, [state.altClient1])
  useEffect(() => {
    if (state.altClient2) fetchStores(2)
  }, [state.altClient2])
  useEffect(() => {
    if (isEditable) {
      getAllocatedClient();
      getAllocatedVehicle();
    }
  }, [state.did]);
  return (
    <div className="add_driver_container">
      <div className="add_driver_wrapper">
        <History
          name={state?.data?.userId?.fullName}
          path={isEditable ? "Driver/edit" : "Driver/add"}
        />
        <div className="add_driver_body_container">
          <h4>Personal Information</h4>
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputFields}
            initialValues={initialValue}
            validationSchema={isEditable ? "" : "driverBasic"}
            onSubmit={isEditable ? handleEditBasic : handleCreateBasic}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
        {/* <Divider /> */}
        <div className="add_driver_body_container">
          <h4>Personal Documents</h4>
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputFiles}
            initialValues={initialValue}
            validationSchema={""}
            onSubmit={isEditable ? handleEditUpload : handleCreateUpload}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
        {!isEditable && (
          <div className="add_driver_body_container">
            <h4>Personal Information ID</h4>
            <AddVerification
              data={state.data}
              values={privateInfo}
              onSubmit={handleCreatePersonalInfo}
              id={state.driverId}
            />
          </div>
        )}
        <div className="add_driver_body_container">
          <h4>Client Allocation</h4>
          <div style={{
            background: 'white',
            padding: 30,
            borderRadius: 20,
          }} >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 my-2">
                  <div className="label_container caption2">
                    Client 1
                  </div>
                  <Dropdown
                    filter
                    value={state.altClient1}
                    onChange={(e) => setState({ altClient1: e.value })}
                    options={state.clients ?? []}
                    filterBy="label,modal"
                    optionLabel="label"
                    placeholder={"Select 1st Client"}
                    style={{ width: "100%" }}
                    showClear
                  />
                </div>
                <div className="col-md-6 my-2">
                  <div className="label_container caption2">
                    Store 1
                  </div>
                  <Dropdown
                    filter
                    value={state.altStore1}
                    onChange={(e) => setState({ altStore1: e.value })}
                    options={state.stores1 ?? []}
                    filterBy="label,modal"
                    optionLabel="label"
                    placeholder={"Select a 1st store"}
                    style={{ width: "100%" }}
                    showClear
                  />
                </div>
                <div className="col-md-6 my-2">
                  <div className="label_container caption2">
                    Client 2
                  </div>
                  <Dropdown
                    filter
                    value={state.altClient2}
                    onChange={(e) => setState({ altClient2: e.value })}
                    options={state.clients ?? []}
                    filterBy="label,modal"
                    optionLabel="label"
                    placeholder={"Select 2nd client"}
                    style={{ width: "100%" }}
                    showClear
                  />
                </div>
                <div className="col-md-6 my-2">
                  <div className="label_container caption2">
                    Store 2
                  </div>
                  <Dropdown
                    filter
                    value={state.altStore2}
                    onChange={(e) => setState({ altStore2: e.value })}
                    options={state.stores2 ?? []}
                    filterBy="label,modal"
                    optionLabel="label"
                    placeholder={"Select 2nd store"}
                    style={{ width: "100%" }}
                    showClear
                  />
                </div>
                {/* <div className="col-md-6 my-2">
                  <div className="label_container caption2">
                    Allot a vehicle
                    <span style={{color : 'red'}} > * </span>
                  </div>
                  <Dropdown
                    filter
                    value={state.altVehicle}
                    onChange={(e) => setState({ altVehicle: e.value })}
                    options={state.vehicles ?? []}
                    filterBy="label,modal"
                    optionLabel="label"
                    itemTemplate={vehicleItemTemplate}
                    placeholder={"Select a vehicle"}
                    style={{ width: "100%" }}
                  />
                </div> */}
              </div>
              <div className="row justify-content-center">
                <div className="col-md-3">
                  <br />
                  <Button
                    onClick={handleClientAllocation}
                    value={isEditable ? "Update" : "Save"}
                    width="250px"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <h4>Vehicle allocation</h4>
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.vehicleAllocated}
            values={allocateVehicle}
            initialValues={""}
            validationSchema={""}
            onSubmit={handleDriverAllocation}
            buttons={<AddButton  buttonDisabled={ state.buttonDisabled} />}
            hasFiles
          />
        </div>
        {/* <Divider /> */}
        <div className="add_driver_body_container">
          <h4>Bank Account Details</h4>
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputBankDetails}
            initialValues={initialValue}
            validationSchema={""}
            onSubmit={
              isEditable ? handleEditBankDetails : handleCreateBankDetails
            }
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
        {/* <Divider /> */}
        <div className="add_driver_body_container">
          <h4>Emergency Contact</h4>
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputEmergency}
            initialValues={initialValue}
            validationSchema={""}
            onSubmit={isEditable ? handleEditEmergency : handleCreateEmergency}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          {isEditable ? (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Update"
              />
            </div>
          ) : (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Save"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
