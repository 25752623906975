import React, { useEffect } from "react";
import History from "components/history/history.component";
import Add from "components/add/add.component";
import Assets from "imports/assets.import";
import Button from "common_components/ui/button/button.ui";
import "./add_warehouse.screen.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  removeEmptyValues,
  useSetState,
  toastifyError,
} from "utils/functions.utils";
import { Models } from "utils/imports.utils";
import { IAddValues } from "utils/interface.utils";
import _ from "lodash";
import { AGREEMENT_DOC_UPLOAD_URL } from "utils/constant.utils";
import { Formik, FieldArray, Field, Form, ErrorMessage } from "formik";

export default function AddWarehouse() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const pathArr = pathname.split("/");
  const isEditable = pathArr.includes("edit");

  const initialValue = {
    city: "",
    state: "",
    area: "",
    fullAddress: "",
    pinCode: "",
    wareHouseSubCategory: "",
    securityInCharge: "",
    securityInChargeNumber: "",
  };

  const [state, setState] = useSetState({
    data: initialValue,
    loading: false,
    buttonDisabled: false,
  });

  const inputFields: IAddValues[] = [
    { label: "Name", key: "name", type: "string" },
    { label: "State", key: "address.state", type: "state" },
    { label: "City", key: "address.city", type: "city" },
    { label: "Enter address", key: "address.fullAddress|address.geoLocation", type: "map" , isNested : true },
    { label: "Pin Code", key: "address.pinCode", type: "string" },
    { label: "Landmark", key: "address.landMark", type: "string" },
    { label: "Building Name", key: "address.buildingName", type: "string" },

    { label: "Space", key: "", type: "space" },
    { label: "Capacity for two-wheeler", key: "capacity.w2", type: "string" },
    { label: "Capacity for three-wheeler", key: "capacity.w3", type: "string" },
    { label: "Capacity for four-wheeler", key: "capacity.w4", type: "string" },

    { label: "Control Center ID", key: "controlCenterId", type: "center" },
    {
      label: "One Time Deposit Amount",
      key: "oneTimeDepositAmount",
      type: "number",
    },
    { label: "Monthly Rent", key: "monthlyRent", type: "number" },

    {
      label: "Agreement Document",
      key: "agreementDocument",
      type: "file",
      file_bucket_url: AGREEMENT_DOC_UPLOAD_URL,
      secondaryKey: "private",
    },
    { label: "Agreement Start Date", key: "agreementStartDate", type: "date" },
    { label: "Agreement End Date", key: "agreementEndDate", type: "date" },
    { label: "Supervisor", key: "", type: "supervisor" },
    { label: "Security", key: "", type: "security" },
  ];

  useEffect(() => {
    if (isEditable) {
      GetWarehouse();
    }
  }, []);

  const GetWarehouse = async () => {
    try {
      const response: any = await Models.warehouse.getWarehouse({
        id: id,
      });
      setState({ data: response.data[0] });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = async (values) => {
    // values = {
    //   ...values,
    //   geoLocation: {
    //     type: "Point",
    //     coordinates: [values.fullAddress?.lng, values.fullAddress?.lat],
    //   },
    //   fullAddress: values?.fullAddress?.formatted_address,
    // };

    try {
      const data: any = removeEmptyValues(values);
      delete data.whId;
      delete data.createdBy;
      if (typeof values.controlCenterId === "object")
        data.controlCenterId = values.controlCenterId._id;

      await Models.warehouse.editWarehouse({
        _id: id,
        ...data,
      });
      navigate("/warehouse");
    } catch (err) {
      toastifyError(err);
      setState({ buttonDisabled: false });
      console.log(err);
    }
  };
  const handleCreate = async (values) => {
    try {
      const data: any = removeEmptyValues(values);
      await Models.warehouse.createWarehouse(data);
      navigate("/warehouse");
    } catch (err) {
      toastifyError(err);
      console.log(err);
    }
  };

  return (
    <div className="add_warehouse_container">
      <div className="add_warehouse_wrapper">
        <History name={state?.data?.nameSlug} />
        <div className="add_warehouse_body_container">
          <Add
            title={`${isEditable ? "Edit" : "Add"}`}
            actions={[{ link: "/", icon: "view" }]}
            data={state.data}
            values={inputFields}
            initialValues={initialValue}
            validationSchema={"warehouse"}
            onSubmit={isEditable ? handleEdit : handleCreate}
            buttons={<AddButton buttonDisabled={state.buttonDisabled} />}
            hasFiles
          />
        </div>
      </div>
    </div>
  );

  function AddButton(props: any) {
    return (
      <div className="view_button_container">
        <div className="view_button_wrapper">
          {isEditable ? (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Update"
              />
            </div>
          ) : (
            <div className="view_button">
              <Button
                width="250px"
                buttonDisabled={props.buttonDisabled}
                value="Save"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
